{
  "form": {
    "forgottenPassword": "",
    "accountRequest": "",
    "buttons": {
      "login": "",
      "requestAccount": ""
    },
    "labels": {
      "email": "",
      "password": ""
    },
    "placeHolders": {
      "password": "",
      "email": ""
    }
  },
  "schema": {
    "passwordRequirements": {
      "lowercase": "",
      "minLength": "",
      "number": "",
      "required": "",
      "specialCharacter": "",
      "uppercase": ""
    },
    "emailRequirements": {
      "emailRequired": "",
      "invalidEmail": ""
    }
  }
}
