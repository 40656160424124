{
  "errors": {
    "inputEmailCodeConfirmationCodeErrorNotEmpty": "",
    "inputEmailCodeConfirmationCodeErrorRequired": "",
    "inputEmailCodePasswordErrorMatches": "",
    "inputEmailCodePasswordErrorMin8": "",
    "inputEmailCodePasswordErrorRequired": ""
  },
  "labels": {
    "inputEmailCodeEmailCodeLabel": "",
    "inputEmailCodePasswordLabel": ""
  },
  "placeholders": {
    "inputEmailCodeEmailCodePlaceholder": "",
    "inputEmailCodePasswordPlaceholder": ""
  },
  "titles": {
    "inputEmailCodeTitle": ""
  },
  "messages": {
    "inputEmailCodeBottomMessage": "",
    "inputEmailCodeEmailAddressConfirmation": "",
    "inputEmailCodeReceiverConfirmation": "",
    "inputEmailCodeWrongEmail": ""
  },
  "buttons": {
    "inputEmailCodeSubmitButton": ""
  }
}
