import React, {createContext, ReactNode, useContext, useMemo} from 'react';
import {
  useCreatePlMutation,
  useGetPlQuery,
  useUpdateSlMutation,
} from '../services/ghgci-sfe';
import { PlEntities, SlEntities } from '../services/types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import useAuth from 'configs/AuthContext';
import { ApiUserResponse, Item, User } from './types';

export interface BasicSlUserData {
  firstname: string;
  lastname: string;
  email: string;
  profilepicture: string;
  role: string;
}

export interface ExtendedSlUserData extends BasicSlUserData {
  userId: string;
}

//TYPES
type UserContextType = {
  handleCreateUser: () => void;
  isCreateUserLoading: boolean;
  isCreateUserSuccess: boolean;
  userCreationError: FetchBaseQueryError | SerializedError | undefined;
  resetCreateUserQueryValues: () => void;

  handleGetUser: () => Item[];
  handleGetAllUsers: () => User[];
  isGetUserFetching: boolean;
  isGetUserLoading: boolean;
  isGetUserSuccessful: boolean;

  handleUpdateUser: (params: ExtendedSlUserData) => void;
  isUpdateUserLoading: boolean;
  isUpdateUserSuccessful: boolean;
  userUpdateError: FetchBaseQueryError | SerializedError | undefined;
  resetUpdateUserQueryValues: () => void;

  handleGetClients: () => [];
};

//DEFAULT CONTEXT
const defaultUserContext: UserContextType = {
  handleCreateUser: () => {},
  isCreateUserLoading: false,
  isCreateUserSuccess: false,
  userCreationError: {},
  resetCreateUserQueryValues: () => {},

  handleGetUser: () => [],
  handleGetAllUsers: () => [],
  isGetUserSuccessful: false,
  isGetUserLoading: false,
  isGetUserFetching: false,

  handleUpdateUser: () => {},
  isUpdateUserLoading: false,
  isUpdateUserSuccessful: false,
  userUpdateError: {},
  resetUpdateUserQueryValues: () => {},

  handleGetClients: () => [],
};

const DataContext = createContext(defaultUserContext);

//PROVIDER
export const UserDataProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();
  // Todo - This ghgciId is provided by Cognito upon login this will be replaced when we connect cognito signup
  const ghgciId = useMemo(() => {
    return auth.user?.getSignInUserSession()?.getIdToken()?.payload['custom:ghgci_id'] || null;
  }, [auth.user]);

    const { isFetching: isGetUserFetching, isLoading: isGetUserLoading, isSuccess: isGetUserSuccessful } = useGetPlQuery(
        {
          typename: PlEntities.User,
          id: ghgciId || '',
        },
        { skip: !ghgciId }
    );

  const handleGetUser = (): ApiUserResponse => {
    const { data } = useGetPlQuery(   {
          typename: PlEntities.User,
          id: ghgciId || '',
        },
        { skip: !ghgciId });
    // Check if data is not undefined before accessing its properties
    if (data) {
      return data.Items;
    } else {
      return {};
    }
  };
  const handleGetAllUsers = (): User[] => {
    const { data } = useGetPlQuery({
      typename: PlEntities.User,
      id: '',
    });
    // Check if data is not undefined before accessing its properties
    if (data) {
      return data.Items;
    } else {
      return {};
    }
  };

  const [
    createUser,
    {
      isLoading: isCreateUserLoading,
      isSuccess: isCreateUserSuccess,
      reset: resetCreateUserQueryValues,
    },
  ] = useCreatePlMutation();

  const handleCreateUser = async () => {
    const currentUserEmail = auth.user?.getSignInUserSession()!.getIdToken()
      .payload.email;
    createUser({
      typename: PlEntities.User,
      id: currentUserEmail,
      requestBody: {
        firstname: 'Example',
        lastname: 'Diaz',
        role: 'User',
        email: 'carlos.diaz@ghgaccounting.ca',
        phone: '123-123-1231',
      },
    }).unwrap();
  };

  const [
    updateUser,
    {
      isLoading: isUpdateUserLoading,
      isSuccess: isUpdateUserSuccessful,
      error: userUpdateError,
      reset: resetUpdateUserQueryValues,
    },
  ] = useUpdateSlMutation();

  const handleUpdateUser = async (params: ExtendedSlUserData) => {
    const { firstname, lastname, email, profilepicture, role, userId } = params;
    updateUser({
      typename: PlEntities.User,
      id: ghgciId,
      requestBody: {
        id: 1,
        firstname,
        lastname,
        role,
        email,
        profilepicture,
        typename: SlEntities.User,
      },
    }).unwrap();
  };

  const handleGetClients = (): [] => [];

  return (
    <DataContext.Provider
      value={{
        handleCreateUser,
        isCreateUserLoading,
        isCreateUserSuccess,
        resetCreateUserQueryValues,
        //
        handleGetUser,
        isGetUserFetching,
        isGetUserLoading,
        //
        handleUpdateUser,
        isUpdateUserLoading,
        isUpdateUserSuccessful,
        userUpdateError,
        resetUpdateUserQueryValues,
        //
        handleGetClients,
        //
        handleGetAllUsers,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useUserDataContext = () => useContext(DataContext);
