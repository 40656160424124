{
    "taskTableLoadingMessage": "... No Tasks to display",
    "searchTaskPlaceholder": "Search Task",
    "description": "Description",
    "buttons":{
        "add":"ADD TASK"
    },
    "messages": {
        "successfulTaskCreation": "Task Created",
        "successfulTaskDeletion": "1 Task deleted"
    },
    "headers": {
        "taskName": "Task",
        "notes": "Notes",
        "priority": "Priority",
        "dueDate": "Due Date",
        "edit": "Edit",
        "client": "Client",
        "clientId": "Client ID",
        "taskId": "Task ID"
    },
    "titles": {
        "active": "Active",
        "completed": "Completed",
        "overDue": "Overdue",
        "today": "Today",
        "undoTaskDelete": "Undo",
        "upcoming": "Upcoming",
        "seeAllTasks": "See All Tasks",
        "addTask": "ADD TASK",
        "priority":"Priority"
    },
    "displayTaskTable":{
        "displayTableFetchingErrorMessage": "There was an error",
        "displayTableNoItemsMessage": "No Items to display"
    },
    "priorities":{
        "levels": {
            "high": "High",
            "low": "Low",
            "medium": "Medium",
            "urgent": "Urgent"
        }
    },
    "labels":{
        "dueDate": "Due Date",
        "taskName": "Task Name"
    },
    "form":{
        "dropdownClientNone": "None",
        "dropdownClientDefault": "Client"
    }

}
