{
    "shortenedWeekDays":{
        "friday": "FR",
        "monday": "MO",
        "saturday": "SA",
        "sunday": "SU",
        "thursday": "TH",
        "tuesday": "TU",
        "wednesday": "WE"
    }
}
