import { NewTaskDataType } from 'pages/task-manager/api/task-type';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  FormControl,
  useTheme,
  OutlinedInput,
  MenuItem,
  Select,
  InputLabel,
  Box,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTaskDataContext } from '../../../../react-query-toolkit/state/tasks-context';
import { ClientIcon } from 'icons/client-icon';
import {
  highTaskPriority,
  lowTaskPriority,
  mediumTaskPriority,
  UrgentTaskPriority,
} from 'pages/task-manager/components/variables';
import { flagPriorityHandler } from 'pages/task-manager/components/utils';
import CloseIcon from '@mui/icons-material/Close';
import {
  DateSelector,
  ReceivedValue,
} from 'components/date-picker/components/date-selector';
import { useClientDataContext } from '../../../../react-query-toolkit/state/client-context';
import { DateTime } from 'luxon';

export type TaskModalFormProps = {
  data?: NewTaskDataType[];
  selectedData: NewTaskDataType;
  modalType: string;
  isOpen: boolean;
  toggleModal?: Dispatch<SetStateAction<boolean>>;
};

export const TaskModalForm = (props: TaskModalFormProps) => {
  const { t: translate } = useTranslation();
  const { selectedData, toggleModal, modalType, isOpen } = props;
  const {
    handleCreateTask,
    handleUpdateTask,
    isCreateTaskLoading,
    isCreateTaskSuccess,
    isUpdateTaskLoading,
    isUpdateTaskSuccess,
    resetCreateTaskQueryValues,
    resetUpdateTaskQueryValues,
  } = useTaskDataContext();

  const { handleGetClients } = useClientDataContext();
  const clientData = handleGetClients() || [];

  const CLIENT_LIST_OPTIONS = [
    { value: '', label: translate?.('taskFormDropdownClientNone') || 'None' }, // Empty option
    ...clientData.map((client) => ({
      value: client.clientId,
      label: (
        <>
          <ClientIcon /> {client.clientName}
        </>
      ),
    })),
  ];

  const PRIORITY_LIST_OPTIONS = [
    {
      value: lowTaskPriority,
      label: (
        <Box>
          {flagPriorityHandler(lowTaskPriority)}
          {'  '}
          {translate?.('taskFormDropdownPriorityLow')}
        </Box>
      ),
    },
    {
      value: mediumTaskPriority,
      label: (
        <Box>
          {flagPriorityHandler(mediumTaskPriority)}
          {'  '}
          {translate?.('taskFormDropdownPriorityMedium')}
        </Box>
      ),
    },
    {
      value: highTaskPriority,
      label: (
        <Box>
          {flagPriorityHandler(highTaskPriority)}{' '}
          {translate?.('taskFormDropdownPriorityHigh')}
        </Box>
      ),
    },
    {
      value: UrgentTaskPriority,
      label: (
        <Box>
          {flagPriorityHandler(UrgentTaskPriority)}{' '}
          {translate?.('taskFormDropdownPriorityUrgent')}
        </Box>
      ),
    },
  ];

  const [formData, setFormData] = useState<NewTaskDataType>(selectedData);
  const handleChange = <K extends keyof NewTaskDataType>(
    field: K,
    value: NewTaskDataType[K],
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  useEffect(() => {
    // Update formData whenever selectedData changes
    setFormData(selectedData);
  }, [selectedData]);

  function closeModalHandler() {
    toggleModal?.(false);
    document.body.style.overflow = 'visible';
  }

  const onSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (modalType === 'create') {
      handleCreateTask(
        formData.taskName,
        formData.priority,
        formData.dueDate,
        formData.notes,
        formData.clientId,
      );
    } else {
      handleUpdateTask(
        formData.taskId!,
        formData.taskName,
        formData.priority,
        formData.isCompleted,
        formData.dueDate,
        formData.notes,
        formData.clientId,
      );
    }
    form.reset();
  };

  const formDisabled =
    isCreateTaskLoading || isCreateTaskSuccess || isUpdateTaskLoading;

  useEffect(() => {
    if ((isCreateTaskSuccess && isOpen) || (isUpdateTaskSuccess && isOpen))
      closeModalHandler();
    resetCreateTaskQueryValues();
    resetUpdateTaskQueryValues();
  }, [isCreateTaskSuccess, isUpdateTaskSuccess]);

  const theme = useTheme();
  const [dueDateValue, setDueDateValue] = React.useState<ReceivedValue>(
    DateTime.fromISO(formData.dueDate).toISO() as
      | string
      | Record<string, unknown>,
  );

  useEffect(() => {
    setDueDateValue(DateTime.fromISO(formData.dueDate).toISO() as string);
  }, [formData.dueDate]);

  const [openDueDate, setDueActive] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={isOpen}
      onClose={closeModalHandler}
      maxWidth={false} // Disable the predefined maxWidth to use custom width
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '630px',
          width: '100%',
        },
        zIndex: 99999,
      }} // Custom maxWidth
    >
      <DialogTitle
        component="div"
        sx={{
          paddingTop: theme.spacing(10),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
          <IconButton
            onClick={() => closeModalHandler()}
            sx={{
              p: 0,
              color: theme.palette.textColor.main,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          paddingTop: `${theme.spacing(3)} !important`,
        }}
      >
        <Box component="form" onSubmit={onSubmitForm} sx={{ mt: 2 }}>
          <Grid container spacing={isMobile ? 4 : 6}>
            <Grid size={{ xs: 12 }}>
              <TextField
                fullWidth
                label={translate?.('taskFormName')}
                value={formData.taskName}
                onChange={(e) => handleChange('taskName', e.target.value)}
                required
              />
            </Grid>

            <Grid size={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label={translate?.('taskFormDescription')}
                value={formData.notes}
                onChange={(e) => handleChange('notes', e.target.value)}
              />
            </Grid>
            <Grid container size={{ xs: 12, md: 12 }} spacing={3}>
              <Grid size={{ xs: 12, md: 3 }}>
                <DateSelector
                  name={translate?.('taskFormDueDateText')}
                  dateData={dueDateValue}
                  dateUpdater={(newValue) => {
                    setDueDateValue(newValue),
                      handleChange(
                        'dueDate',
                        DateTime.fromISO(newValue as string).toISO() as string,
                      );
                  }}
                />
                {/* <DesktopDatePicker
                  label={translate?.('taskFormDueDateText')}
                  open={openDueDate}
                  onClose={() => setDueActive(false)}
                  disableOpenPicker
                  slotProps={{
                    textField: {
                      onClick: () => setDueActive(true),
                    },
                    popper: {
                      sx: {
                        zIndex: '99999 !important',
                        '.MuiPickersArrowSwitcher-button': {
                          color: theme.palette.primary.main,
                        },
                      },
                    },
                  }}
                  value={dueDateValue}
                  onChange={(newValue) => {
                    setDueDateValue(newValue); // Update the local closeDateValue state
                    handleChange(
                      'dueDate',
                      dayjs(newValue).format('YYYY-MM-DD'),
                    );
                  }}
                  sx={{ width: '100%' }}
                /> */}
              </Grid>

              <Grid size={{ xs: 12, md: 3 }}>
                <FormControl fullWidth>
                  <InputLabel>Priority</InputLabel>
                  <Select
                    value={formData.priority}
                    onChange={(e) => handleChange('priority', e.target.value)}
                    input={
                      <OutlinedInput
                        label={translate?.('taskFormDropdownPriorityTitle')}
                      />
                    }
                    MenuProps={{
                      style: { zIndex: 99999 },
                    }}
                  >
                    {PRIORITY_LIST_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, md: 3 }}>
                <FormControl fullWidth>
                  <InputLabel>
                    {translate?.('taskFormDropdownClientDefault')}
                  </InputLabel>
                  <Select
                    value={formData.clientId}
                    onChange={(e) => handleChange('clientId', e.target.value)}
                    input={
                      <OutlinedInput
                        label={translate?.('taskFormDropdownClientDefault')}
                      />
                    }
                    MenuProps={{
                      style: { zIndex: 99999 },
                    }}
                  >
                    {CLIENT_LIST_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {/* Action buttons */}
            <Grid
              container
              direction={{ md: 'row', sm: 'column' }}
              px={{ md: 6, sm: 4 }}
              justifyContent="space-between"
              sx={{ paddingBottom: theme.spacing(10) }}
            >
              <Button
                variant="outlined"
                onClick={closeModalHandler}
                sx={{
                  mt: { sm: 2, md: 0 },
                  mb: { sm: 2, md: 0 },
                  mr: { md: 3, sm: 0 },
                }} // Add margin between buttons
              >
                {translate?.('formCancelButton')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  formDisabled || !formData.taskName || !formData.dueDate
                }
              >
                {modalType === 'create'
                  ? translate?.('taskFormDropdownPriorityAddButton')
                  : translate?.('formSaveButton')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
