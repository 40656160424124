import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Heading, Span, TextField } from 'components';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Box, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAuth from 'configs/AuthContext';
import { useState } from 'react';
import { MessageContainer } from 'pages/forgot-password/components/styled';
import { useTranslateAll } from '../../../locales/translation-hooks';

export interface ResetPasswordRequestProps {
  nextStep: () => void;
  prevStep: () => void;
  userEmail: string;
}

export const InputEmailCode = (props: ResetPasswordRequestProps) => {
  const { translateForgottenPassword } = useTranslateAll(['forgottenPassword']);

  const resetPasswordSchema = yup.object().shape({
    confirmationCode: yup
      .string()
      .required(
        translateForgottenPassword(
          'errors.inputEmailCodePasswordErrorRequired',
        ),
      )
      .matches(
        /(.|\s)*\S(.|\s)*/,
        translateForgottenPassword(
          'errors.inputEmailCodeConfirmationCodeErrorNotEmpty',
        ),
      ),
    password: yup
      .string()
      .required(
        translateForgottenPassword(
          'errors.inputEmailCodePasswordErrorRequired',
        ),
      )
      .min(
        8,
        translateForgottenPassword('errors.inputEmailCodePasswordErrorMin8'),
      )
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&;]+$/,
        translateForgottenPassword('errors.inputEmailCodePasswordErrorMatches'),
      ),
  });
  const {
    control,
    handleSubmit,
    trigger,
    formState: { isValid, errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      confirmationCode: '',
      password: '',
    },
    resolver: yupResolver(resetPasswordSchema),
  });

  const navigate = useNavigate();
  const { confirmPassword } = useAuth();
  const [error, setError] = useState<string>('');
  const [showEmail, setShowEmail] = useState(false);

  async function onSubmit(_data: FieldValues) {
    try {
      confirmPassword(
        props.userEmail,
        _data.confirmationCode,
        _data.password,
        () => setError(''),
        (err: Error) => {
          setError(err.message);
        },
      );
      props.nextStep();
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  }

  const checkFieldValidity = async (
    fieldName: 'confirmationCode' | 'password',
  ) => {
    await trigger(fieldName);
  };

  return (
    <>
      <div style={{ marginBottom: '24px' }}>
        <Heading level={1} color="secondaryBlue">
          {translateForgottenPassword('titles.inputEmailCodeTitle')}
        </Heading>
      </div>
      <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              onBlur={() => {
                checkFieldValidity('password');
              }}
              onChange={(e) => {
                field.onChange(e);
                clearErrors();
              }}
              label={translateForgottenPassword(
                'labels.inputEmailCodePasswordLabel',
              )}
              placeholder={translateForgottenPassword(
                'placeholders.inputEmailCodePasswordPlaceholder',
              )}
              type="password"
              status={
                errors.password ? 'error' : isValid ? 'success' : undefined
              }
              errorMessage={errors.password && errors.password.message}
            />
          )}
        />
        <Controller
          name="confirmationCode"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              onBlur={() => {
                checkFieldValidity('confirmationCode');
              }}
              onChange={(e) => {
                field.onChange(e);
                clearErrors();
              }}
              label={translateForgottenPassword(
                'labels.inputEmailCodeEmailCodeLabel',
              )}
              placeholder={translateForgottenPassword(
                'placeholders.inputEmailCodeEmailCodePlaceholder',
              )}
              type="text"
              status={
                errors.confirmationCode
                  ? 'error'
                  : isValid
                    ? 'success'
                    : undefined
              }
              errorMessage={
                errors.confirmationCode && errors.confirmationCode.message
              }
            />
          )}
        />

        <div style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }}>
          <Button type="submit">
            {translateForgottenPassword('buttons.inputEmailCodeSubmitButton')}
          </Button>
        </div>
        {error != '' ? <p>{error} </p> : ''}
        <MessageContainer
          onClick={() => {
            setShowEmail(true);
          }}
        >
          <Span textDecoration={'underline'} color={'bluePrimary'}>
            {translateForgottenPassword(
              'messages.inputEmailCodeReceiverConfirmation',
            )}
          </Span>
          <Span fontSize={'13px'} color="spanText">
            {showEmail
              ? translateForgottenPassword(
                  'messages.inputEmailCodeEmailAddressConfirmation',
                  {
                    userEmail: props.userEmail,
                  },
                )
              : ''}
          </Span>
          {showEmail && (
            <Link onClick={() => props.prevStep()} variant={'link'}>
              {translateForgottenPassword('messages.inputEmailCodeWrongEmail')}
            </Link>
          )}
        </MessageContainer>

        <div
          style={{ width: '100%', marginTop: '12px', marginBottom: '32px' }}
        ></div>
      </form>
      <Box display={'flex'} alignContent={'flex-start'} width={'100%'}>
        <Link onClick={() => navigate('/login')} variant={'link'}>
          {translateForgottenPassword(
            'messages.resetPasswordRequestBottomMessage',
          )}
        </Link>
      </Box>
    </>
  );
};
