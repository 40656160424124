import { Amplify } from 'aws-amplify';

interface EnvConfig {
  "ghgci-identitypool": string;
  "ghgci-userpool": string;
  "ghgci-userpoolclient": string;
  build?: string; // Optional if you want to use the build value for debugging or tracking
}

export const loadAmplifyConfig = async () => {
  try {
    const response = await fetch('/env.json'); // Fetch the new configuration from env.json
    if (!response.ok) {
      throw new Error(`Failed to load Amplify configuration: ${response.statusText}`);
    }

    const config: EnvConfig = await response.json();
    console.log('Raw Env JSON Config:', config);

    // Extract parameters directly from the env.json structure
    const identityPoolId = config["ghgci-identitypool"];
    const userPoolId = config["ghgci-userpool"];
    const userPoolWebClientId = config["ghgci-userpoolclient"];

    // Log extracted values
    console.log('Extracted Config Values:', { identityPoolId, userPoolId, userPoolWebClientId });

    // Validate extracted values
    if (!identityPoolId || !userPoolId || !userPoolWebClientId) {
      throw new Error(
          `Missing required configuration values:
        identityPoolId: ${identityPoolId || 'undefined'},
        userPoolId: ${userPoolId || 'undefined'},
        userPoolWebClientId: ${userPoolWebClientId || 'undefined'}`
      );
    }

    // Map JSON configuration to Amplify config
    const amplifyConfig = {
      Auth: {
        identityPoolId,
        userPoolId,
        userPoolWebClientId,
        region: 'ca-central-1',
        mandatorySignIn: true,
        authenticationFlowType: 'USER_SRP_AUTH',
        cookieStorage: {
          domain: document.location.hostname,
          secure: false,
          path: '/',
          expires: 365,
        },
      },
      Storage: {
        AWSS3: {
          bucket: 'ghgci-assets',
          region: 'ca-central-1',
        },
      },
      Analytics: {
        disabled: true,
      },
    };

    // Configure Amplify
    Amplify.configure(amplifyConfig);
    console.log('Amplify configured successfully with dynamic settings:', amplifyConfig);
  } catch (error) {
    console.error('Error loading Amplify configuration:', error);
    throw error; // Halt app initialization if configuration fails
  }
};
