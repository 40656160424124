{
    "requests":{
        "accountAddress": "Adresse",
        "accountCity": "Ville",
        "accountClient": "Client",
        "accountContactName": "Nom du contact",
        "accountCountry": "Pays",
        "accountEmail": "E-mail",
        "accountPhone": "Téléphone",
        "accountRegion": "Région",
        "accountState": "État/Province",
        "errors":{
            "default": "Une erreur est survenue",
            "selectionRequired": "Une sélection est requise"
        }
    },
    "reviewing":{
        "reviewYourInfoClientInfoTitle": "Verifiez vos informations client",
        "reviewYourInfoTitle": "Vérifiez vos informations"
    },
    "services":{
        "arrItem1": "Suivre l'inventaire des GES",
        "arrItem2": "IC Intensité Carbone",
        "arrItem3": "Perspective climatique",
        "arrItem4": "Projet de compensation",
        "arrItem5": "Autre",
        "interestQuestion": "Quel service vous intéresse ?"


    },
    "tellUsMore":{
        "orgSizes":{
            "arrItem1": "1-50 employés",
            "arrItem2": "51-500 employés",
            "arrItem3": "501 - 1000 employés",
            "arrItem4": "1001+ employés"
        },
        "orgTypes":{
            "arrItem1": "Gouvernement (Régional ou Local)",
            "arrItem2": "Entreprise / Société",
            "arrItem3": "Industrie",
            "arrItem4": "Organisation à but non lucratif / Charité",
            "arrItem5": "Établissement d’enseignement",
            "arrItem6": "Groupe communautaire",
            "arrItem7": "Autres"
        },
        "subTitles":{
            "subTitle1": "Quel est le type d’organisation ?",
            "subTitle2": "Quelle est la taille de votre organisation ?",
            "serviceProfile": "Quel service vous intéresse ?"
        },
        "titles":{
            "title": "Dites-nous en plus",
            "profileTitle": "Profil de Service",
            "profileCommentTitle": "Commentaires"
        }
    }
}
