import { Stack } from '@mui/material';
import { Form } from '../form/form';
import { SectionHeader, SectionHeaderProps } from '../section-header';
import { FormValuesStateAndSetter } from '../../user-profile';
import { BasicJoinedUserSettingsType } from 'pages/user-profile/components/types';
import { FormControlFields } from 'pages/user-profile/components/types';
import {
  createGetPropertyTranslation,
  translateAll,
  useTranslateAll,
} from '../../../../locales/translation-hooks';

const { translateUserProfile } = translateAll(['userProfile']);

const passwordControls: FormControlFields[] = [
  {
    name: 'currentpassword',
    controlType: 'input',
    inputType: 'text',
    isRequired: true,
    get displayedName() {
      return createGetPropertyTranslation(
        'labels.password.current',
        translateUserProfile,
      );
    },
  },
  {
    name: 'newpassword',
    controlType: 'input',
    inputType: 'email',
    isRequired: true,
    get displayedName() {
      return createGetPropertyTranslation(
        'labels.password.new',
        translateUserProfile,
      );
    },
  },
  {
    name: 'confirmpassword',
    controlType: 'input',
    inputType: 'text',
    isRequired: true,
    get displayedName() {
      return createGetPropertyTranslation(
        'labels.password.confirm',
        translateUserProfile,
      );
    },
  },
];

export const Password = ({
  formInputValuesStateAndSetter,
  fetchedUserSettings,
  handleSubmit,
}: {
  formInputValuesStateAndSetter: FormValuesStateAndSetter;
  fetchedUserSettings: BasicJoinedUserSettingsType;
  handleSubmit: () => void;
}) => {
  const { translateUserProfile } = useTranslateAll(['userProfile']);

  const sectionHeader: SectionHeaderProps = {
    title: translateUserProfile('headings.password'),
    subtitle: translateUserProfile('subHeadings.password.main'),
  };

  return (
    <Stack gap={{ xs: 7, md: 6 }} bgcolor="white" borderRadius={2}>
      <SectionHeader
        title={sectionHeader.title}
        subtitle={sectionHeader.subtitle}
      />
      <Form
        area="password"
        formControlsInfo={passwordControls}
        formInputValuesStateAndSetter={formInputValuesStateAndSetter}
        fetchedUserSettings={fetchedUserSettings}
        handleSubmit={handleSubmit}
      />
    </Stack>
  );
};
