import React from 'react';
import { Container } from 'pages/dashboard/styled';
import {
  CardText,
  ThankYouContainer,
  ThankYouTextTitle,
} from 'pages/request-account/components/styled';
import { PageWrapper } from 'components';
import { Box } from '@mui/material';
import { useTranslateAll } from '../../../locales/translation-hooks';
export function ThankYouDisplay(): JSX.Element {
  const { translateCommon } = useTranslateAll(['common']);

  return (
    <PageWrapper>
      <Box
        display={'flex'}
        alignItems={'center'}
        height={'100%'}
        alignContent={'center'}
        justifyContent={'center'}
      >
        <Container flexDirection={'column'}>
          <ThankYouContainer>
            <ThankYouTextTitle>
              {translateCommon('titles.thankYouDisplayTitle')}
            </ThankYouTextTitle>
            <CardText>
              {translateCommon('messages.thankYouDisplayMessage')}
            </CardText>
          </ThankYouContainer>
        </Container>
      </Box>
    </PageWrapper>
  );
}
