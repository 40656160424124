import Cognito, { CognitoUser } from '@aws-amplify/auth';
import React, { createContext, useContext, useEffect, useState } from 'react';

export type AuthChallengeName =
  | 'NEW_PASSWORD_REQUIRED'
  | 'SMS_MFA'
  | 'SOFTWARE_TOKEN_MFA'
  | 'MFA_SETUP';

export interface UserAttributes {
  sub: string;
  email: string;
  email_verified: string;
  given_name: string;
  name: string;
  updated_at: string;
  'custom:bytesQuota': string;
  'custom:bytesUsed': string;
}

export type AuthUser = CognitoUser & {
  challengeName: AuthChallengeName;
  attributes: UserAttributes;
};

interface AuthContextType {
  user: AuthUser | null;
  signin: (
    user: string,
    password: string,
    callback: VoidFunction,
    // eslint-disable-next-line
    fail: (err: any) => void,
  ) => void;
  handleChangePassword: (
    currentPassword: string,
    newPassword: string,
    callback: VoidFunction,
    // eslint-disable-next-line
    fail: (err: any) => void,
  ) => void;
  forgotPassword: (
    user: string,
    callback: VoidFunction,
    // eslint-disable-next-line
    fail: (err: any) => void,
  ) => void;
  signout: (callback: VoidFunction) => void;
  completeNewPassword: (newPass: string, callback: VoidFunction) => void;
  confirmPassword: (
    email: string,
    password: string,
    code: string,
    callback: VoidFunction,
    fail: (err: any) => void,
  ) => void;
}

const initialAuthContext = {
  user: null,
  signin: () => {},
  handleChangePassword: () => {},
  forgotPassword: () => {},
  signout: () => {},
  completeNewPassword: () => {},
  confirmPassword: () => {},
};

export const currentAuthenticatedUser = (): {
  read(): CognitoUser | null | undefined;
} => {
  let status = 'pending';
  let result: CognitoUser | null;
  const suspender = Cognito.currentAuthenticatedUser().then(
    (user) => {
      status = 'success';
      result = user;
    },
    (error) => {
      status = 'error';
      result = null;
    },
  );
  return {
    read() {
      if (status === 'pending') {
        throw suspender;
      } else if (status === 'error') {
        return null;
      } else if (status === 'success') {
        return result;
      }
    },
  };
};

export const AuthContext = createContext<AuthContextType>(initialAuthContext);

export function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [user, setUser] = useState<AuthUser | null>(null);

  const signin = (
    email: string,
    password: string,
    callback: VoidFunction,
    // eslint-disable-next-line
    fail: (err: any) => void,
  ) => {
    return Cognito.signIn(email, password)
      .then((user: AuthUser) => {
        setUser(user);
        callback();
      })

      .catch((err) => fail(err));
  };

  useEffect(() => {
    Cognito.currentAuthenticatedUser()
        .then((authenticatedUser) => {
          setUser(authenticatedUser as AuthUser); // Cast to AuthUser if necessary
        })
        .catch((err) => {
          console.log('User is not authenticated:', err);
          setUser(null); // Clear user state if not authenticated
        });
  }, []);

  const handleChangePassword = (
    currentPassword: string,
    newPassword: string,
    callback: VoidFunction,
    // eslint-disable-next-line
    fail: (err: any) => void,
  ) => {
    if (user) {
      Cognito.changePassword(user, currentPassword, newPassword)
        .then(() => {
          callback();
        })
        .catch((err) => fail(err));
    } else {
      fail(new Error('User is not authenticated'));
    }
  };

  const forgotPassword = (
    email: string,
    callback: VoidFunction,
    // eslint-disable-next-line
    fail: (err: any) => void,
  ) => {
    return Cognito.forgotPassword(email)
      .then(() => {
        callback();
      })
      .catch((err) => fail(err));
  };

  const confirmPassword = (
    email: string,
    code: string,
    password: string,
    callback: VoidFunction,
    // eslint-disable-next-line
    fail: (err: any) => void,
  ) => {
    return Cognito.forgotPasswordSubmit(email, code, password)
      .then(() => {
        callback();
      })
      .catch((err) => fail(err));
  };

  const signout = async (callback: VoidFunction) => {
    try {
      user?.signOut();
      await Cognito.signOut();
      setUser(null);
      callback();
    } catch (err) {
      console.error(err); // handle error
    }
  };

  const completeNewPassword = (newPass: string, callback: VoidFunction) => {
    user?.completeNewPasswordChallenge(
      newPass,
      {},
      {
        onSuccess: () => {
          Cognito.currentAuthenticatedUser().then((user) => {
            setUser(user);
            callback();
          });
        },
        onFailure: (err) => {
          throw err;
        },
      },
    );
  };

  useEffect(() => {
    if (!user) {
      Cognito.currentAuthenticatedUser()
        .then((user) => setUser(user))
        .catch((e) => {
          console.log('User is not authenticated', e);
        });
    }
  }, [user]);

  const value = {
    user,
    signin,
    handleChangePassword,
    forgotPassword,
    signout,
    completeNewPassword,
    confirmPassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default function useAuth(): AuthContextType {
  return useContext(AuthContext);
}
