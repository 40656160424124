import React, { ReactNode } from 'react';
import { Tooltip } from '@mui/material';
import { TruncatedTextCell } from 'pages/clients/components/clients-table/styled';
import { useTranslateAll } from '../locales/translation-hooks';

// This component will wrap component children. It will take a text to be displayed as tooltip title (copyText) when hovering, if the element is clicked then the text (copyText) is  copied to the clipboard .
// This component is meant to be used with TansTack as it is truncated. In other words: if a word is too big it will show (...) at the end example: "This is an exam..."
// Please see the regular copy-to-clipboard component for non TanStack usages

// Usage:
//
// <CopyToClipBoardTruncated copyText={'Your Text'}>
// <YourComponent/>
// </CopyToClipBoard>
export const CopyToClipboardTruncated = ({
  children,
  copyText,
}: {
  copyText?: string;
  children: ReactNode;
}) => {
  const HIDE_COPIED_MESSAGE_TIME = 2000;

  const [titleText, setTitleText] = React.useState(copyText);
  const { translateCommon } = useTranslateAll(['common']);

  const handleTooltipOpen = (copyText: string) => {
    navigator.clipboard.writeText(copyText);
    setTitleText(translateCommon('messages.textCopiedMessage'));
    setTimeout(() => {
      setTitleText(copyText);
    }, HIDE_COPIED_MESSAGE_TIME);
  };

  return (
    <Tooltip title={titleText}>
      <TruncatedTextCell
        onClick={() => handleTooltipOpen(copyText ? copyText : '')}
      >
        {children}
      </TruncatedTextCell>
    </Tooltip>
  );
};
