export const ProjectLogoDefault = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 77 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="36" cy="36" r="32" fill="#D9D9D9" />
    <rect x="20" y="27" width="15" height="15" fill="white" />
    <rect
      x="32"
      y="19"
      width="17"
      height="18"
      rx="8.5"
      fill="white"
      fillOpacity="0.3"
    />
    <path d="M37 26L49.9904 48.5H24.0096L37 26Z" fill="#F7F8FA" />
  </svg>
);
