{
  "clientAccount": {
    "title": "",
    "placeHolders": {
      "address": "",
      "city": "",
      "client": "",
      "contact": "",
      "country": "",
      "email": "",
      "phone": "",
      "region": "",
      "state": ""
    },
    "labels": {
      "city": "",
      "client": "",
      "contact": "",
      "email": "",
      "phone": "",
      "state": "",
      "address": ""
    },
    "errors": {
      "contactNotEmpty": "",
      "contactRequired": "",
      "emailInvalid": "",
      "emailRequired": "",
      "nameNotEmpty": "",
      "nameRequired": "",
      "phoneInvalid": "",
      "phoneRequired": ""
    }
  },
  "editClient": {
    "title": "",
    "labels": {
      "client": "",
      "firstName": "",
      "lastName": "",
      "phone": "",
      "email": "",
      "closeDate": "",
      "activeDate": "",
      "country": "",
      "stateProvince": "",
      "municipality": "",
      "city": "",
      "streetAddress": "",
      "postalCode": "",
      "organizationType": ""
    },
    "options": {
      "government": "",
      "company": "",
      "industry": "",
      "nonProfit": "",
      "educationalInstitution": "",
      "communityGroup": "",
      "others": ""
    },
    "buttons": {
      "cancel": "",
      "save": ""
    }
  }
}
