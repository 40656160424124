import React from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import { useTranslateAll } from '../../../locales/translation-hooks';

export function TaskSearchBar({
  value: initialValue,
  mobileSearchBarActive,
  setIconClicked,
  onChange,
  debounce = 500,
  inputRef,
  setMobileSearchBarActive,
}: {
  value: string | number;
  mobileSearchBarActive: boolean;
  setMobileSearchBarActive: React.Dispatch<React.SetStateAction<boolean>>;
  setIconClicked: React.Dispatch<React.SetStateAction<boolean>>;
  iconClicked: boolean;
  onChange: (value: string | number) => void;
  debounce?: number;
  inputRef: React.RefObject<HTMLInputElement>;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  const { translateProjects } = useTranslateAll(['projects']);

  const clearSearch = () => {
    setValue(''); // Clear local value
    onChange(''); // Clear parent value (globalFilter in TaskManager)
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  ); // Matches xs (mobile)
  return (
    <Box
      maxWidth={'auto'}
      width={!isMobile ? '40%' : '100%'}
      ml={isMobile ? (mobileSearchBarActive && isMobile ? 0 : 'auto') : 7}
    >
      <TextField
        variant="outlined"
        inputRef={inputRef}
        placeholder={translateProjects('searchProjectsPlaceholder')}
        fullWidth
        size="small"
        value={value}
        onBlur={() => {
          isMobile ?? setMobileSearchBarActive(false);
        }}
        onChange={(e) => setValue(e.target.value)} // Update Search Query
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="primary" />
            </InputAdornment>
          ),
          endAdornment: value && ( // Show 'X' button if search query exists
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  clearSearch();
                  setIconClicked(false);
                  setMobileSearchBarActive(false);
                }}
                size="small"
                sx={{ padding: 0 }}
              >
                <ClearIcon color="primary" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          '.MuiInputBase-input': { fontVariant: 'body2' },
        }}
      />
    </Box>
  );
}
