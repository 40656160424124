{

    "form":{
        "forgottenPassword": "Forgot Password",
        "accountRequest": "Don't have an account?",
        "buttons":{
            "login": "LOG IN",
            "requestAccount": "REQUEST ACCOUNT"
        },
        "labels":{
            "email": "Email",
            "password": "Password"
        },
        "placeHolders":{
            "password": "Password",
            "email": "Email"
        }
    },
    "schema":{
        "passwordRequirements":{
            "lowercase": "Password must contain at least 1 lowercase character",
            "minLength": "Password must be at least 8 characters",
            "number": "Password must contain at least 1 number",
            "required": "Password is required",
            "specialCharacter": "Password must contain at least 1 special character",
            "uppercase": "Password must contain at least 1 uppercase character"
        },
        "emailRequirements":{
            "emailRequired": "Email is required",
            "invalidEmail": "Invalid email address"
        }
    }
}
