{
    "clientAccount":{
            "title": "Client Account Information",
            "placeHolders":{
                "address": "Address...",
                "city": "City...",
                "client": "ClientName...",
                "contact": "Contact Name...",
                "country": "Country...",
                "email": "Email...",
                "phone": "Phone Number...",
                "region": "Region...",
                "state": "State..."
            },
            "labels":{
                "city": "City",
                "client": "Client *",
                "contact": "Contact Name*",
                "email": "Email *",
                "phone": "Phone *",
                "state":"State *",
                "address":"Address *"
            },
            "errors":{
                "contactNotEmpty": "Contact Name cannot be empty.",
                "contactRequired": "Contact Name is required",
                "emailInvalid": "Invalid email address",
                "emailRequired": "Email is required",
                "nameNotEmpty": "Client Name cannot be empty.",
                "nameRequired": "Client Name is required",
                "phoneInvalid": "Invalid phone number.",
                "phoneRequired": "Phone Number is required"
            }
        },
    "editClient":{
        "title": "Edit Client",
        "labels":{
            "client": "Client",
            "firstName": "Name",
            "lastName": "Last Name",
            "phone": "Phone",
            "email": "E-mail",
            "closeDate": "Close Date",
            "activeDate": "Active Date",
            "country": "Country",
            "stateProvince": "State/Province",
            "municipality": "Municipality",
            "city": "City",
            "streetAddress": "Street Address",
            "postalCode": "Postal Code",
            "organizationType": "Organization Type"
        },
        "options":{
            "government": "Government (Regional or Local)",
            "company": "Company / Business",
            "industry": "Industry",
            "nonProfit": "Non-profit / Charity",
            "educationalInstitution": "Educational Institution",
            "communityGroup": "Community Group",
            "others": "Others"
        },
        "buttons":{
            "cancel": "Cancel",
            "save": "Save"
        }
    }
}
