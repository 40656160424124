{
    "requests":{
        "accountAddress": "Address",
        "accountCity": "City",
        "accountClient": "Client",
        "accountContactName": "Contact Name",
        "accountCountry": "Country",
        "accountEmail": "Email",
        "accountPhone": "Phone",
        "accountRegion": "Region",
        "accountState": "State/Province",
        "errors":{
            "default": "There was an error",
            "selectionRequired": "Selection is required"
        }
    },
    "reviewing":{
        "reviewYourInfoClientInfoTitle": "Client Information",
        "reviewYourInfoTitle": "Review Your Information"
    },
    "services":{
        "arrItem1": "Track GHG Inventory",
        "arrItem2": "CI Carbon Intensity",
        "arrItem3": "Climate Lens",
        "arrItem4": "Offset Project",
        "arrItem5": "Other",
        "interestQuestion": "What service are you interesting in?"


    },
    "tellUsMore":{
        "orgSizes":{
            "arrItem1": "1-50 employees",
            "arrItem2": "51-500 employees",
            "arrItem3": "501 - 1000 employees",
            "arrItem4": "1001+ employees"
        },
        "orgTypes":{
            "arrItem1": "Government (Regional or Local)",
            "arrItem2": "Company / Business",
            "arrItem3": "Industry",
            "arrItem4": "Non-profit / Charity",
            "arrItem5": "Educational Institution",
            "arrItem6": "Community Group",
            "arrItem7": "Others"
        },
        "subTitles":{
            "subTitle1": "What is the type of organization?",
            "subTitle2": "What is the size of organization?",
            "serviceProfile": "What service are you interesting in?"
        },
        "titles":{
            "title": "Tell Us More",
            "profileTitle": "Service Profile",
            "profileCommentTitle": "COMMENTS"
        }
    }
}
