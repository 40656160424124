{
    "title":"User Profile",
    "headings":{
        "profile":"Profile",
        "password":"Password",
        "formats":"Formats"
    },
    "subHeadings":{
        "profile":{
            "main":"Update your photo and personal details here."
        },
        "password":{
            "main":"Change Password"
        },
        "formats":{
            "main":"Formats"
        }
    },
    "labels":{
        "profile":{
            "photo":"Photo",
            "firstName":"First Name",
            "lastName":"Last Name",
            "phone":"Phone",
            "jobTitle":"Job Title",
            "loginEmail":"Login Email",
            "noticeEmail":"Notice Email",
            "timeZone":"Time Zone",
            "location":"Location"
        },
        "password":{
            "current":"Current Password",
            "new":"New Password",
            "confirm":"Confirm Password"
        },
        "formats":{
            "dateFormat":"Date Format",
            "timeFormat":"Time Format",
            "numberFormat":"Number Format"
        }
    },
    "buttons":{
        "save":"Save",
        "cancel":"Cancel",
        "delete":"Delete",
        "upload":"Upload"
    },
    "options":{
        "formats":{
            "dateFormat":{
            },
            "timeFormat":{
                "12":"12 Hour",
                "24":"24 Hour"
            },
            "numberFormat":{
                "comma":"Comma",
                "period":"Period"
            }
        }
    }
}
