import { Cell, ColumnDef } from '@tanstack/react-table';
import { ActiveIcon, ArrowDownWithBorder, ArrowRightWithBorder } from 'icons';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
import {
  ClientSubComponentHeaders,
  DataVisibilityType,
  NewClientDataType,
  NewDataType,
} from 'pages/clients/api/get-clients';
import { ClientModalForm } from 'pages/clients/components/popUp-Modal/client-modal-form';
import { Container } from 'pages/dashboard/styled';
import { useEffect, useState } from 'react';
import { LG, MD } from 'utils/breakpoints';
import { spacingWithPx } from 'utils/styles';
import { getWindowDimensions } from 'utils/window-utils';
import { DisplayTable } from './components/clients-table';
import { ExpandingButtonContainer } from './components/clients-table/styled';
import { RenderSubComponent } from 'pages/clients/components/clients-table/render-sub-component';
import { useClientDataContext } from '../../react-query-toolkit/state/client-context';
import { isFirstDatePastSecondDate } from 'utils/date-validations';
import { PageWrapper } from 'components/molecules/page-wrapper/page-wrapper';
import { PageTitle } from 'components/atoms/page-title/page-title';
import { CopyToClipboardTruncated } from 'utils/copy-to-clipboard-truncated';
import { useUserDataContext } from '../../react-query-toolkit/state/user-context';
import { Item } from '../../react-query-toolkit/state/types';
import { findItemsByTypename } from 'utils/filterItemsbyTypeName';
import { getClientIdsFromProjects } from 'utils/get-client-ids-from-projects';
import { Box, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { ClientSearchBar } from 'pages/clients/components/client-search-bar/client-search-bar';
import { useTranslateAll } from '../../locales/translation-hooks';
import { clientHeadersTranslationMappings } from '../../locales/mappings';

export const Clients = () => {
  const [filtering, setFiltering] = useState({
    value: '',
    clientFilter: 'Name', // Default to searching by Name
    containsFilter: 'Contains', // Default to "Contains"
  });
  const [windowSize, setWindowSize] = useState(getWindowDimensions());
  const [isMobileWidth, setIsMobileWidth] = useState<boolean>(
    windowSize.width < MD,
  );
  const [isDesktopWidth, setIsDesktopWidth] = useState<boolean>(
    windowSize.width > LG,
  );
  const [columnsVisibility, setColumnsVisibility] =
    useState<DataVisibilityType>({
      contactName: isMobileWidth,
      phone: isDesktopWidth,
      email: isDesktopWidth,
      city: isDesktopWidth,
    });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<NewClientDataType>();
  const { translateCommon, translateMisc, translateProjects } = useTranslateAll(
    ['common', 'misc', 'projects'],
  );

  const subComponentHeaders: ClientSubComponentHeaders = {
    clientId: 'ID',
    clientName: 'Name',
    clientPhone: 'Phone',
    clientEmail: 'Email',
    clientLocation: 'City',
  };

  const navigate = useNavigate();

  function createColumnInfo() {
    return [
      {
        header: ' ',
        accessor: 'expand',
        size: 35,
        enableSorting: false,
        cell: ({ row }: Cell<ColumnDef<NewDataType>, NewDataType>) => (
          <ExpandingButtonContainer>
            <button
              onKeyDown={row.getToggleExpandedHandler()}
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: {
                  cursor: 'pointer',
                  top: '2px',
                  display: 'flex',
                  position: 'relative',
                },
              }}
            >
              {row.getIsExpanded() ? (
                <ArrowDownWithBorder />
              ) : (
                <ArrowRightWithBorder />
              )}
            </button>
          </ExpandingButtonContainer>
        ),
      },
      {
        header: `${translateCommon('headers.headerClient')}`,
        accessorKey: 'clientName',
        size: 100,
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '3px', alignItems: 'center' }}>
              <ActiveIcon
                active={isFirstDatePastSecondDate(
                  row.original.closeDate,
                  row.original.activeDate,
                )}
              />
            </div>
            <CopyToClipboardTruncated copyText={row.original.clientName}>
              {row.original.clientName}
            </CopyToClipboardTruncated>
          </div>
        ),
      },
      {
        header: `${translateCommon('headers.headerName')}`,
        accessorKey: 'contactName',
        size: 80,
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <CopyToClipboardTruncated
            copyText={row.original.firstName + ' ' + row.original.lastName}
          >
            {row.original.firstName + ' ' + row.original.lastName}
          </CopyToClipboardTruncated>
        ),
      },
      {
        header: `${translateCommon('headers.headerPhone')}`,
        accessorKey: 'phone',
        size: 60,
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <CopyToClipboardTruncated copyText={row.original.phone}>
            {row.original.phone}
          </CopyToClipboardTruncated>
        ),
      },
      {
        header: `${translateCommon('headers.headerEmail')}`,
        accessorKey: 'email',
        enableSorting: false,
        size: 90,
        cell: ({ row, column }: Cell<NewClientDataType, NewClientDataType>) => {
          return (
            <CopyToClipboardTruncated copyText={row.original.email}>
              {row.original.email}
            </CopyToClipboardTruncated>
          );
        },
      },
      {
        header: `${translateCommon('headers.headerCity')}`,
        accessorKey: 'city',
        enableSorting: false,
        size: 50,
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <div style={{ display: 'flex' }}>
            <CopyToClipboardTruncated copyText={row.original.city}>
              {row.original.city}
            </CopyToClipboardTruncated>
          </div>
        ),
      },
      {
        header: `${translateCommon('headers.headerMyProjects')}`,
        accessorKey: 'clientId',
        enableSorting: false,
        size: 1,
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <div>
            <Tooltip title={translateProjects('seeAllProjectsForClientTitle')}>
              <button
                onClick={() =>
                  navigate(
                    `/my-projects/client-name/${row.original.clientName}`,
                  )
                }
                {...{
                  style: { cursor: 'pointer' },
                }}
              >
                <AssignmentIcon sx={{ color: 'secondary.dark' }} />
              </button>
            </Tooltip>
          </div>
        ),
      },
      {
        header: ' ',
        accessorKey: 'action',
        enableSorting: false,
        maxSize: 40,
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <div style={{ display: 'flex' }}>
            <button
              onClick={() => {
                setSelectedData(row.original);
                setModalIsOpen(true);
              }}
            >
              <EditIcon sx={{ color: 'secondary.dark' }} />
            </button>
          </div>
        ),
      },
    ];
  }

  // Column Info State
  const [columnInfo, setColumnInfo] = useState(createColumnInfo());

  // Fetch client data
  const { handleGetClients, clientListIsLoading, clientsFetchingError } =
    useClientDataContext();
  const rawClientData = handleGetClients() || [];

  // Fetch user data
  const { handleGetUser } = useUserDataContext();
  const rawUserData: Item[] | null = handleGetUser();

  // Extract client IDs from user projects if user data exists
  const userClientsIds =
    rawUserData && Array.isArray(rawUserData)
      ? getClientIdsFromProjects(
          findItemsByTypename(rawUserData, 'ref:project'),
        )
      : [];

  // Filter clients to display based on user client IDs
  const clientsToBeDisplayed =
    rawUserData && rawClientData && userClientsIds !== null
      ? getItemsByIds(rawClientData, userClientsIds)
      : [];

  // Helper function to filter items by IDs
  function getItemsByIds(data: NewClientDataType[], ids: (string | null)[]) {
    return data.filter((item) => ids.includes(item.clientId));
  }

  // Determine final client data to be displayed
  let clientData: NewClientDataType[];
  if (rawClientData) {
    clientData = clientsToBeDisplayed;
  }

  // Apply filtering to `clientsToBeDisplayed`
  const filteredClients = clientsToBeDisplayed.filter((client) => {
    if (!filtering.value) return true; // Show all if no search input

    const searchTerm = filtering.value.toLowerCase();
    const { clientFilter, containsFilter } = filtering;

    // Get the field to search
    let fieldToSearch = '';
    if (clientFilter === 'Name')
      fieldToSearch = client.clientName.toLowerCase();
    if (clientFilter === 'Email') fieldToSearch = client.email.toLowerCase();
    if (clientFilter === 'Phone') fieldToSearch = client.phone;

    if (!fieldToSearch) return false;

    // Apply filtering logic
    if (containsFilter === 'Contains')
      return fieldToSearch.includes(searchTerm);
    if (containsFilter === 'Start with')
      return fieldToSearch.startsWith(searchTerm);
    if (containsFilter === 'Ends with')
      return fieldToSearch.endsWith(searchTerm);

    return true;
  });

  const handleResize = () => {
    setWindowSize(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsMobileWidth(windowSize.width <= 768);
    setIsDesktopWidth(windowSize.width > LG);

    setColumnsVisibility({
      contactName: !isMobileWidth,
      phone: isDesktopWidth,
      email: isDesktopWidth,
      city: isDesktopWidth,
    });
    return () => {};
  }, [windowSize.width]);

  const theme = useTheme();

  return (
    <>
      <PageWrapper>
        <Box justifyItems={'center'} sx={{ width: '100%', cursor: 'pointer' }}>
          <Box width={'100%'} maxWidth={theme.breakpoints.values.xxl}>
            <Container width="100%" margin="0">
              <PageTitle
                title={translateMisc('NavBar.navBarNavigationItems.myClients')}
              />
              {/*Todo : Verify if we will create a variant for the header typography according to Carls PR - instead of using a component*/}
            </Container>
            <ClientSearchBar onSearch={setFiltering} />
            <Container
              width="100%"
              maxWidth={`${theme.breakpoints.values.xxl}px`}
              //This width was defined by Ana Maria
              flexDirection="row"
              margin={`${theme.spacing(7)} 0 0 0`}
            >
              <DisplayTable
                filtering={filtering.value}
                setFiltering={(newValue) =>
                  setFiltering((prev) => ({
                    ...prev,
                    value:
                      typeof newValue === 'string'
                        ? newValue
                        : String(newValue), // Ensure it's a string
                  }))
                }
                data={filteredClients}
                columns={columnInfo}
                getRowCanExpand={() => true}
                columnsVisibility={columnsVisibility}
                showFooter
                loadingItem={
                  clientListIsLoading ? (
                    <Box
                      display={'flex'}
                      width={'100%'}
                      height={'30%'}
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      <CircularProgress size={50} sx={{ paddingY: 4 }} />
                    </Box>
                  ) : clientsFetchingError ? (
                    'There was an error'
                  ) : undefined
                }
                RenderSubComponent={RenderSubComponent}
                subComponentHeaders={subComponentHeaders}
                translationKeysMapping={clientHeadersTranslationMappings}
                headersTranslationFunction={translateCommon}
              />
            </Container>
          </Box>
        </Box>
      </PageWrapper>
      {selectedData ? (
        <ClientModalForm
          data={clientData!}
          selectedData={selectedData}
          isOpen={modalIsOpen}
          toggleModal={setModalIsOpen}
        />
      ) : null}
    </>
  );
};
