import {
  ConditionalTextContainerHeader,
  ConditionalTextContainerInfo,
  StyledFirstSubRow,
  StyledSubRow,
  SubConsultantContainer,
  SubRowContainer,
} from 'pages/clients/components/clients-table/styled';
import React from 'react';
import { Row } from '@tanstack/react-table';
import { RowTaskTableContainer } from 'pages/task-manager/components/styled';
import { ProfileImage } from 'pages/clients/components/clients-table/profile-image';
import { Calendar } from 'icons';
import { colors } from 'utils/colors';
import { epochToDate } from 'utils/epoch-to-date';
import { AssigneesContainer } from 'pages/projects/components/styled';
import { NewProjectStep } from '../../../react-query-toolkit/state/types';
import { findUserInfoById } from 'pages/projects/components/project-table';
import { useUserDataContext } from '../../../react-query-toolkit/state/user-context';
import { useTranslateAll } from 'locales/translation-hooks';
export default function ProjectTableSubComponent({
  row,
}: {
  row: Row<NewProjectStep>;
}) {
  const { handleGetAllUsers } = useUserDataContext();
  const rawUserData = handleGetAllUsers() || [];
  const { translateCommon } = useTranslateAll('common');
  return (
    <>
      <RowTaskTableContainer hideSubComponentFromSizeSm={false}>
        <AssigneesContainer>
          <SubRowContainer>
            <StyledSubRow>
              <ConditionalTextContainerHeader>
                {translateCommon('headers.headerProjectAssignees')}
              </ConditionalTextContainerHeader>
              <StyledFirstSubRow>
                <SubConsultantContainer>
                  {row.original.assignees.map((_image: string, idx: number) => (
                    <ProfileImage
                      textToDisplayWhenClicked={`${translateCommon('messages.EmailCopiedMessage')}`}
                      moveValue={`${idx}0px`}
                      profileName={
                        findUserInfoById(
                          row.original.assignees[idx],
                          rawUserData,
                        )!.firstname!
                      }
                      profileEmail={
                        findUserInfoById(
                          row.original.assignees[idx],
                          rawUserData,
                        )!.email!
                      }
                      imageIdx={`${idx + 1}`}
                      key={idx}
                    />
                  ))}
                </SubConsultantContainer>
              </StyledFirstSubRow>
            </StyledSubRow>
          </SubRowContainer>
        </AssigneesContainer>
        <SubRowContainer>
          <StyledSubRow>
            <ConditionalTextContainerHeader>
              {translateCommon('headers.headerDueDate')}
            </ConditionalTextContainerHeader>
            <StyledFirstSubRow>
              <ConditionalTextContainerInfo>
                <div
                  tabIndex={0}
                  role="button"
                  onClick={row.getToggleExpandedHandler()}
                  onKeyDown={row.getToggleExpandedHandler()}
                >
                  <Calendar
                    width={14}
                    height={14}
                    color={colors.lighterSecondaryBlue}
                  />{' '}
                  {epochToDate(row.original.duedate, 'MMMDD,YYYY')}
                </div>
              </ConditionalTextContainerInfo>
            </StyledFirstSubRow>
          </StyledSubRow>
        </SubRowContainer>
      </RowTaskTableContainer>
    </>
  );
}
