import ReactCalendar, { ViewCallback } from 'react-calendar';
import React, { SetStateAction, useEffect, useState } from 'react';
import {
  RightSideContainer,
  CalendarContainer,
  PriorityDot,
  Dot,
  DateCellWrapper,
  ModalContentContainer,
  Modal,
  ModalHeader,
  CloseButton,
  ModalText,
  SeeAllText,
  ModalContentContainerInner,
} from './styled';
import { CardHeader } from 'pages/dashboard/styled';
import { Heading, Hoverable } from 'components';
import { ArrowLeft, ArrowRight } from './icons';
import { isBefore, isSameDay, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTaskDataContext } from '../../../../react-query-toolkit/state/tasks-context';
import { NewTaskDataType } from 'pages/task-manager/api/task-type';
import { parseInt } from 'lodash';
import { formatTaskData } from 'pages/task-manager/api/format-task-data';
import { useUserDataContext } from '../../../../react-query-toolkit/state/user-context';
import { Item, Project } from '../../../../react-query-toolkit/state/types';
import { findItemsByTypename } from 'utils/filterItemsbyTypeName';
import { EventInput } from '@fullcalendar/core';
import { useTranslateAll } from '../../../../locales/translation-hooks';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

// const monthAndYearToString = (year: number, month: number) => {
//   const DEFAULT_DATE = 1;
//   const date = new Date(Date.UTC(year, month, DEFAULT_DATE));

//   return date.toLocaleString('default', {
//     month: 'long',
//     year: 'numeric',
//   });
// };

export const Calendar = () => {
  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showAll, setShowAll] = useState(false);
  const [selectedDayInMonth, setSelectedDayInMonth] = useState(date.getDate()); // Store selected day
  const { i18n } = useTranslation();

  const monthAndYearToDisplay = DateTime.fromJSDate(date)
    .setLocale(i18n.language)
    .toFormat('LLLL yyyy')
    .toLocaleUpperCase();

  const handleClickDay = (args: Date) => {
    handleClick(args);
  };

  const handleActiveDateChange: ViewCallback = ({ activeStartDate }) => {
    setDate(activeStartDate);
  };

  ///Fetching the task data
  const { handleGetTasks, setTaskIdSelected } = useTaskDataContext();
  const rawTaskData = handleGetTasks() || [];

  const { handleGetUser } = useUserDataContext();
  // Adjust rawUserData to be Item[] | null
  const rawUserData: Item[] | null = handleGetUser() || [];
  const userProjects: Project[] =
    rawUserData && Array.isArray(rawUserData)
      ? findItemsByTypename(rawUserData, 'ref:project')
      : [];
  const { handleGetClients } = useUserDataContext();

  const clientData = handleGetClients() || [];

  function transformTaskData(taskData: NewTaskDataType[]) {
    return taskData
      .filter((task) => !task.isCompleted) // Filter out completed tasks
      .map((task) => ({
        date: task.dueDate,
        title: task.taskName,
        priority: task.priority,
        eventId: task.taskId,
        eventType: 'task',
      }));
  }

  function transformProjectData(projectData: Project[]): EventInput[] {
    return projectData.map((project) => ({
      eventId: project.id,
      date: project.duedate,
      title: `${project.period} ${project.mandate} ${project.jurisdiction}`,
      eventType: 'project',
      priority: 'project',
      description: 'This is a project description',
    }));
  }

  const taskDates = transformTaskData(formatTaskData(rawTaskData, clientData));
  const dates = [...taskDates, ...transformProjectData(userProjects)];
  const { translateCalendar, translateCommon } = useTranslateAll([
    'calendar',
    'common',
  ]);
  const navigate = useNavigate();

  const [modal, setModal] = useState<{
    visible: boolean;
    content: {
      title: string;
      priority: string;
      eventId: string;
      eventType: string;
    }[];
    remainingCount: number;
  }>({
    visible: false,
    content: [],
    remainingCount: 0,
  });

  const today = new Date();

  const openModal = (
    events: {
      title: string;
      priority: string;
      eventId: string;
      eventType: string;
    }[],
  ) => {
    const displayedEvents = events.slice(0, 3);
    const remainingCount = events.length - 3;
    setModal({
      visible: true,
      content: displayedEvents,
      remainingCount: remainingCount > 0 ? remainingCount : 0,
    });
    setShowAll(false);
  };

  const closeModal = () => {
    setModal({ visible: false, content: [], remainingCount: 0 });
    setShowAll(false);
    setDate(
      new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDayInMonth,
      ),
    );
  };

  const handleClick = (date: Date) => {
    setSelectedDayInMonth(parseInt(dayjs(date).format('DD')));
    const dateEvents = dates.filter((d) => isSameDay(parseISO(d.date), date));
    if (dateEvents.length > 0) {
      const events = dateEvents.map((event) => ({
        title: event.title,
        priority: event.priority,
        eventId: event.eventId!,
        eventType: event.eventType,
      }));
      setSelectedDate(date);
      openModal(events);
    } else {
      setSelectedDate(date);
    }
  };

  const renderTileContent = ({ date }: { date: Date }) => {
    const dateEvents = dates.filter((d) => isSameDay(parseISO(d.date), date));
    const past = isBefore(date, today);
    const isTodayDue = isSameDay(date, today);
    if (dateEvents.length > 0) {
      return (
        <DateCellWrapper hasEvents onClick={() => handleClick(date)}>
          <Dot
            isTodayDue={isTodayDue}
            past={past}
            onClick={() => handleClick(date)}
          />
        </DateCellWrapper>
      );
    } else {
      return (
        <DateCellWrapper onClick={() => handleClick(date)}>
          {/* No dot for this day */}
        </DateCellWrapper>
      );
    }
  };

  const handleSeeAllClick = () => {
    setModal((prevModal) => ({
      ...prevModal,
      content: dates
        .filter((d) => isSameDay(parseISO(d.date), selectedDate))
        .map((event) => ({
          title: event.title,
          priority: event.priority,
          eventId: event.eventId!,
          eventType: event.eventType!,
        })),
    }));
    setShowAll(true);
  };

  const handleArrowLeftClick = () => {
    const newDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    setDate(newDate);
  };

  const handleArrowRightClick = () => {
    const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    setDate(newDate);
  };

  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [selectedDate]);

  function handleEventClick(eventType: string, eventId: string) {
    if (eventType === 'task') {
      setTaskIdSelected(eventId);
      navigate('/task-manager');
    }
    if (eventType === 'project') {
      navigate(`/my-projects/project-id/${eventId}`);
    }
  }

  return (
    <RightSideContainer className="calendar-temp">
      <CalendarContainer key={key}>
        <CardHeader>
          <div onClick={() => navigate('/calendar')}>
            <Heading level={3} color="secondaryBlue">
              {monthAndYearToDisplay}
            </Heading>
          </div>
          <div style={{ gap: '30px', display: 'flex' }}>
            <Hoverable onClick={() => handleArrowLeftClick()}>
              <ArrowLeft />
            </Hoverable>
            <Hoverable onClick={() => handleArrowRightClick()}>
              <ArrowRight />
            </Hoverable>
          </div>
        </CardHeader>
        <ReactCalendar
          tileContent={renderTileContent}
          formatShortWeekday={(_locale, date) =>
            [
              `${translateCalendar('shortenedWeekDays.sunday')}`,
              `${translateCalendar('shortenedWeekDays.monday')}`,
              `${translateCalendar('shortenedWeekDays.tuesday')}`,
              `${translateCalendar('shortenedWeekDays.wednesday')}`,
              `${translateCalendar('shortenedWeekDays.thursday')}`,
              `${translateCalendar('shortenedWeekDays.friday')}`,
              `${translateCalendar('shortenedWeekDays.saturday')}`,
            ][date.getDay()]
          }
          value={date}
          onActiveStartDateChange={handleActiveDateChange}
          onChange={(value: SetStateAction<Date>) => {
            setDate(value);
          }}
          onClickDay={handleClickDay}
        />
        {modal.visible && (
          <Modal>
            <ModalHeader>
              <h4>{dayjs(date).format('DD')}</h4>
              <CloseButton onClick={closeModal}>×</CloseButton>
            </ModalHeader>
            <ModalContentContainer>
              {modal.content.map((event, index) => (
                <ModalContentContainerInner
                  display={'flex'}
                  key={index}
                  onClick={() => {
                    handleEventClick(event.eventType, event.eventId);
                  }}
                >
                  <PriorityDot priority={event.priority} />
                  <ModalText>{event.title}</ModalText>
                  <br />
                </ModalContentContainerInner>
              ))}
              {!showAll && modal.remainingCount > 0 && (
                <>
                  <SeeAllText onClick={handleSeeAllClick}>
                    {modal.remainingCount} {translateCommon('more')}
                  </SeeAllText>
                </>
              )}
              {showAll ? (
                <SeeAllText onClick={() => navigate('/task-manager')}>
                  {translateCommon('seeAll')}
                </SeeAllText>
              ) : null}
              {modal.remainingCount === 0 && (
                <>
                  <SeeAllText onClick={() => navigate('/task-manager')}>
                    {translateCommon('seeAll')}
                  </SeeAllText>
                </>
              )}
            </ModalContentContainer>
          </Modal>
        )}
      </CalendarContainer>
    </RightSideContainer>
  );
};
