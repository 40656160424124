{
  "taskTableLoadingMessage": "",
  "searchTaskPlaceholder": "",
  "description": "",
  "buttons": {
    "add": ""
  },
  "messages": {
    "successfulTaskCreation": "",
    "successfulTaskDeletion": ""
  },
  "headers": {
    "taskName": "",
    "notes": "",
    "priority": "",
    "dueDate": "",
    "edit": "",
    "client": "",
    "clientId": "",
    "taskId": ""
  },
  "titles": {
    "active": "",
    "completed": "",
    "overDue": "",
    "today": "",
    "undoTaskDelete": "",
    "upcoming": "",
    "seeAllTasks": "",
    "addTask": "",
    "priority": ""
  },
  "displayTaskTable": {
    "displayTableFetchingErrorMessage": "",
    "displayTableNoItemsMessage": ""
  },
  "priorities": {
    "levels": {
      "high": "",
      "low": "",
      "medium": "",
      "urgent": ""
    }
  },
  "labels": {
    "dueDate": "",
    "taskName": ""
  },
  "form": {
    "dropdownClientNone": "",
    "dropdownClientDefault": ""
  }
}
