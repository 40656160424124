import React, { createContext, useContext, useState, ReactNode } from 'react';

interface FaqContextType {
  isOpen: boolean;
  activePage: string | null;
  openFaq: (pageName: string) => void;
  closeFaq: () => void;
}

const FaqContext = createContext<FaqContextType | undefined>(undefined);

export const useFaqContext = (): FaqContextType => {
  const context = useContext(FaqContext);
  if (!context) {
    throw new Error('useFaqContext must be used within a FaqProvider');
  }
  return context;
};

export const FaqProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activePage, setActivePage] = useState<string | null>(null);

  const openFaq = (pageName: string) => {
    setActivePage(pageName);
    setIsOpen(true);
  };

  const closeFaq = () => {
    setIsOpen(false);
    setActivePage(null);
  };

  return (
    <FaqContext.Provider value={{ isOpen, activePage, openFaq, closeFaq }}>
      {children}
    </FaqContext.Provider>
  );
};
