{
  "dataTable": "",
  "seeAll": "",
  "expandAll": "",
  "collapseAll": "",
  "more": "",
  "uploading": "",
  "buttons": {
    "addButton": "",
    "backButton": "",
    "cancelButton": "",
    "closeButton": "",
    "confirmButton": "",
    "confirmationButton": "",
    "submitButton": "",
    "deleteButton": "",
    "editButton": "",
    "saveButton": "",
    "nextButton": "",
    "okButton": "",
    "uploadButton": ""
  },
  "dueDate": {
    "dueDateTomorrow": "",
    "dueDateYesterday": "",
    "dueDateToday": "",
    "dueDateOverdue": "",
    "dueDateIn": ""
  },
  "errors": {
    "errorButtons": {
      "errorCloseButton": ""
    },
    "errorTitles": {
      "errorTitle": "",
      "errorUpdatingTitle": ""
    },
    "errorMessages": {
      "errorDefaultMessage": "",
      "errorUpdatingMessage": ""
    }
  },
  "greetings": {
    "welcomeGreeting": "",
    "navBarGreeting": ""
  },
  "headers": {
    "headerCalendar": "",
    "headerCity": "",
    "headerClient": "",
    "headerUserProfile": "",
    "headerConsultant": "",
    "headerDashboard": "",
    "headerDueDate": "",
    "headerEdit": "",
    "headerEmail": "",
    "headerHelpButton": "",
    "headerLocation": "",
    "headerLogin": "",
    "headerName": "",
    "headerNotifications": "",
    "headerPhone": "",
    "headerPriority": "",
    "headerProjectAssignees": "",
    "headerProjectComments": "",
    "headerProjectDueDate": "",
    "headerProjectStatus": "",
    "headerProjectSteps": "",
    "headerProjects": "",
    "headerMyProjects": "",
    "headerSingleProject": "",
    "headerSettings": "",
    "headerTask": "",
    "headerTaskManager": ""
  },
  "labels": {
    "applicableStandard": "",
    "intendedUse": "",
    "successCriteria": ""
  },
  "messages": {
    "message": "",
    "noDataMessage": "",
    "noResultsMessage": "",
    "confirmationMessage": "",
    "textCopiedMessage": "",
    "EmailCopiedMessage": "",
    "thankYouDisplayMessage": ""
  },
  "questions": {
    "typeOfOrganizationQuestion": "",
    "sizeOfOrganizationQuestion": ""
  },
  "titles": {
    "congratulations": "",
    "thankYouDisplayTitle": ""
  },
  "unknowns": {
    "unknownClient": "",
    "unknownManager": "",
    "unknownDueDate": "",
    "unknownUser": ""
  }
}
