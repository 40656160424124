import React from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  useTheme,
} from '@mui/material';
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';
import { useTranslateAll } from '../../../locales/translation-hooks';

type SearchBarProps = {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  clearSearch: () => void;
};

export const SearchBar = ({
  searchQuery,
  setSearchQuery,
  clearSearch,
}: SearchBarProps) => {
  const { translateProjects } = useTranslateAll('projects');
  const theme = useTheme();
  return (
    <Box>
      <TextField
        variant="outlined"
        placeholder={translateProjects('searchProjectsPlaceholder')}
        fullWidth
        size="small"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)} // Update Search Query
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color={'primary'} />
            </InputAdornment>
          ),
          endAdornment: searchQuery && ( // Show 'X' button if search query exists
            <InputAdornment position="end">
              <IconButton
                onClick={clearSearch}
                size="small"
                sx={{ padding: 0 }}
              >
                <ClearIcon color={'primary'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          '.MuiInputBase-input': { fontVariant: 'body3' },
          mb: 2,
          '& .MuiOutlinedInput-root': {
            paddingY: `${theme.spacing(3)} !important`,
            paddingX: `${theme.spacing(4)} !important`,
          },
          '& .MuiInputBase-input': {
            ...theme.typography.body3, // Apply body3 variant directly
            padding: '0 8px', // Align text properly
            typography: 'secondary.main',
          },
        }}
      />
    </Box>
  );
};
