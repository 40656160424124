import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { RequestInformationType } from 'pages/request-account';
import { PageWrapper } from 'components/molecules/page-wrapper/page-wrapper';
import {
  Box,
  Button,
  Container,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useForm, Controller } from 'react-hook-form';
import { useTranslateAll } from 'locales/translation-hooks';

export interface createPasswordProps {
  setRequestedInformation: React.Dispatch<
    React.SetStateAction<RequestInformationType>
  >;
  requestedInformation: RequestInformationType;
  nextStep: () => void;
}

export const ClientAccountInfo = (props: createPasswordProps) => {
  const { requestedInformation, setRequestedInformation, nextStep } = props;
  const { translateClients, translateCommon } = useTranslateAll([
    'clients',
    'common',
  ]);

  // Schema for validation
  const clientAccountInfoSchema = yup.object().shape({
    clientName: yup
      .string()
      .required(translateClients('clientAccount.errors.nameRequired'))
      .matches(
        /(.|\s)*\S(.|\s)*/,
        translateClients('clientAccount.errors.nameNotEmpty'),
      ),
    contactName: yup
      .string()
      .required(translateClients('clientAccount.errors.contactRequired'))
      .matches(
        /(.|\s)*\S(.|\s)*/,
        translateClients('clientAccount.errors.contactNotEmpty'),
      ),
    phone: yup
      .string()
      .required(translateClients('clientAccount.errors.phoneRequired'))
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        translateClients('clientAccount.errors.phoneInvalid'),
      ),
    email: yup
      .string()
      .required(translateClients('clientAccount.errors.emailRequired'))
      .email(translateClients('clientAccount.errors.emailInvalid')),
    address: yup.string(), // Add missing fields here
    country: yup.string(),
    state: yup.string(),
    region: yup.string(),
    city: yup.string(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      clientName: '',
      contactName: '',
      phone: '',
      email: '',
      address: '',
      country: '',
      state: '',
      region: '',
      city: '',
    },
    resolver: yupResolver(clientAccountInfoSchema),
  });

  // Synchronize form state with requestedInformation
  useEffect(() => {
    reset({
      clientName: requestedInformation.clientName || '',
      contactName: requestedInformation.contactName || '',
      phone: requestedInformation.phone || '',
      email: requestedInformation.email || '',
      address: requestedInformation.address || '',
      country: requestedInformation.country || '',
      state: requestedInformation.state || '',
      region: requestedInformation.region || '',
      city: requestedInformation.city || '',
    });
  }, [requestedInformation, reset]);

  const handleNext = (data: any) => {
    setRequestedInformation((prev) => {
      const updatedInfo = { ...prev, ...data };
      return updatedInfo; // State updated correctly
    });

    // Ensure the next step is triggered after the state update
    setTimeout(() => nextStep(), 0);
  };

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
          overflow: 'auto',
        }}
      >
        <Container maxWidth="md">
          <Box maxWidth="688px" mx="auto">
            <Typography
              color="primary.main"
              textAlign="center"
              gutterBottom
              sx={(theme) => ({
                fontSize: {
                  xs: theme.typography.h5.fontSize,
                  md: theme.typography.h4.fontSize,
                },
              })}
            >
              {translateClients('clientAccount.title')}
            </Typography>
            <form onSubmit={handleSubmit(handleNext)}>
              <Grid container spacing={6}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Controller
                    name="clientName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={translateClients('clientAccount.labels.client')}
                        placeholder={translateClients(
                          'clientAccount.placeHolders.client',
                        )}
                        variant="outlined"
                        fullWidth
                        error={!!errors.clientName}
                        helperText={errors.clientName?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Controller
                    name="contactName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={translateClients('clientAccount.labels.contact')}
                        placeholder={translateClients(
                          'clientAccount.placeHolders.contact',
                        )}
                        variant="outlined"
                        fullWidth
                        error={!!errors.contactName}
                        helperText={errors.contactName?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={translateClients('clientAccount.labels.phone')}
                        placeholder={translateClients(
                          'clientAccount.placeHolders.phone',
                        )}
                        variant="outlined"
                        fullWidth
                        error={!!errors.phone}
                        helperText={errors.phone?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={translateClients('clientAccount.labels.email')}
                        placeholder={translateClients(
                          'clientAccount.placeHolders.email',
                        )}
                        variant="outlined"
                        fullWidth
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label={translateClients('clientAccount.labels.country')}
                        placeholder={translateClients(
                          'clientAccount.placeHolders.country',
                        )}
                        variant="outlined"
                        fullWidth
                        error={!!errors.country}
                        helperText={errors.country?.message}
                      >
                        <MenuItem value="Canada">
                          {'CANADA placeholder'}
                        </MenuItem>
                        <MenuItem value="USA">{'USA placeholder'}</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label={translateClients('clientAccount.labels.state')}
                        variant="outlined"
                        fullWidth
                        error={!!errors.state}
                        helperText={errors.state?.message}
                      >
                        <MenuItem value="British Columbia">
                          {'BC placeholder'}
                        </MenuItem>
                        <MenuItem value="Ontario">
                          {'Ontario placeholder'}
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid size={12}>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={translateClients('clientAccount.labels.address')}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid size={12}>
                  <Box textAlign="start">
                    <Button type="submit" variant="contained" color="primary">
                      {translateCommon('buttons.nextButton')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </Box>
    </PageWrapper>
  );
};
