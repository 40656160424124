import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { PropsWithChildren } from 'react';

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000000;
  color: black;
  height: 98vh;
  width: 98vw;
`;

const Overlay = styled.div<{ zIndexValue?: number }>`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(81, 86, 90, 0.6);
  z-index: ${({ zIndexValue = 9999 }) => zIndexValue};
`;

export const PopupModalOverlay = ({
  modalIsOpen,
  children,
  zIndexValue,
}: {
  modalIsOpen: boolean;
  children: PropsWithChildren<JSX.Element>;
  zIndexValue?: number;
}) => {
  if (!modalIsOpen) return null;
  return (
    <>
      {createPortal(
        <Overlay zIndexValue={zIndexValue}>
          <ModalContainer>{children}</ModalContainer>
        </Overlay>,
        document.getElementById('top-portal')!,
        (document.body.style.overflow = 'hidden'),
      )}
    </>
  );
};
