export interface ClientHeadersTranslationKeys {
  clientName: string;
  contactName: string;
  phone: string;
  email: string;
  city: string;
  clientId: string;
}

export const clientHeadersTranslationMappings: ClientHeadersTranslationKeys = {
  clientName: 'headers.headerClient',
  contactName: 'headers.headerName',
  phone: 'headers.headerPhone',
  email: 'headers.headerEmail',
  city: 'headers.headerCity',
  clientId: 'headers.headerProjects',
};

// const subComponentHeaders: ClientSubComponentHeaders = {
//   clientId: 'ID',
//   clientName: 'Name',
//   clientPhone: 'Phone',
//   clientEmail: 'Email',
//   clientLocation: 'City',
// };

export interface ProjectHeadersTranslationKeys {
  expand: string;
  projectStep: string;
  projectAssignees: string;
  dueDate: string;
  projectComments: string;
}
export const projectHeadersTranslationMappings: ProjectHeadersTranslationKeys =
  {
    expand: 'headers.headerProjectStatus',
    projectStep: 'headers.headerProjectSteps',
    projectAssignees: 'headers.headerProjectAssignees',
    dueDate: 'headers.headerProjectDueDate',
    projectComments: 'headers.headerProjectComments',
  };

export interface TasksHeadersTranslationKeys {
  taskName: string;
  notes: string;
  clientId: string;
  priority: string;
  dueDate: string;
  edit: string;
  clientName: string;
  taskId: string;
}

export const tasksHeadersTranslationMappings = {
  taskName: 'headers.taskName',
  notes: 'headers.notes',
  clientId: 'headers.client',
  priority: 'headers.priority',
  dueDate: 'headers.dueDate',
  edit: 'headers.edit',
  clientName: 'headers.client',
  taskId: 'headers.taskId',
};

export interface ProjectStepsTranslationKeys {
  'set-up': string;
  'data-collection': string;
  research: string;
  calculation: string;
  'calculation-review': string;
  'draft-report': string;
  'report-review': string;
  'client-review': string;
  'finalize-report': string;
  'close-project': string;
}

export const projectStepsTranslationMappings: ProjectStepsTranslationKeys = {
  'set-up': 'steps.setup',
  'data-collection': 'steps.dataCollection',
  research: 'steps.research',
  calculation: 'steps.calculation',
  'calculation-review': 'steps.calculationReview',
  'draft-report': 'steps.draftReport',
  'report-review': 'steps.reportReview',
  'client-review': 'steps.clientReview',
  'finalize-report': 'steps.finalizeReport',
  'close-project': 'steps.closeProject',
};

export interface TasksPriorityTranslationKeys {
  High: string;
  Low: string;
  Medium: string;
  Urgent: string;
}

export const tasksPriorityTranslationMappings = {
  High: 'priorities.levels.high',
  Low: 'priorities.levels.low',
  Medium: 'priorities.levels.medium',
  Urgent: 'priorities.levels.urgent',
};

export const getTranslationKeyMapping = <T extends keyof K, K>(
  key: T,
  mappedObject: K,
): string => {
  const translationKey = key && mappedObject[key];
  return translationKey && translationKey.trim() !== '' ? translationKey : '';
};
