import { ClientContextRawData } from '../../../react-query-toolkit/services/types';

export function formatClientData(data: ClientContextRawData[]): {
  lastName: string;
  country: string;
  clientId: string;
  address: string;
  clientName: string;
  city: string;
  postalCode: string;
  municipality: string;
  closeDate: string;
  organizationType: string;
  firstName: string;
  consultants: string[];
  phone: string;
  activeDate: string;
  logo: string;
  action: string;
  state: string;
  email: string;
  typename: string | undefined;
}[] {
  return data.map((client: ClientContextRawData) => ({
    clientId: client.id,
    clientName: client.clientname,
    firstName: client.firstname,
    lastName: client.lastname,
    phone: client.phone,
    email: client.email,
    country: client.location,
    city: client.location,
    typename: client.typename,
    municipality: client.location,
    state: client.location,
    activeDate: client.activedate,
    closeDate: client.closedate,
    address: client.address,
    postalCode: client.postalcode,
    organizationType: client.organizationtype,
    consultants: client.accountconsultants ? client.accountconsultants : [],
    //todo Account consultants come from the mandate not anymore from client. we need to call it from it.
    logo: client.logo,
    action: '',
  }));
}
