import { User } from '../react-query-toolkit/state/types';

export const findUserById = (id: string, users: User[]): User | null => {
  if (!Array.isArray(users)) {
    console.warn("Expected an array for 'users', but got:", users);
    return null;
  }
  if (!id) {
    console.warn("Expected a valid 'id', but got:", id);
    return null;
  }

  const foundUser = users.find((user) => user.id === id);

  if (!foundUser) {
    console.warn(`No user found with id: ${id}`);
  }

  return foundUser || null;
};
