import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Heading, TextField } from 'components';
import { Box, Link } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import useAuth from 'configs/AuthContext';
import { Dispatch, SetStateAction, useState } from 'react';
import { StyledSpan } from 'pages/forgot-password/components/styled';
import { useTranslateAll } from '../../../locales/translation-hooks';

export interface ResetPasswordRequestProps {
  nextStep: () => void;
  setUserEmail: Dispatch<SetStateAction<string>>;
  userEmail: string;
}

export const ResetPasswordRequest = (props: ResetPasswordRequestProps) => {
  const { translatePassword } = useTranslateAll('password');
  const navigate = useNavigate();
  const { forgotPassword } = useAuth();
  const [error, setError] = useState<string>('');

  const loginSchema = yup.object().shape({
    email: yup
      .string()
      .email(translatePassword('resetPassword.errors.invalidEmail'))
      .required(translatePassword('resetPassword.errors.requiredEmail')),
  });

  const {
    control,
    trigger,
    formState: { isValid, errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(loginSchema),
  });

  const handleNext = async () => {
    const isStepValid = await trigger();
    try {
      forgotPassword(
        props.userEmail,
        () => setError(''),
        (err: Error) => {
          setError(err.message);
        },
      );
      if (isStepValid && props.userEmail !== '') {
        props.nextStep();
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(String(error));
      }
    }
  };

  // }

  const checkFieldValidity = async (fieldName: 'email') => {
    await trigger(fieldName);
  };

  return (
    <>
      <div style={{ marginBottom: '0' }}>
        <Heading level={1} color="secondaryBlue">
          {translatePassword('resetPassword.titles.requestEmailTitle')}
        </Heading>
      </div>
      <StyledSpan>
        {translatePassword('resetPassword.messages.requestEmail')}
      </StyledSpan>
      <Controller
        name="email"
        control={control}
        defaultValue=""
        render={({ field }) => {
          return (
            <TextField
              type={'email'}
              {...field}
              onBlur={() => {
                checkFieldValidity('email');
              }}
              onChange={(e) => {
                props.setUserEmail(e.target.value);
                field.onChange(e);
                clearErrors();
              }}
              label={translatePassword('resetPassword.emailLabel')}
              placeholder={translatePassword('resetPassword.emailPlaceHolder')}
              status={errors.email ? 'error' : isValid ? 'success' : undefined}
              errorMessage={errors.email && errors.email.message}
            />
          );
        }}
      />
      <div style={{ width: '100%', marginTop: '24px', marginBottom: '60px' }}>
        <Button onClick={() => handleNext()}>
          {translatePassword('resetPassword.button')}
        </Button>
      </div>
      {error != '' ? <p>{error} </p> : ''}
      <Box display={'flex'} alignContent={'flex-start'} width={'100%'}>
        <Link onClick={() => navigate('/login')} variant={'link'}>
          {translatePassword('resetPassword.messages.bottom')}
        </Link>
      </Box>
    </>
  );
};
