{
  "description": {
    "default": "",
    "receptionDate": "",
    "changedProjectStepDueDate": "",
    "changedProjectDueDate": "",
    "createdNewTask": "",
    "changedProjectStepStatus": "",
    "noDetailsAvailable": ""
  },
  "titles": {
    "systemNotices": "",
    "openProject": "",
    "reportReview": ""
  }
}
