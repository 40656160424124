import React, { useEffect, useState } from 'react';
import { Document } from '@contentful/rich-text-types';
import { createClient } from 'contentful';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AccordionItem } from 'pages/faq/components/accordion-item';
import { useFaqContext } from '../../../react-query-toolkit/state/faq-context';
import { useTheme } from '@mui/material/styles';

const client = createClient({
  space: 'y3n4tj2ong0l',
  accessToken: 'whLrYVGlrSGjcMaHVmBpZKP7dIpLCRwt0jMiVHyCuqs',
});

type FAQItem = {
  question: string;
  answer: Document;
};

export const FaqModal: React.FC = () => {
  const { isOpen, closeFaq, activePage } = useFaqContext();
  const [data, setData] = useState<FAQItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchFaqs = async () => {
      if (!activePage) return;

      setLoading(true);
      try {
        const response = await client.getEntries({
          content_type: 'pages',
          include: 2,
        });

        const pageData = response.items.find(
          (page: any) => page.fields.name === activePage,
        );

        if (pageData && Array.isArray(pageData.fields.allTextContent)) {
          const faqs: FAQItem[] = (pageData.fields.allTextContent as any[]) // Avoid `any`
            .filter((faq) => faq?.fields?.question && faq?.fields?.answer)
            .map((faq) => ({
              question: faq.fields.question,
              answer: faq.fields.answer as Document, // Ensure correct type
            }));

          setData(faqs);
        } else {
          setData([]);
        }
      } catch (error) {
        setError('Error fetching FAQs.');
        console.error('FAQ fetch error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFaqs();
  }, [activePage]);

  const theme = useTheme();
  return (
    <Dialog
      open={isOpen}
      onClose={closeFaq}
      fullWidth
      maxWidth="sm"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '12px', // Rounded corners
          padding: 6,
          boxShadow: `0px 4px 10px ${theme.palette.notificationBorderShadow.main}`,
        },
      }}
    >
      <DialogTitle
        variant={'h5'}
        sx={{
          color: 'primary.main',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingX: 0,
          paddingY: 2,
          borderBottom: `2px solid ${theme.palette.notificationBorderShadow.main} !important`,
        }}
      >
        Help
        <IconButton
          aria-label="close"
          onClick={closeFaq}
          sx={{
            color: 'textColor.main',
            padding: 0,
          }}
        >
          <CloseIcon
            fontSize="large"
            sx={{
              padding: 0,
            }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 4, // Space between accordions
        }}
      >
        {loading ? (
          <Box display="flex" justifyContent="center" p={3}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : data.length > 0 ? (
          data.map((faq, index) => (
            <AccordionItem
              key={index}
              question={faq.question}
              answer={faq.answer}
            />
          ))
        ) : (
          <Typography>No FAQs available for this page.</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};
