{
  "title": "",
  "headings": {
    "profile": "",
    "password": "",
    "formats": ""
  },
  "subHeadings": {
    "profile": {
      "main": ""
    },
    "password": {
      "main": ""
    },
    "formats": {
      "main": ""
    }
  },
  "labels": {
    "profile": {
      "photo": "",
      "firstName": "",
      "lastName": "",
      "phone": "",
      "jobTitle": "",
      "loginEmail": "",
      "noticeEmail": "",
      "timeZone": "",
      "location": ""
    },
    "password": {
      "current": "",
      "new": "",
      "confirm": ""
    },
    "formats": {
      "dateFormat": "",
      "timeFormat": "",
      "numberFormat": ""
    }
  },
  "buttons": {
    "save": "",
    "cancel": "",
    "delete": "",
    "upload": ""
  },
  "options": {
    "formats": {
      "dateFormat": {},
      "timeFormat": {
        "12": "",
        "24": ""
      },
      "numberFormat": {
        "comma": "",
        "period": ""
      }
    }
  }
}
