{
    "createPassword": {
        "label": "Password*",
        "placeHolder": "Password...",
        "title": "Create a Password",
        "passwordStrengthSubtitle": "Password Strength:",
        "errors":{
            "atLeast1Number": "At least 1 number.",
            "atLeast1Uppercase": "At least 1 uppercase.",
            "min8Char": "At least 8 char.",
            "isRequired": "Password is required.",
            "atLeast1SpecialChar": "At least 1 special character."
        },
        "strengthEvaluators":{
            "medium": "Medium",
            "strong": "Strong",
            "weak": "Weak"
        }
    },
    "resetPassword":{
        "button": "RESET PASSWORD",
        "emailLabel": "Email",
        "emailPlaceHolder": "Email",
        "titles":{
            "title": "Reset Password",
            "requestEmailTitle": "Forgot Password?"
        },
        "messages":{
            "bottom": "< Back to Log In",
            "requestEmail": "No Worries, please input your account email."
        },
        "errors":{
            "invalidEmail": "Invalid email address",
            "requiredEmail": "Email is required"
        }
    }
}
