import React, {Dispatch, Fragment, SetStateAction, useState} from 'react';
import {
  ArrowContainer,
  ButtonContainer,
  FooterContainer,
  HeaderContainer,
  ItemsPerPageContainer,
  PageNavigationContainer,
  StyledDropDownMenu1,
  StyledOptionMenu,
  StyledTable,
  StyledTbody,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledTr,
  SubComponentTr,
  TableContainer,
} from './styled';
import {IconButton, Typography} from "@mui/material";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {ColumnDef, ExpandedState, flexRender, Row, SortingState, StringHeaderIdentifier,} from '@tanstack/react-table';
import {ClientSubComponentHeaders, DataVisibilityType, NewDataType,} from 'pages/clients/api/get-clients';
import {tableLogic} from './table-logic';
import {TableSortingArrows} from 'icons/table-sorting-arrows';
import {TranslationFunction, useTranslateAll,} from 'locales/translation-hooks';
import {
  ClientHeadersTranslationKeys,
  getTranslationKeyMapping,
  ProjectHeadersTranslationKeys,
  TasksHeadersTranslationKeys,
} from 'locales/mappings';

type TableProps<TData> = {
  filtering?: string;
  setFiltering?: Dispatch<SetStateAction<string>>;
  data: TData[];
  // "@ts-expect-error"
  columns: ColumnDef<any>[];
  getRowCanExpand: (row: Row<TData>) => boolean;
  columnsVisibility?: DataVisibilityType;
  showFooter: boolean;
  RenderSubComponent?: React.ComponentType<{
    // "@ts-expect-error"
    row: Row<any>;
    subComponentHeaders: ClientSubComponentHeaders;
  }>;
  subComponentHeaders?: ClientSubComponentHeaders;
  hideHeader?: boolean;
  loadingItem?: string | JSX.Element;
  hideSubComponentFromSizeMd?: boolean;
  hideSubComponentFromSizeLg?: boolean;
  hideSubComponentFromSizeSm?: boolean;
  setGlobalFilter?: Dispatch<SetStateAction<string>>;
  globalFilter?: string;
  cellMaxWidth?: string;
  translationKeysMapping?:
    | ClientHeadersTranslationKeys
    | ProjectHeadersTranslationKeys
    | TasksHeadersTranslationKeys;

  headersTranslationFunction?: TranslationFunction;
};

export function DisplayTable({
  filtering,
  setFiltering,
  data,
  columns,
  getRowCanExpand,
  columnsVisibility,
  showFooter,
  RenderSubComponent,
  subComponentHeaders,
  loadingItem,
  hideHeader,
  hideSubComponentFromSizeLg,
  hideSubComponentFromSizeMd,
  hideSubComponentFromSizeSm,
  setGlobalFilter,
  globalFilter,
  cellMaxWidth,
  translationKeysMapping,
  headersTranslationFunction,
}: TableProps<NewDataType>) {
  const [pageAmount, setPageAmount] = useState<number>(10);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  // Here are the table options
  const table = tableLogic(
    data,
    columns,
    pageAmount,
    getRowCanExpand,
    setSorting,
    sorting,
    setExpanded,
    expanded,
    filtering,
    setFiltering,
    columnsVisibility,
    setGlobalFilter,
    globalFilter,
  );

  const ITEM_VIEWS = [10, 15, 20, 25, 30, 40, 50];
  const { translateTasks, translateCommon } = useTranslateAll([
    'tasks',
    'common',
  ]);

  const displayTableNoItemsMessage = translateTasks(
    'displayTaskTable.displayTableNoItemsMessage',
  );

  const translateHeaders = (accessorKey: StringHeaderIdentifier) => {
    if (headersTranslationFunction) {
      return headersTranslationFunction(
        getTranslationKeyMapping(accessorKey, translationKeysMapping || {}),
      );
    }
    return translateCommon(
      getTranslationKeyMapping(accessorKey, translationKeysMapping || {}),
    );
  };

  return (
    <>
      {data.length > 0 ? (
        <TableContainer>
          <StyledTable>
            <StyledThead hide={hideHeader}>
              {table.getHeaderGroups().map((headerGroup, index) => (
                <StyledTr key={headerGroup.id} hide={hideHeader && index === 0}>
                  {headerGroup.headers.map((header, idx) => {
                    return (
                      <StyledTh
                        key={`${header.id}-${idx}`}
                        colSpan={header.colSpan}
                        style={{
                          width:
                            header.getSize() === Number.MAX_SAFE_INTEGER
                              ? 'auto'
                              : header.getSize(),
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <HeaderContainer
                            key={header.id}
                            onKeyDown={header.column.getToggleSortingHandler()}
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            <Typography
                              variant={'subtitle2'}
                              color={'primary.dark'}
                            >
                              {flexRender(
                                translateHeaders(
                                  header.column.columnDef.accessorKey,
                                ),
                                header.getContext(),
                              )}
                            </Typography>
                            {header.column.getCanSort()
                              ? {
                                  asc: <ArrowContainer>↑</ArrowContainer>,
                                  desc: <ArrowContainer> ↓</ArrowContainer>,
                                }[header.column.getIsSorted() as string] ?? (
                                  <ArrowContainer>
                                    <TableSortingArrows />
                                  </ArrowContainer>
                                )
                              : ''}
                          </HeaderContainer>
                        )}
                      </StyledTh>
                    );
                  })}
                </StyledTr>
              ))}
            </StyledThead>
            <StyledTbody>
              {table.getRowModel().rows.map((row, idx) => {
                return (
                  <Fragment key={row.id}>
                    <StyledTr key={`${row.id}-${idx}`}>
                      {/* first row is a normal row */}
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <StyledTd
                            key={cell.id}
                            rowIsSelected={row.getIsSelected()}
                            style={{
                              maxWidth: cellMaxWidth
                                ? cellMaxWidth + 'px'
                                : '100px',
                              // this maxWidth is needed to be placed to work overflow ellipsis css
                              // It will be ignored and instead the auto width of the header will be used.
                              // This is a known bug in TanStack v8
                              whiteSpace:
                                'nowrap' /* Prevent text from wrapping */,
                              overflow: 'hidden' /* Hide the overflow text */,
                              textOverflow:
                                'ellipsis' /* Show ellipsis (...) for overflow text */,
                            }}
                          >
                            <Typography
                              variant={'body3'}
                              color={'text.primary'}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </Typography>
                          </StyledTd>
                        );
                      })}
                    </StyledTr>
                    {row.getIsExpanded() && RenderSubComponent ? (
                      // &&
                      // subComponentHeaders
                      <SubComponentTr
                        hideSubComponentFromSizeLg={hideSubComponentFromSizeLg}
                        hideSubComponentFromSizeMd={hideSubComponentFromSizeMd}
                        hideSubComponentFromSizeSm={hideSubComponentFromSizeSm}
                        showSubComponent={
                          !!(row.getIsExpanded() && RenderSubComponent)
                        }
                      >
                        {/* 2nd row is a custom 1 cell row */}
                        <td colSpan={row.getVisibleCells().length}>
                          <RenderSubComponent
                            row={row}
                            subComponentHeaders={subComponentHeaders!}
                          />
                        </td>
                      </SubComponentTr>
                    ) : null}
                  </Fragment>
                );
              })}
            </StyledTbody>
          </StyledTable>
          <FooterContainer showFooter={showFooter}>
            <ItemsPerPageContainer>
              <StyledDropDownMenu1
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                  setPageAmount(Number(e.target.value));
                }}
              >
                {ITEM_VIEWS.map((pageSize, idx) => (
                  <StyledOptionMenu key={idx} value={pageSize}>
                    {pageSize}
                  </StyledOptionMenu>
                ))}
              </StyledDropDownMenu1>
            </ItemsPerPageContainer>
            <PageNavigationContainer>
              <ButtonContainer>
                <IconButton
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                  sx={{
                    color: table.getCanPreviousPage() ? "primary.main" : "grey.500",
                  }}
                >
                  <ArrowLeftIcon fontSize="small" />
                </IconButton>
              </ButtonContainer>

              <ButtonContainer>
                <IconButton
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                  sx={{
                    color: table.getCanNextPage() ? "primary.main" : "grey.500",
                  }}
                >
                  <ArrowRightIcon fontSize="small" />
                </IconButton>
              </ButtonContainer>
            </PageNavigationContainer>
          </FooterContainer>
        </TableContainer>
      ) : (
        <>{loadingItem ? loadingItem : displayTableNoItemsMessage}</>
      )}
    </>
  );
}
