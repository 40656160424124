import { PageWrapper } from 'components';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ResetPasswordRequest } from 'pages/forgot-password/components/reset-password-request';
import { InputEmailCode } from 'pages/forgot-password/components/input-email-code';
import { ConfirmationDisplay } from 'pages/forgot-password/components/confirmation-display';

export const ForgotPassword = () => {
  const [formStep, setFormStep] = useState(1);
  const [userEmail, setUserEmail] = useState('');

  const prevStep = () => {
    if (formStep > 1) {
      setFormStep(formStep - 1);
    }
  };

  // proceed to the next step
  const nextStep = () => {
    if (formStep < 3) {
      setFormStep(formStep + 1);
    }
  };

  function renderSwitch(step: number) {
    switch (step) {
      case 1:
        return (
          <ResetPasswordRequest
            userEmail={userEmail}
            setUserEmail={setUserEmail}
            nextStep={nextStep}
          />
        );
      case 2:
        return (
          <InputEmailCode
            userEmail={userEmail}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 3:
        return <ConfirmationDisplay />;
      default:
        break;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []); // Empty dependency array ensures this runs only once on mount

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
          overflow: 'auto',
          overflowX: 'none',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
        >
          <Box
            height="100%"
            maxHeight="530px"
            my={4}
            maxWidth="402px"
            width="100%"
            alignItems="center"
            justifyContent="center"
            bgcolor={isSmallScreen ? 'transparent' : 'white'}
            boxShadow={isSmallScreen ? 0 : 3}
            borderRadius={2}
            display="flex"
            py={9}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                padding: '0 40px',
              }}
            >
              {renderSwitch(formStep)}
            </div>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
};
