import {
  ConditionalTextContainerHeader,
  ConditionalTextContainerInfo,
  StyledFirstSubRow,
  StyledSubRow,
  SubRowContainer,
} from 'pages/clients/components/clients-table/styled';
import React from 'react';
import { Row } from '@tanstack/react-table';
import { Calendar } from 'icons';
import { colors } from 'utils/colors';
import { epochToDate } from 'utils/epoch-to-date';
import { flagPriorityHandler } from 'pages/task-manager/components/utils';
import {
  ClientIconContainer,
  ClientInfoContainer,
  RowTaskTableContainer,
} from 'pages/task-manager/components/styled';
import { useTranslation } from 'react-i18next';
import { NewTaskDataType } from 'pages/task-manager/api/task-type';
import { ClientIcon } from 'icons/client-icon';
import { findClientNameById } from 'utils/find-client-name-by-id';
import { useUserDataContext } from '../../../react-query-toolkit/state/user-context';
import { DateTime } from 'luxon';
export default function TaskTableSubComponent({
  row,
}: {
  row: Row<NewTaskDataType>;
}) {
  const { handleGetClients } = useUserDataContext();

  const clientData = handleGetClients() || [];
  const { t: translate } = useTranslation();
  return (
    <>
      <RowTaskTableContainer>
        <SubRowContainer>
          <StyledSubRow>
            <ConditionalTextContainerHeader>
              {translate?.('headerDueDate')}
            </ConditionalTextContainerHeader>
            <StyledFirstSubRow>
              <ConditionalTextContainerInfo>
                <Calendar
                  width={14}
                  height={14}
                  color={colors.lighterSecondaryBlue}
                />{' '}
                {DateTime.fromISO(row.original.dueDate).toFormat(
                  'MMM dd, yyyy',
                )}
                {/*  epochToDate(row.original.dueDate, 'MMMDD,YYYY') */}
              </ConditionalTextContainerInfo>
            </StyledFirstSubRow>
          </StyledSubRow>
        </SubRowContainer>
        <SubRowContainer>
          <StyledSubRow>
            <ConditionalTextContainerHeader>
              {translate?.('headerPriority')}
            </ConditionalTextContainerHeader>
            <StyledFirstSubRow>
              <ConditionalTextContainerInfo>
                <div>
                  {flagPriorityHandler(row.original.priority.toString())}{' '}
                  {row.original.priority}
                </div>
              </ConditionalTextContainerInfo>
            </StyledFirstSubRow>
          </StyledSubRow>
        </SubRowContainer>
        <SubRowContainer>
          <StyledSubRow>
            <ConditionalTextContainerHeader>
              {translate?.('headerClient')}
            </ConditionalTextContainerHeader>
            <StyledFirstSubRow>
              <ConditionalTextContainerInfo>
                <ClientInfoContainer>
                  <ClientIconContainer>
                    <ClientIcon />
                  </ClientIconContainer>{' '}
                  {clientData
                    ? findClientNameById(row.original.clientId, clientData)
                    : null}
                </ClientInfoContainer>
              </ConditionalTextContainerInfo>
            </StyledFirstSubRow>
          </StyledSubRow>
        </SubRowContainer>
      </RowTaskTableContainer>
    </>
  );
}
