import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { RequestInformationType } from 'pages/request-account';
import { PageWrapper } from 'components';
import { useTranslateAll } from '../../../../locales/translation-hooks';

export interface ClientAccountInfoProps {
  setRequestedInformation: React.Dispatch<
    React.SetStateAction<RequestInformationType>
  >;
  requestedInformation: RequestInformationType;
  nextStep: () => void;
  prevStep: () => void;
}

export function TellUsMore(props: ClientAccountInfoProps): JSX.Element {
  const { requestedInformation, setRequestedInformation, nextStep, prevStep } =
    props;
  const [error, setError] = useState(false);
  const { translateAccountRequest, translateCommon } = useTranslateAll([
    'accountRequest',
    'common',
  ]);

  const organizationTypeArr = [
    translateAccountRequest('tellUsMore.orgTypes.arrItem1'),
    translateAccountRequest('tellUsMore.orgTypes.arrItem2'),
    translateAccountRequest('tellUsMore.orgTypes.arrItem3'),
    translateAccountRequest('tellUsMore.orgTypes.arrItem4'),
    translateAccountRequest('tellUsMore.orgTypes.arrItem5'),
    translateAccountRequest('tellUsMore.orgTypes.arrItem6'),
    translateAccountRequest('tellUsMore.orgTypes.arrItem7'),
  ];

  const organizationSizeArr = [
    translateAccountRequest('tellUsMore.orgSizes.arrItem1'),
    translateAccountRequest('tellUsMore.orgSizes.arrItem2'),
    translateAccountRequest('tellUsMore.orgSizes.arrItem3'),
    translateAccountRequest('tellUsMore.orgSizes.arrItem4'),
  ];

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    group: 'organizationType' | 'organizationSize',
  ) => {
    if (group === 'organizationType') {
      setRequestedInformation({
        ...requestedInformation,
        organizationType: organizationTypeArr[Number(e.target.value)],
      });
    } else {
      setRequestedInformation({
        ...requestedInformation,
        size: organizationSizeArr[Number(e.target.value)],
      });
    }
    setError(false); // Reset error when a selection is made
  };

  const handleNext = () => {
    if (
      requestedInformation.size !== '' &&
      requestedInformation.organizationType !== ''
    ) {
      nextStep();
    } else {
      setError(true);
    }
  };

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
          overflow: 'auto',
        }}
      >
        <Container maxWidth="md">
          <Box maxWidth="582px" mx="auto">
            <Typography
              mb={7}
              color="primary.main"
              textAlign="center"
              gutterBottom
              sx={(theme) => ({
                fontSize: {
                  xs: theme.typography.h5.fontSize,
                  md: theme.typography.h4.fontSize,
                },
              })}
            >
              {translateAccountRequest('tellUsMore.titles.title')}
            </Typography>
            <form>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={4}
                justifyContent="space-between"
              >
                <FormControl
                  component="fieldset"
                  error={error && !requestedInformation.organizationType}
                  sx={{
                    flex: 1,
                    margin: {
                      xs: '40px 0px 40px 0px !important',
                      md: '40px 20px 40px 20px !important',
                    },
                  }}
                >
                  <Typography variant="body2" mb={5} color="primary.main">
                    {translateAccountRequest('tellUsMore.subTitles.subTitle1')}
                  </Typography>
                  <RadioGroup
                    name="organizationType"
                    value={organizationTypeArr.indexOf(
                      requestedInformation.organizationType,
                    )}
                    sx={{ mb: 5 }}
                    onChange={(e) => handleChange(e, 'organizationType')}
                  >
                    {organizationTypeArr.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        value={index}
                        control={<Radio />}
                        label={
                          <Typography
                            textAlign={'center'}
                            variant="body3"
                            color={'textColorSecondary.secondary'}
                          >
                            {item}
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                  {error && !requestedInformation.organizationType && (
                    <FormHelperText>
                      {translateAccountRequest(
                        'requests.errors.selectionRequired',
                      )}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  component="fieldset"
                  error={error && !requestedInformation.size}
                  sx={{
                    flex: 1,
                    margin: {
                      xs: '40px 0px 40px 0px !important',
                      md: '40px 20px 40px 20px !important',
                    },
                  }}
                >
                  <Typography variant="body2" mb={5} color="primary.main">
                    {translateAccountRequest('tellUsMore.subTitles.subTitle2')}
                  </Typography>
                  <RadioGroup
                    name="organizationSize"
                    value={organizationSizeArr.indexOf(
                      requestedInformation.size,
                    )}
                    onChange={(e) => handleChange(e, 'organizationSize')}
                    sx={{ mb: 5 }}
                  >
                    {organizationSizeArr.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        value={index}
                        control={<Radio />}
                        label={
                          <Typography
                            variant="body3"
                            color={'textColorSecondary.secondary'}
                          >
                            {item}
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                  {error && !requestedInformation.size && (
                    <FormHelperText>
                      {translateAccountRequest(
                        'requests.errors.selectionRequired',
                      )}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>
              <Box display="flex" justifyContent="space-between" my={7}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={prevStep}
                  sx={{ width: '120px' }}
                >
                  {translateCommon('buttons.backButton')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  sx={{ width: '120px' }}
                >
                  {translateCommon('buttons.nextButton')}
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>
    </PageWrapper>
  );
}
