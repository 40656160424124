import { Stack } from '@mui/material';
import { SectionHeader, SectionHeaderProps } from '../section-header';
import { Form } from '../form/form';
import { FormValuesStateAndSetter } from '../../user-profile';
import { BasicJoinedUserSettingsType } from 'pages/user-profile/components/types';
import { FormControlFields } from 'pages/user-profile/components/types';
import { ExtendedUserSettingsData } from '../../../../react-query-toolkit/state/user-settings-context';
import {
  createGetPropertyTranslation,
  translateAll,
  useTranslateAll,
} from '../../../../locales/translation-hooks';

const { translateUserProfile } = translateAll(['userProfile']);

const formatsControls: FormControlFields[] = [
  {
    name: 'numberformat',
    controlType: 'input',
    inputType: 'radio',
    options: [
      {
        value: 'decimal',
        get displayedValue() {
          return createGetPropertyTranslation(
            'options.formats.numberFormat.comma',
            translateUserProfile,
          );
        },
      },
      {
        value: 'point',
        get displayedValue() {
          return createGetPropertyTranslation(
            'options.formats.numberFormat.period',
            translateUserProfile,
          );
        },
      },
    ],
    isRequired: true,
    get displayedName() {
      return createGetPropertyTranslation(
        'labels.formats.numberFormat',
        translateUserProfile,
      );
    },
  },
  {
    name: 'timeformat',
    controlType: 'input',
    inputType: 'radio',
    options: [
      {
        value: 'hh:mm a',
        get displayedValue() {
          return createGetPropertyTranslation(
            'options.formats.timeFormat.12',
            translateUserProfile,
          );
        },
      },
      {
        value: 'HH:mm',
        get displayedValue() {
          return createGetPropertyTranslation(
            'options.formats.timeFormat.24',
            translateUserProfile,
          );
        },
      },
    ],
    isRequired: true,
    get displayedName() {
      return createGetPropertyTranslation(
        'labels.formats.timeFormat',
        translateUserProfile,
      );
    },
  },
];

export const Formats = ({
  formInputValuesStateAndSetter,
  fetchedUserSettings,
  handleSubmit,
}: {
  formInputValuesStateAndSetter: FormValuesStateAndSetter;
  fetchedUserSettings: BasicJoinedUserSettingsType;
  handleSubmit: (userDataObject: ExtendedUserSettingsData) => Promise<void>;
}) => {
  const { translateUserProfile } = useTranslateAll(['userProfile']);
  const sectionHeader: SectionHeaderProps = {
    title: translateUserProfile('headings.formats'),
  };

  return (
    <Stack bgcolor={'white'} gap={{ xs: 7, md: 6 }} borderRadius={2}>
      <SectionHeader title={sectionHeader.title} />
      <Form
        area="formats"
        formControlsInfo={formatsControls}
        formInputValuesStateAndSetter={formInputValuesStateAndSetter}
        fetchedUserSettings={fetchedUserSettings}
        handleSubmit={handleSubmit}
      />
    </Stack>
  );
};
