import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Typography,
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from 'utils/capitalize-first-letter';
import { useTranslateAll } from '../../../locales/translation-hooks';

type Props = {
  projectId: string;
  projectName: string;
  clientName: string;
  consultant: string;
  step: string;
  status: string;
  dueDate: string;
};

export const ProjectCard = ({
  projectId,
  projectName,
  clientName,
  consultant,
  step,
  status,
  dueDate,
}: Props) => {
  const navigate = useNavigate();

  const handleProceed = () => navigate(`/my-projects/project-id/${projectId}`);

  const statuses = [
    { value: 'not-started', label: 'Not Started', color: 'secondary.dark' },
    { value: 'on-hold', label: 'On Hold', color: 'error.main' },
    { value: 'in-progress', label: 'In Progress', color: 'primary.dark' },
    { value: 'done', label: 'Done', color: 'success.main' },
  ];

  const matchedStatus = statuses.find((s) => s.value === status) || {
    label: 'Unknown',
    color: 'bg.main',
  };

  const { translateProjects } = useTranslateAll('projects');

  return (
    <Card
      onClick={handleProceed}
      elevation={3}
      sx={{
        width: '100%',
        cursor: 'pointer',
        maxWidth: { xs: '463px', md: 'inherent' },
        minWidth: { xs: '320', md: 'inherent' },
        paddingY: 3,
        paddingX: 5,
        borderRadius: 2,
        display: { xs: 'inherent', md: 'flex' }, // Enable flexbox for dynamic sizing
        flexDirection: { xs: 'inherent', md: 'column' }, // Keep content stacked vertically
        justifyContent: { xs: 'inherent', md: 'space-between' }, // Spread content vertically
        flexGrow: { xs: 'inherent', md: '1' }, // Allows card to expand
        height: { xs: 'inherent', md: '100%' }, // Fills parent height
      }}
    >
      {/* Top Row */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        {/* Title and Client Name */}
        <Box>
          <Typography variant="caption" color="secondary.dark">
            {clientName}
          </Typography>
          <Typography variant="h6" color="primary.dark">
            {projectName}
          </Typography>
        </Box>

        {/* Avatar */}
        <Avatar
          sx={{
            width: 56,
            height: 56,
            bgcolor: 'primary.light',
            fontSize: 24,
          }}
        >
          {consultant.charAt(0)}
        </Avatar>
      </Box>

      <CardContent sx={{ padding: '0 !important' }}>
        <Box display="flex" flexDirection="column" gap={1}>
          {/* //Consultant*/}
          <Box display="flex" alignItems="center" gap={1}>
            {/* Title */}
            <Typography
              variant="subtitle2"
              color="text.primary"
              sx={{ minWidth: '120px' }} // Consistent width for alignment
            >
              {translateProjects('titles.consultant')}
            </Typography>

            {/* Value */}
            <Avatar
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                alignSelf: 'stretch',
                bgcolor: 'primary.light',
                width: '18px',
                height: '18px',
              }}
            >
              <Typography variant="body3">{consultant.charAt(0)}</Typography>
            </Avatar>

            <Typography variant="body3" color="text.primary" paddingLeft={2}>
              {consultant}
            </Typography>
          </Box>

          {/* //Step*/}
          <Box display="flex" alignItems="center" gap={1}>
            {/* Title */}
            <Typography
              variant="subtitle2"
              color="text.primary"
              sx={{ minWidth: '120px' }} // Consistent width for alignment
            >
              {translateProjects('titles.step')}
            </Typography>

            {/* Value */}
            <Typography variant="body3" color="text.primary">
              {capitalizeFirstLetter(step)}
            </Typography>
          </Box>

          {/*//Project Date*/}
          <Box display="flex" alignItems="center" gap={1}>
            {/* Title */}
            <Typography
              variant="subtitle2"
              color="text.primary"
              sx={{ minWidth: '120px' }} // Consistent width for alignment
            >
              {translateProjects('titles.dueDate')}
            </Typography>

            {/* Value */}
            <EventIcon fontSize="small" sx={{ color: 'secondary.dark' }} />
            <Typography variant="body3" color="text.primary">
              {dueDate}
            </Typography>
          </Box>

          {/*//Divider*/}
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

          {/* Status Chip */}
          <Box
            display="flex"
            justifyContent="flex-start"
            paddingTop={6}
            paddingBottom={1}
          >
            <Chip
              label={matchedStatus.label} // Dynamically set the label
              size="small"
              sx={{
                paddingY: 1,
                paddingX: 1,
                fontWeight: 'bold',
                backgroundColor: 'secondary.light',
                color: matchedStatus.color, // Dynamically set the color
              }}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
