import React, {
  Dispatch,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DateArrowRight } from 'icons/date-arrow-right';
import { DateArrowLeft } from 'icons/date-arrow-left';
import { colors } from 'utils/colors';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import {
  ArrowContainer,
  BlueCircle,
  CalendarEventContainer,
  CalendarWrapper,
  ClosingIconContainer,
  DateContainer,
  DateRowContainer,
  DateText,
  ErrorMessage,
  ErrorMessageContainer,
} from 'pages/calendar/styled';
import {
  ModalText,
  PriorityDot,
} from 'pages/dashboard/components/calendar/styled';
import { NewTaskDataType } from 'pages/task-manager/api/task-type';
import {
  DateSelectArg,
  DayCellContentArg,
  EventClickArg,
  EventDropArg,
  EventInput,
} from '@fullcalendar/core';
import { useTaskDataContext } from '../../../react-query-toolkit/state/tasks-context';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { TaskModalForm } from 'pages/task-manager/components/popup-modal/task-modal-form';
import { Xclose } from 'icons/x-close';
import { Container } from 'pages/dashboard/styled';
import {
  CalendarCardContainer,
  ModalItemContainer,
} from 'pages/calendar/components/styled';
import { Box, Tooltip } from '@mui/material';
import { PageTitle } from 'components/atoms/page-title/page-title';
import { WithTopModal } from 'components/top-modal';
import { Project } from '../../../react-query-toolkit/state/types';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslateAll } from '../../../locales/translation-hooks';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { capitalizeFirstLetter } from '../../../utils/capitalize-first-letter';

let eventGuid = 0;

export function createEventId(): string {
  return String(eventGuid++);
}

interface DesktopCalendarProps {
  rawTaskData: NewTaskDataType[];
  userProjects: Project[];
}

export const DesktopCalendar: React.FC<DesktopCalendarProps> = ({
  rawTaskData,
  userProjects,
}) => {
  const [selectedData, setSelectedData] = useState<NewTaskDataType | null>(
    null,
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const fullScreenCalendarRef = useRef<FullCalendar>(null);
  const calendarRef = useRef<FullCalendar>(null);

  const {
    setTaskIdSelected,
    handleUpdateTask,
    tasksListIsLoading,
    tasksFetchingError,
    updateTaskError,
  } = useTaskDataContext();

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().refetchEvents();
    }
    if (fullScreenCalendarRef.current) {
      fullScreenCalendarRef.current.getApi().refetchEvents();
    }
  }, [rawTaskData]);

  const navigate = useNavigate();

  function transformTaskData(taskData: NewTaskDataType[]): EventInput[] {
    return taskData
      .filter((task) => !task.isCompleted)
      .map((task) => ({
        id: createEventId(),
        start: new Date(task.dueDate).toISOString().split('T')[0] + 'T12:00:00',
        eventType: 'task',
        taskId: task.taskId,
        title: task.taskName,
        priority: task.priority,
        isCompleted: task.isCompleted,
        dueDate: task.dueDate,
        description: task.notes,
        clientId: task.clientId,
        clientName: task.clientName,
      }));
  }

  function transformProjectData(projectData: Project[]): EventInput[] {
    return projectData.map((project) => ({
      id: `project-${project.id}`,
      projectId: project.id,
      start:
        new Date(project.duedate).toISOString().split('T')[0] + 'T12:00:00',
      title: `${project.period} ${project.mandate} ${project.jurisdiction}`,
      eventType: 'project',
      priority: 'project',
      description: 'This is a project description',
    }));
  }

  const dates = [
    ...transformTaskData(rawTaskData),
    ...transformProjectData(userProjects),
  ];

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, []);

  useEffect(() => {
    if (selectedData) {
      setModalIsOpen(true);
    }
  }, [selectedData]);

  function handleDateSelect(selectInfo: DateSelectArg) {
    const selectedDate = dayjs(selectInfo.startStr).startOf('day');
    const currentDate = dayjs().startOf('day');

    if (
      selectedDate.isAfter(currentDate) ||
      selectedDate.isSame(currentDate, 'day')
    ) {
      setSelectedData({
        taskName: '',
        priority: 'Medium',
        notes: '',
        dueDate: selectedDate.toISOString(),
        isCompleted: false,
        clientName: '',
        clientId: '',
      });
    } else {
      setErrorMessage('Please select a date from today onwards.');
    }
  }

  function handleEventClick(clickInfo: EventClickArg) {
    if (clickInfo.event.extendedProps?.eventType === 'task') {
      setTaskIdSelected(clickInfo.event.extendedProps.taskId);
      navigate('/task-manager', {
        state: { taskId: clickInfo.event.extendedProps.taskId },
      });
    }
    if (clickInfo.event.extendedProps?.eventType === 'project') {
      navigate(
        `/my-projects/project-id/${clickInfo.event.extendedProps.projectId}`,
      );
    }
  }

  const renderDayCellContent = (dayCellInfo: DayCellContentArg) => {
    const { date, dayNumberText } = dayCellInfo;
    const isToday = dayjs(date).isSame(dayjs(), 'day');
    return isToday ? <BlueCircle>{dayNumberText}</BlueCircle> : dayNumberText;
  };

  const handlePopoverClick = () => {
    // console.log('See More was clicked'); // -Todo Delete from final version if event is not needed
  };

  function handleEventDrop(dropInfo: EventDropArg) {
    const dropDate = dayjs(dropInfo.event.start).startOf('day');
    const currentDate = dayjs().startOf('day');

    if (dropDate.isAfter(currentDate) || dropDate.isSame(currentDate, 'day')) {
      if (dropInfo.event.extendedProps?.eventType === 'task') {
        handleUpdateTask(
          dropInfo.event.extendedProps?.taskId,
          dropInfo.event.title,
          dropInfo.event.extendedProps?.priority,
          dropInfo.event.extendedProps?.isCompleted,
          dropDate.toISOString(),
          dropInfo.event.extendedProps?.description,
          dropInfo.event.extendedProps?.clientId,
        );
        if (updateTaskError) {
          dropInfo.revert();
          setErrorMessage('There was an error updating this task');
        }
      }
    } else {
      setErrorMessage('You cannot drop events to past dates.');
      dropInfo.revert();
    }
  }

  useEffect(() => {
    if (updateTaskError) {
      setErrorMessage('There was an error updating this task');
    }
  }, [updateTaskError]);

  const { translateCommon } = useTranslateAll('common');

  const [fullscreen, setFullscreen] = useState(false);

  const createCalendarComponent = (
    calendarRef: MutableRefObject<FullCalendar | null>,
    rawTaskData: NewTaskDataType[],
    dates: EventInput[],
    handleDateSelect: (selectInfo: DateSelectArg) => void,
    renderEventContent: (eventInfo: {
      timeText: string;
      event: EventInput;
    }) => React.ReactNode,
    handleEventClick: (clickInfo: EventClickArg) => void,
    renderDayCellContent: (dayCellInfo: DayCellContentArg) => React.ReactNode,
    handlePopoverClick: () => void,
    handleEventDrop: (dropInfo: EventDropArg) => void,
  ): React.ReactNode => {
    const { i18n } = useTranslation();
    return (
      <CalendarCardContainer>
        <CalendarHeader
          calendarRef={calendarRef}
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
        />
        <FullCalendar
          key={JSON.stringify(rawTaskData)}
          dayPopoverFormat={{ day: 'numeric', weekday: 'short' }}
          locale={i18n.language}
          dayHeaderFormat={{ weekday: 'short' }}
          height={'100%'}
          eventColor={colors.white}
          eventTextColor={colors.black}
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          headerToolbar={false}
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={2}
          stickyHeaderDates={false}
          initialEvents={dates}
          select={handleDateSelect}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          dayCellContent={renderDayCellContent}
          moreLinkClick={handlePopoverClick}
          eventDrop={handleEventDrop}
        />
      </CalendarCardContainer>
    );
  };
  const createCalendarPopups = (): React.ReactNode => {
    return (
      <>
        {selectedData && (
          <TaskModalForm
            selectedData={selectedData}
            isOpen={modalIsOpen}
            toggleModal={setModalIsOpen}
            modalType={'create'}
          />
        )}
        {errorMessage && (
          <WithTopModal
            isOpen={!!errorMessage}
            width={'auto'}
            toggleModal={setFullscreen}
          >
            <ModalItemContainer>
              <ErrorMessageContainer>
                <ErrorMessage>{errorMessage}</ErrorMessage>
                <ClosingIconContainer onClick={() => setErrorMessage(null)}>
                  <Xclose color={colors.bluePrimary} />
                </ClosingIconContainer>
              </ErrorMessageContainer>
            </ModalItemContainer>
          </WithTopModal>
        )}
      </>
    );
  };

  return (
    <>
      {tasksListIsLoading ? (
        <Box
          display={'flex'}
          width={'100%'}
          height={'30%'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <CircularProgress size={50} sx={{ paddingY: 4 }} />
        </Box>
      ) : dates ? (
        <CalendarWrapper>
          <Container width="100%" margin="0 0 24px 0">
            <PageTitle title={translateCommon('headers.headerCalendar')} />
          </Container>
          {createCalendarComponent(
            calendarRef,
            rawTaskData,
            dates,
            handleDateSelect,
            renderEventContent,
            handleEventClick,
            renderDayCellContent,
            handlePopoverClick,
            handleEventDrop,
          )}
        </CalendarWrapper>
      ) : (
        tasksFetchingError
      )}
      {createCalendarPopups()}
      <WithTopModal isOpen={fullscreen} toggleModal={setFullscreen}>
        <CalendarWrapper>
          {createCalendarComponent(
            fullScreenCalendarRef,
            rawTaskData,
            dates,
            handleDateSelect,
            renderEventContent,
            handleEventClick,
            renderDayCellContent,
            handlePopoverClick,
            handleEventDrop,
          )}
        </CalendarWrapper>
        {createCalendarPopups()}
      </WithTopModal>
    </>
  );
};

function renderEventContent(eventInfo: {
  timeText: string;
  event: EventInput;
}) {
  return (
    <CalendarEventContainer>
      <PriorityDot
        dotSize={'8px'}
        priority={eventInfo.event.extendedProps?.priority}
      />
      <ModalText fontSize={'12px'}>{eventInfo.event.title}</ModalText>
    </CalendarEventContainer>
  );
}

interface CalendarHeaderProps {
  calendarRef: MutableRefObject<FullCalendar | null>;
  fullscreen: boolean;
  setFullscreen: Dispatch<boolean>;
}

const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  calendarRef,
  fullscreen,
  setFullscreen,
}) => {
  const { i18n } = useTranslation();
  const [calendarDate, setCalendarDate] = useState(DateTime.now());
  const title = calendarDate.setLocale(i18n.language).toLocaleString({
    month: 'long',
    year: 'numeric',
  });

  const nextHandle = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().next();
      setCalendarDate(calendarDate.plus({ months: 1 }));
    }
  };

  const prevHandle = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().prev();
      setCalendarDate(calendarDate.minus({ months: 1 }));
    }
  };

  return (
    <DateRowContainer>
      <DateContainer>
        <ArrowContainer onClick={prevHandle}>
          <DateArrowLeft />
        </ArrowContainer>
        <DateText>{capitalizeFirstLetter(title)}</DateText>
        <ArrowContainer onClick={nextHandle}>
          <DateArrowRight />
        </ArrowContainer>
      </DateContainer>
      {!fullscreen ? (
        <Tooltip title="Enter full screen">
          <FullscreenIcon onClick={() => setFullscreen(!fullscreen)} />
        </Tooltip>
      ) : (
        <Tooltip title="Exit full screen">
          <FullscreenExitIcon onClick={() => setFullscreen(!fullscreen)} />
        </Tooltip>
      )}
    </DateRowContainer>
  );
};
