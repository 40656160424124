import styled from 'styled-components';
import { colors } from 'utils/colors';
import { breakpoints } from 'utils/breakpoints';

export const StatusContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  padding-right: 5px;
`;

export const FullContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ProjectHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 0 0 0 10px;
  }
`;

export const InputTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  padding-bottom: 20px;
  @media screen and (max-width: ${breakpoints.md}) {
  }
`;
export const InputTextLabel = styled.p`
  color: ${colors.bluePrimary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07px;
  margin-bottom: 5px;
  @media screen and (max-width: ${breakpoints.md}) {
  }
`;
export const InputText = styled.textarea`
  display: flex;
  height: 81px;
  flex-direction: column;
  align-items: center;
  gap: 149px;
  align-self: stretch;

  color: ${colors.lighterSecondaryBlue};
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 26.5px; /* 176.667% */
  letter-spacing: 0.1px;

  padding-left: 17px;
  padding-top: 10px;

  @media screen and (max-width: ${breakpoints.md}) {
  }
`;

export const DateLabel = styled.div`
  display: flex;
  padding: 0 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: rgba(202, 212, 219, 0.6);
  height: 24px;
  width: 110px;

  @media screen and (max-width: ${breakpoints.md}) {
    width: 110px;
  }
`;
export const LabelText = styled.p`
  color: ${colors.labelText};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.048px;
  white-space: nowrap;
  overflow: hidden;
`;

export const AssigneesContainer = styled.div``;
