{
  "createPassword": {
    "label": "Mot de passe",
    "placeHolder": "Mot de passe...",
    "title": "Créer un mot de passe",
    "passwordStrengthSubtitle": "",
    "errors": {
      "atLeast1Number": "Au moins 1 chiffre.",
      "atLeast1Uppercase": "Au moins 1 majuscule.",
      "min8Char": "Au moins 8 caractères.",
      "isRequired": "Un mot de passe est requis.",
      "atLeast1SpecialChar": "Au moins 1 caractère spécial."
    },
    "strengthEvaluators": {
      "medium": "Moyen",
      "strong": "Fort",
      "weak": "Faible"
    }
  },
  "resetPassword": {
    "button": "RÉINITIALISER LE MOT DE PASSE",
    "emailLabel": "E-mail",
    "emailPlaceHolder": "E-mail",
    "titles": {
      "title": "Réinitialiser le mot de passe",
      "requestEmailTitle": "Mot de passe oublié ?"
    },
    "messages": {
      "bottom": "< Retour à la page de connexion",
      "requestEmail": "Pas de souci, veuillez saisir l'adresse e-mail associée a votre compte."
    },
    "errors": {
      "invalidEmail": "Adresse e-mail invalide",
      "requiredEmail": "Une adresse e-mail est requise"
    }
  }
}
