import React from 'react';
import { CardText } from 'pages/forgot-password/components/styled';
import { Button, Heading } from 'components';
import { useNavigate } from 'react-router-dom';
import { useTranslateAll } from '../../../locales/translation-hooks';

export function ConfirmationDisplay(): JSX.Element {
  const navigate = useNavigate();
  const { translateCommon } = useTranslateAll(['common']);

  return (
    <>
      <div style={{ width: '100%', textAlign: 'center', marginBottom: '24px' }}>
        <Heading level={1} color="secondaryBlue">
          {translateCommon('titles.congratulations')}
        </Heading>
      </div>
      <CardText>{translateCommon('messages.confirmationMessage')}</CardText>
      <div style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }}>
        <Button onClick={() => navigate('/login')}>
          {translateCommon('buttons.confirmationButton')}
        </Button>
      </div>
    </>
  );
}
