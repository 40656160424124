import { NewProjectDataType } from 'pages/projects/get-projects';
import {
  NewProjectStep,
  Project,
  ProjectComment,
  ProjectPLResponse,
  ProjectStep,
  ProjectText, ProjectUserRef,
} from '../../../react-query-toolkit/state/types';

export default function refactorProjectPlData(
  data: ProjectPLResponse,
  projectId: string,
): NewProjectDataType {
  if (!data || !Array.isArray(data)) {
    return {
      projectId: '',
      projectClientId: '',
      projectJurisdiction: '',
      projectMandate: '',
      projectSteps: [],
      projectComments: [],
      projectCurrentStep: '',
      projectAssignees: [],
      projectDueDate: '',
      successCriteria: [],
      applicableStandard: [],
      intendedUse: [],
      projectType: '',
      projectPeriod: '',
      nextProject: '',
      accountManager: '',
      previousProject: '',
    };
  }

  const projectStepsOrder = [
    'set-up',
    'data-collection',
    'research',
    'calculation',
    'calculation-review',
    'draft-report',
    'report-review',
    'client-review',
    'finalize-report',
    'close-project',
  ];

  // Initialize project steps with empty assignees array
  const projectSteps: NewProjectStep[] = data
    .filter((item): item is ProjectStep => item.typename === 'projectstep')
    .map((step) => ({
      sk_id: step.sk_id || '',
      name: step.name,
      duedate: step.duedate,
      status: step.status,
      assignees: [],
      typename: 'projectstep' as const // <-- Explicitly cast as a literal type
    }))
    .sort(
      (a, b) =>
        projectStepsOrder.indexOf(a.name) - projectStepsOrder.indexOf(b.name),
    );

  // Extract all users with roles
  const projectUsers = data.filter(
    (item): item is ProjectUserRef => item.typename === 'ref:user'
  );

  // Assign users to the correct project step based on roles
  projectUsers.forEach((user) => {
    if (user.roles && Array.isArray(user.roles)) {
      user.roles.forEach((role) => {
        const stepName = projectStepsOrder[role.id - 1]; // Match role ID to project step order
        const matchingStep = projectSteps.find((step) => step.name === stepName);
        if (matchingStep) {
          matchingStep.assignees.push(user.id); // Assign user ID to the step
        }
      });
    }
  });


// Assign projectAssignees as a list of unique user IDs
  const projectAssignees = [...new Set(projectUsers.map((user) => user.id))];

  // Filter and sort project comments
  const projectComments: ProjectComment[] = data
    .filter((item): item is ProjectComment => item.typename === 'comment')
    .map((comment) => ({
      id: comment.id,
      typename: comment.typename,
      created_at: comment.created_at,
      author: comment.author,
      parent: comment.parent,
      projectstep: comment.projectstep,
      read: comment.read,
      tags: comment.tags,
      text: comment.text,
      checked: comment.checked,
    }));

  // Categorize project texts by language
  const successCriteriaTexts: { language: string; text: string }[] = [];
  const applicableStandardTexts: { language: string; text: string }[] = [];
  const intendedUseTexts: { language: string; text: string }[] = [];

  data
    .filter((item): item is ProjectText => item.typename === 'projecttext')
    .forEach((text) => {
      successCriteriaTexts.push({
        language: text.language,
        text: text.success_criteria,
      });
      applicableStandardTexts.push({
        language: text.language,
        text: text.applicable_standard,
      });
      intendedUseTexts.push({
        language: text.language,
        text: text.intended_use,
      });
    });

  // Extract main project data
  const mainProject = data.find(
    (item): item is Project => item.typename === 'project',
  );

  // Utility function to clean `pj#` prefix from project IDs
  const cleanProjectId = (id?: string) => (id?.startsWith('pj#') ? id.slice(3) : id || '');
  const cleanUsertId = (id?: string) => (id?.startsWith('us#') ? id.slice(3) : id || '');

  return {
    projectId: projectId || '',
    projectClientId: mainProject?.client || '',
    projectMandate: mainProject?.mandate || '',
    projectSteps: projectSteps,
    projectComments: projectComments,
    projectCurrentStep: mainProject?.step || '',
    projectJurisdiction: mainProject?.jurisdiction || '',
    projectDueDate: mainProject?.duedate || '',
    projectType: mainProject?.projecttype || '',
    projectPeriod: mainProject?.period || '',
    nextProject: cleanProjectId(mainProject?.next_project) || '',
    accountManager: cleanUsertId(mainProject?.accountmanager) || '',
    previousProject: cleanProjectId(mainProject?.previous_project) || '',
    projectAssignees: projectAssignees, // Updated list of unique user IDs
    successCriteria: successCriteriaTexts,
    applicableStandard: applicableStandardTexts,
    intendedUse: intendedUseTexts,
  };
}
