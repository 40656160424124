{
  "unknownUser": "Utilisateur inconnu",
  "me": "Moi",
  "searchProjectsPlaceholder": "Rechercher des projets…",
  "searchCommentsPlaceholder": "Rechercher des commentaires…",
  "addCommentPlaceholder": "Ajouter un commentaire",
  "filterAll": "TOUT",
  "filterStarted": "COMMENCÉ",
  "filterReview": "REVUE",
  "filterCompleted": "COMPLÉTÉ",
  "confirmAction": "Confirmer l'action",
  "actions": {
    "edit": "Modifier",
    "delete": "Supprimer",
    "reply": "Répondre",
    "close": "Fermer",
    "showReplies": "Afficher les réponses",
    "hideReplies": "Masquer les réponses",
    "markAsAddressed": "Marquer comme traité",
    "seeAllProjects": "Voir tous les projets pour ce client"
  },
  "notes": {
    "projectDueDateChange": "Date d'échéance du projet modifiée",
    "projectStepDueDateChange": "Date d'échéance de l'étape du projet modifiée",
    "projectStepStatusChange": "Statut de l'étape du projet modifié"
  },
  "titles": {
    "projectName": "Nom du projet :",
    "client": "Client :",
    "consultant": "Consultant :",
    "dueDate": "Date d'échéance :",
    "step": "Étape"
  },
  "statuses": {
    "active": "En cours",
    "completed": "Complété",
    "overDue": "En retard",
    "today": "Aujourd'hui",
    "projectSteps": {
      "notStarted": "Non commencé",
      "onHold": "En attente",
      "inProgress": "En cours",
      "done": "Terminé"
    }
  },
  "messages": {
    "noProject": "... Aucun projet à afficher",
    "loading": "...Chargement",
    "displayProjectsNoItemsMessage": "",
    "confirmMarkAsAddressed": "Vous êtes sur le point de marquer ce commentaire comme traité. Voulez-vous continuer ?"
  },
  "buttons": {
    "projectOverview": {
      "save": "Sauvegarder",
      "overview": "Vue d'ensemble"
    }
  },
  "steps": {
    "setup": "configuration",
    "dataCollection": "collecte de données ",
    "research": "recherche",
    "calculation": "phase de calcul",
    "calculationReview": "vérification des calculs ",
    "draftReport": "rapport préliminaire",
    "reportReview": "revue de rapport",
    "clientReview": "revue client",
    "finalizeReport": "finaliser le rapport",
    "closeProject": "clôturer le projet"
  }
}
