{
    "description":{
        "default": "Notification",
        "receptionDate": "Received: {{createdAt}}",
        "changedProjectStepDueDate": "{{name}} step's due date was changed",
        "changedProjectDueDate": "Project due date was changed to {{duedate}}",
        "createdNewTask": "A new task \"{{name}}\" was created",
         "changedProjectStepStatus": "Status for \"{{name}}\" step was changed to {{status}}",
        "noDetailsAvailable": "No additional details available."
    },
    "titles": {
        "systemNotices": "System Notices",
        "openProject": "Open Project",
        "reportReview": "Report Review"
    }
}
