import styled from 'styled-components';
import { colors, spacingWithPx } from 'utils/styles';
import { UserIcon, PencilAngledIcon, Hoverable } from 'icons';
import { Paragraph } from 'components';
import { StatusBar } from './status-bar';
import { breakpoints } from 'utils/breakpoints';
import { useNavigate } from 'react-router-dom';
import { useTranslateAll } from '../../../locales/translation-hooks';
import {
  projectStepsTranslationMappings,
  ProjectStepsTranslationKeys,
} from '../../../locales/mappings';

type Props = {
  icon?: JSX.Element;
  projectName: string;
  progress: number;
  step: string;
  projectId: string;
};

const Item = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: [icon] 0.2fr [projectName] 1fr [statusBar] 1.5fr [progress] 0.5fr [editIcon] 0.2fr;
  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-template-columns: [icon] 0.2fr [projectName] 0.5fr [statusBar] 2fr [progress] 0.5fr [editIcon] 0.2fr;
  }
  gap: ${spacingWithPx.sm};
  align-items: center;
  padding: 2px 0;
`;

export const MyProjectsItem = ({
  // icon, // ToDo: Change this to required prop, and use custom icon, replacing UserIcon placeholder
  projectName,
  progress,
  step,
  projectId,
}: Props) => {
  const navigate = useNavigate();
  const projectURL = `/my-projects/project-id/${projectId}`;
  const { translateProjects } = useTranslateAll('projects');

  return (
    <Item onClick={() => navigate(projectURL)}>
      <UserIcon />
      <Paragraph>{projectName}</Paragraph>
      <StatusBar progress={progress} />
      <Paragraph>
        {translateProjects(
          projectStepsTranslationMappings[
            step as keyof ProjectStepsTranslationKeys
          ],
        )}
      </Paragraph>
      <Hoverable hoverColor={colors.bluePrimary}>
        <PencilAngledIcon />
      </Hoverable>
    </Item>
  );
};
