import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import './style.css';
import { Login } from 'pages/login';
import { store } from './state/store';
import { AuthProvider } from 'configs/AuthContext';
import { LanguageProvider } from 'locales/context/LanguageContext';
import { languages } from 'locales/languages';
import { Clients } from 'pages/clients';
import { LanguagesExample } from 'pages/i18n-example';
import { ProjectsWrapper } from 'pages/projects';
import './configs/amplify';
import { RequestAccount } from 'pages/request-account';
import { ClientDataProvider } from './react-query-toolkit/state/client-context';
import { TaskDataProvider } from './react-query-toolkit/state/tasks-context';
import { ChangePassword } from 'pages/change-password';
import { ForgotPassword } from 'pages/forgot-password';
import { ApiProvider } from '@reduxjs/toolkit/query/react';
import { ghgSfeApi } from './react-query-toolkit/services/ghgci-sfe';
import { NavAndSideBar } from 'components/nav-and-sidebar/nav-and-sidebar';
import { NavAndSideBarProvider } from 'src/components/nav-and-sidebar';
import { Dashboard } from 'pages/dashboard';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ProtectedRoute from 'utils/private-route';
import { TaskManager } from 'pages/task-manager';
import ProjectItem from 'pages/projects/components/project-item';
import { Calendar } from 'pages/calendar';
import { UserProfile } from 'pages/user-profile/user-profile';
import { Notifications } from 'pages/notifications';
import { Faq } from 'pages/faq';
import { UserSettingsDataProvider } from './react-query-toolkit/state/user-settings-context';
import { UserDataProvider } from './react-query-toolkit/state/user-context';
import { ProjectsDataProvider } from './react-query-toolkit/state/projects-context';
import { NotificationDataProvider } from './react-query-toolkit/state/notifications-context';
import {loadAmplifyConfig} from "configs/amplify";

export interface ComponentAdapterProps {
  component: JSX.Element;
  isProtected?: boolean;
}

const ComponentAdapter = ({ isProtected, component }: ComponentAdapterProps) =>
  isProtected ? (
    <ProtectedRoute>
      <NavAndSideBar>{component}</NavAndSideBar>
    </ProtectedRoute>
  ) : (
    <NavAndSideBar>{component}</NavAndSideBar>
  );

const protectedRoutes = (
  <>
    <Route
      path="/:dashboard?"
      element={
        <ComponentAdapter
          isProtected
          component={
            <TaskDataProvider>
            <ClientDataProvider>
              <UserDataProvider>
                <Dashboard />
              </UserDataProvider>
            </ClientDataProvider>
            </TaskDataProvider>
          }
        />
      }
    />
    <Route
      path="task-manager"
      element={
        <ComponentAdapter
          isProtected
          component={
            <TaskDataProvider>
                <ClientDataProvider>
              <UserDataProvider>
                <TaskManager />
              </UserDataProvider>
                </ClientDataProvider>
            </TaskDataProvider>
          }
        />
      }
    />
    <Route
      path="calendar"
      element={
        <ComponentAdapter
          isProtected
          component={
            <TaskDataProvider>
              <UserDataProvider>
                <Calendar />
              </UserDataProvider>
            </TaskDataProvider>
          }
        />
      }
    />
    <Route
      path="my-clients"
      element={
        <ComponentAdapter
          isProtected
          component={
            <UserDataProvider>
            <ClientDataProvider>
              <Clients />
              </ClientDataProvider>
            </UserDataProvider>
          }
        />
      }
    />
    <Route
      path="notifications"
      element={
        <ComponentAdapter
          isProtected
          component={
            <NotificationDataProvider>
              <Notifications />
            </NotificationDataProvider>
          }
        />
      }
    />
    <Route
      path="faq"
      element={<ComponentAdapter isProtected component={<Faq />} />}
    />
    <Route
      path="my-projects/project-id/:projectId"
      element={
        <ComponentAdapter
          isProtected
          component={
            <ProjectsDataProvider>
              <UserDataProvider>
              <ClientDataProvider>
                <ProjectItem />
            </ClientDataProvider>
              </UserDataProvider>
            </ProjectsDataProvider>
          }
        />
      }
    />
    {/* Specific path for client ID */}
    <Route
      path="my-projects/client-id/:clientId"
      element={<ComponentAdapter isProtected component={<ClientDataProvider><ProjectsWrapper /></ClientDataProvider>} />}
    />
    {/* General path for /my-projects */}
    <Route
      path="my-projects"
      element={
        <ComponentAdapter
          isProtected
          component={
            <UserDataProvider>
            <ClientDataProvider>
              <ProjectsWrapper />
              </ClientDataProvider>
            </UserDataProvider>
          }
        />
      }
    />
    {/* Catch-all route for any other path within /my-projects */}
    <Route
      path="my-projects/*"
      element={
        <ComponentAdapter
          isProtected
          component={
            <UserDataProvider>
            <ClientDataProvider>
              <ProjectsWrapper />
              </ClientDataProvider>
            </UserDataProvider>
          }
        />
      }
    />
    <Route
      path="user-profile"
      element={
        <ComponentAdapter
          isProtected
          component={
            <UserDataProvider>
              <UserSettingsDataProvider>
                <UserProfile />
              </UserSettingsDataProvider>
            </UserDataProvider>
          }
        />
      }
    />
  </>
);

const unprotectedRoutes = (
  <>
    <Route
      path="request-account"
      element={<ComponentAdapter component={<RequestAccount />} />}
    />
    <Route
      path="forgot-password"
      element={<ComponentAdapter component={<ForgotPassword />} />}
    />
    <Route path="login" element={<ComponentAdapter component={<Login />} />} />
    <Route
      path="change-password"
      element={<ComponentAdapter component={<ChangePassword />} />}
    />
    <Route
      path="internal-languages"
      element={<ComponentAdapter component={<LanguagesExample />} />}
    />
  </>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {protectedRoutes}
      {unprotectedRoutes}
    </>,
  ),
    //This must be added to take away react warning
    {future: {
            v7_fetcherPersist: true,
            v7_normalizeFormMethod: true,
            v7_partialHydration: true,
            v7_relativeSplatPath: true,
            v7_skipActionErrorRevalidation: true,
        },
    }
);

// Updated for React 18 using createRoot
export const App = async () => {
    await loadAmplifyConfig();
    const rootElement = document.getElementById('root');
    const root = createRoot(rootElement!);

  root.render(
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ApiProvider api={ghgSfeApi}>
          <I18nextProvider i18n={languages}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <LanguageProvider>
                <AuthProvider>
                  <NavAndSideBarProvider>
                      <RouterProvider router={router} future={{
                          v7_startTransition: true, // This needs to be added to take away alerts of React
                      }}/>
                  </NavAndSideBarProvider>
                </AuthProvider>
              </LanguageProvider>
            </LocalizationProvider>
          </I18nextProvider>
        </ApiProvider>
      </Provider>
    </ThemeProvider>,
  );
};
