{
  "requests": {
    "accountAddress": "",
    "accountCity": "",
    "accountClient": "",
    "accountContactName": "",
    "accountCountry": "",
    "accountEmail": "",
    "accountPhone": "",
    "accountRegion": "",
    "accountState": "",
    "errors": {
      "default": "",
      "selectionRequired": ""
    }
  },
  "reviewing": {
    "reviewYourInfoClientInfoTitle": "",
    "reviewYourInfoTitle": ""
  },
  "services": {
    "arrItem1": "",
    "arrItem2": "",
    "arrItem3": "",
    "arrItem4": "",
    "arrItem5": "",
    "interestQuestion": ""
  },
  "tellUsMore": {
    "orgSizes": {
      "arrItem1": "",
      "arrItem2": "",
      "arrItem3": "",
      "arrItem4": ""
    },
    "orgTypes": {
      "arrItem1": "",
      "arrItem2": "",
      "arrItem3": "",
      "arrItem4": "",
      "arrItem5": "",
      "arrItem6": "",
      "arrItem7": ""
    },
    "subTitles": {
      "subTitle1": "",
      "subTitle2": "",
      "serviceProfile": ""
    },
    "titles": {
      "title": "",
      "profileTitle": "",
      "profileCommentTitle": ""
    }
  }
}
