{
  "errors":{
    "inputEmailCodeConfirmationCodeErrorNotEmpty": "Le code ne peut pas être vide",
    "inputEmailCodeConfirmationCodeErrorRequired": "Le code est requis",
    "inputEmailCodePasswordErrorMatches": "Le mot de passe doit contenir au moins 1 lettre, 1 chiffre et 1 caractère spécial",
    "inputEmailCodePasswordErrorMin8": "Le mot de passe doit comporter au moins 8 caractères",
    "inputEmailCodePasswordErrorRequired": "Le mot de passe est requis"

  },
  "labels":{
    "inputEmailCodeEmailCodeLabel": "Envoyer le code par e-mail",
    "inputEmailCodePasswordLabel": "Nouveau mot de passe"

  },
  "placeholders":{
      "inputEmailCodeEmailCodePlaceholder": "Envoyer le code par e-mail",
      "inputEmailCodePasswordPlaceholder": "Nouveau mot de passe"
  },
  "titles":{
    "inputEmailCodeTitle": "Mot de passe oublié ?"
  },
  "messages":{
    "inputEmailCodeBottomMessage": "< Retour à la page de connexion",
    "inputEmailCodeEmailAddressConfirmation": "Veuillez confirmer votre adresse e-mail: {{ userEmail }}",
    "inputEmailCodeReceiverConfirmation": "Vous n'avez pas reçu de code ?",
    "inputEmailCodeWrongEmail": "Mauvais e-mail ?"
  },
  "buttons":{
    "inputEmailCodeSubmitButton": "RÉINITIALISER LE MOT DE PASSE"
  }
}
