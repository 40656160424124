{
  "NavBar": {
    "navBarNavigationItems": {
      "myClients": "Meine Kunden",
      "consultants": "Berater",
      "dashboard": "Dashboard",
      "projects": "Projekte",
      "tasks": "Aufgaben",
      "userProfile": "Mein Profil",
      "calendar": "Kalender",
      "notifications": "Benachrichtigungen",
      "reports": "Berichte",
      "signout": "Abmelden"
    },
    "taskManager": "Aufgaben-Manager"
  }
}
