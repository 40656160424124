{
  "NavBar":{
    "navBarNavigationItems":{
      "myClients": "Mes Clients",
      "consultants": "Consultants",
      "dashboard": "Tableau de bord",
      "projects": "Projets",
      "tasks": "Tâches",
      "userProfile": "Mon Profile",
      "calendar": "Calendrier",
      "notifications": "Notifications",
      "reports": "Rapports",
      "signout": "Déconnexion"
    },
    "taskManager":"Gestionnaire de tâches"
  }
}
