{
    "unknownUser": "Unknown User",
    "me": "Me",
    "searchProjectsPlaceholder": "Search Projects...",
    "searchCommentsPlaceholder": "Search Comments...",
    "addCommentPlaceholder":"Add comment",
    "filterAll": "ALL",
    "filterStarted": "STARTED",
    "filterReview": "REVIEW",
    "filterCompleted": "COMPLETED",
    "confirmAction": "Confirm Action",
    "actions": {
        "edit": "Edit",
        "delete": "Delete",
        "reply": "Reply",
        "close": "Close",
        "showReplies": "Show Replies",
        "hideReplies": "Hide Replies",
        "markAsAddressed": "Mark as Addressed",
        "seeAllProjects":"See all projects for this client"

    },
    "notes":{
        "projectDueDateChange": "Project Due Date Changed",
        "projectStepDueDateChange": "Project Step Due Date Changed",
        "projectStepStatusChange": "Project Step Status Changed"
    },
    "titles":{
        "projectName": "Project Name:",
        "client": "Client:",
        "consultant": "Consultant:",
        "dueDate": "Due Date:",
        "step": "Step"
    },
    "statuses":{
        "active": "Active",
        "completed": "Completed",
        "overDue": "Overdue",
        "today": "Today",
        "projectSteps":{
            "notStarted": "Not Started",
            "onHold": "On Hold",
            "inProgress": "In Progress",
            "done": "Done"
        }
    },
    "messages":{
        "noProject": "... No Projects to display",
        "loading": "...Loading",
      "displayProjectsNoItemsMessage": "No Projects to display",
        "confirmMarkAsAddressed": "You are about to mark this comment as addressed. Do you want to continue?"
    },
    "buttons": {
        "projectOverview":{
            "save": "Save",
            "overview": "Overview"
        }
    },
    "steps":{
        "setup":"set-up",
        "dataCollection":"data-collection",
        "research":"research",
        "calculation":"calculation",
        "calculationReview":"calculation-review",
        "draftReport":"draft-report",
        "reportReview":"report-review",
        "clientReview":"client-review",
        "finalizeReport":"finalize-report",
        "closeProject":"close-project"
    }
}
