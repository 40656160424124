{
  "createPassword": {
    "label": "",
    "placeHolder": "",
    "title": "",
    "passwordStrengthSubtitle": "",
    "errors": {
      "atLeast1Number": "",
      "atLeast1Uppercase": "",
      "min8Char": "",
      "isRequired": "",
      "atLeast1SpecialChar": ""
    },
    "strengthEvaluators": {
      "medium": "",
      "strong": "",
      "weak": ""
    }
  },
  "resetPassword": {
    "button": "",
    "emailLabel": "",
    "emailPlaceHolder": "",
    "titles": {
      "title": "",
      "requestEmailTitle": ""
    },
    "messages": {
      "bottom": "",
      "requestEmail": ""
    },
    "errors": {
      "invalidEmail": "",
      "requiredEmail": ""
    }
  }
}
