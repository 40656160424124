{
  "ConfirmationMessage": "We have updated your password! Please click on Continue and log In using your email and new password.",
  "LoginFormButtonAction": "LOG IN",
  "LoginFormButtonRequestAccount": "REQUEST ACCOUNT",
  "LoginFormEmailLabel": "Email",
  "LoginFormEmailPlaceholder": "Email",
  "LoginFormForgotPassword": "Forgot Password",
  "LoginFormPasswordLabel": "Password",
  "LoginFormPasswordPlaceholder": "Password",
  "LoginFormRequestAccountMessage": "Don't have an account?",
  "ProjectTableLoadingMessage": "... No Projects to display",
  "ProjectLoadingMessage": "...Loading",
  "SeeAllTasksTitle": "See All Tasks",
  "activeTitle": "Active",
  "addTaskTitle": "ADD TASK",
  "taskFormDropdownClientNone": "None",
  "applicableStandardLabel": "Aplicable Standard",
  "backButton": "BACK",
  "calendarShortWeekDayFriday": "FR",
  "calendarShortWeekDayMonday": "MO",
  "calendarShortWeekDaySaturday": "SA",
  "calendarShortWeekDaySunday": "SU",
  "calendarShortWeekDayThursday": "TH",
  "calendarShortWeekDayTuesday": "TU",
  "calendarShortWeekDayWednesday": "WE",
  "clientAccountInfoAddressPlaceholder": "Address...",
  "clientAccountInfoCityLabel": "City",
  "clientAccountInfoCityPlaceholder": "City...",
  "clientAccountInfoClientLabel": "Client *",
  "clientAccountInfoClientPlaceholder": "ClientName...",
  "clientAccountInfoContactLabel": "Contact Name*",
  "clientAccountInfoContactPlaceholder": "Contact Name...",
  "clientAccountInfoCountryPlaceholder": "Country...",
  "clientAccountInfoEmailLabel": "Email *",
  "clientAccountInfoEmailPlaceholder": "Email...",
  "clientAccountInfoErrorMessageContactNotEmpty": "Contact Name cannot be empty.",
  "clientAccountInfoErrorMessageContactRequired": "Contact Name is required",
  "clientAccountInfoErrorMessageEmailInvalid": "Invalid email address",
  "clientAccountInfoErrorMessageEmailRequired": "Email is required",
  "clientAccountInfoErrorMessageNameNotEmpty": "Client Name cannot be empty.",
  "clientAccountInfoErrorMessageNameRequired": "Client Name is required",
  "clientAccountInfoErrorMessagePhoneInvalid": "Invalid phone number.",
  "clientAccountInfoErrorMessagePhoneRequired": "Phone Number is required",
  "clientAccountInfoPhoneLabel": "Phone *",
  "clientAccountInfoPhonePlaceholder": "Phone Number...",
  "clientAccountInfoRegionPlaceholder": "Region...",
  "clientAccountInfoStatePlaceholder": "State...",
  "clientAccountInfoTitle": "Client Account Information",
  "completedTitle": "Completed",
  "confirmationButton": "CONTINUE",
  "confirmationDisplayTitle": "Congratulations!",
  "createPasswordErrorMessageAtLeast1Number": "At least 1 number.",
  "createPasswordErrorMessageAtLeast1Uppercase": "At least 1 uppercase.",
  "createPasswordErrorMessageMin8": "At least 8 char.",
  "createPasswordErrorMessageRequired": "Password is required.",
  "createPasswordLabel": "Password*",
  "createPasswordPlaceHolder": "Password...",
  "createPasswordTitle": "Create a Password",
  "dataTable": "Data Table",
  "displayTableFetchingErrorMessage": "There was an error",
  "displayTableNoItemsMessage": "No Items to display",
  "formCancelButton": "Cancel",
  "formSaveButton": "SAVE",
  "greeting": "Hello, World!",
  "headerCalendar": "Calendar",
  "headerClient": "Clients",
  "headerUserProfile":"User Profile",
  "headerConsultant": "Consultant",
  "headerDashboard": "Dashboard",
  "headerDueDate": "Due Date",
  "headerEdit": "Edit",
  "headerEmail": "Email",
  "headerHelpButton": "HELP",
  "headerLocation": "Location",
  "headerLogin": "Log In",
  "headerName": "Name",
  "headerNotifications": "Notifications",

  "note_duedateChangePSText": "Project Step Due Date Changed",
  "note_duedateChangeProjectText": "Project Due Date Changed",
  "note_newtaskText": "Task Created",
  "note_statusChangeProjectstepText": "Project Step Status Changed",
  "dueDateTomorrowText": "Tomorrow",
  "dueDateYesterdayText": "Yesterday",
  "notificationFallbackText": "Notification",
  "notificationDueDateChangedPSDescriptionText": "{{name}} step's due date was changed",
  "notificationDueDateChangedProjectDescriptionText": "Project due date was changed to {{duedate}}",
  "notificationNewTaskDescriptionText": "A new task \"{{name}}\" was created",
  "notificationStatusChangedDescriptionText": "Status for \"{{name}}\" step was changed to {{status}}",
  "notificationNoDetailsAvailableText": "No additional details available.",
  "errorDialogTitleText": "Error",
  "errorDialogDescriptionText": "There was an error deleting the notification. Please try again.",
  "errorDialogCloseButtonText": "Close",
  "receivedNotificationText": "Received: {{createdAt}}",

  "headerPhone": "Phone",
  "headerPriority": "Priority",
  "headerProjectAssignees": "Assignees",
  "headerProjectComments": "Comments",
  "headerProjectDueDate": "Due Date",
  "headerProjectStatus": "Status",
  "headerProjectSteps": "Steps",
  "headerProjects": "My Projects",
  "headerSingleProject": "Project",
  "projectNameTitle": "Project Name:",
  "projectClientTitle": "Client:",
  "projectConsultantTitle": "Consultant:",
  "projectDueDateTitle": "Due Date:",
  "projectOverviewButtonText": "Overview",
  "unknownClient": "Unknown Client",
  "unknownManager": "Unknown Manager",
  "unknownDueDate": "Unknown Due Date",
  "headerSettings": "User Profile",
  "headerTask": "Task",
  "headerTaskManager": "Task Manager",
  "inputEmailCodeBottomMessage": "< Back to Log In",
  "inputEmailCodeConfirmationCodeErrorNotEmpty": "Code can't be empty",
  "inputEmailCodeConfirmationCodeErrorRequired": "Code is required",
  "inputEmailCodeEmailAddressConfirmation": "Please confirm your email address: {{ userEmail }}",
  "inputEmailCodeEmailCodeLabel": "Email Code",
  "inputEmailCodeEmailCodePlaceholder": "Email Code",
  "inputEmailCodePasswordErrorMatches": "Password must contain at least 1 letter, 1 number, and 1 special character",
  "inputEmailCodePasswordErrorMin8": "Password must be at least 8 characters",
  "inputEmailCodePasswordErrorRequired": "Password is required",
  "inputEmailCodePasswordLabel": "New Password",
  "inputEmailCodePasswordPlaceholder": "New Password",
  "inputEmailCodeReceiverConfirmation": "Didn't receive a code?",
  "inputEmailCodeSubmitButton": "RESET PASSWORD",
  "inputEmailCodeTitle": "Forgot Password?",
  "inputEmailCodeWrongEmail": "Wrong email?",
  "intendedUseLabel": "Intended Use",
  "loginSchemaEmailRequired": "Email is required",
  "loginSchemaInvalidEmail": "Invalid email address",
  "loginSchemaPasswordLowercase": "Password must contain at least 1 lowercase character",
  "loginSchemaPasswordMinLength": "Password must be at least 8 characters",
  "loginSchemaPasswordNumber": "Password must contain at least 1 number",
  "loginSchemaPasswordRequired": "Password is required",
  "loginSchemaPasswordSpecialCharacter": "Password must contain at least 1 special character",
  "loginSchemaPasswordUppercase": "Password must contain at least 1 uppercase character",
  "message": "Welcome to GHG CI.",
  "myClients": "My Clients",
  "navBarGreeting": "Hi",
  "nextButton": "NEXT",
  "overDueTitle": "Overdue",
  "passwordStrengthEvaluatorMedium": "Medium",
  "passwordStrengthEvaluatorStrong": "Strong",
  "passwordStrengthEvaluatorWeak": "Weak",
  "projectOverviewSave": "Save",
  "requestAccountAddress": "Address",
  "requestAccountCity": "City",
  "requestAccountClient": "Client",
  "requestAccountContactName": "Contact Name",
  "requestAccountCountry": "Country",
  "requestAccountEmail": "Email",
  "requestAccountErrorMessage": "There was an error",
  "requestAccountPhone": "Phone",
  "requestAccountRegion": "Region",
  "requestAccountState": "State/Province",
  "resetPasswordRequestBottomMessage": "< Back to Log In",
  "resetPasswordRequestButton": "RESET PASSWORD",
  "resetPasswordRequestEmailLabel": "Email",
  "resetPasswordRequestEmailMessage": "No Worries, please input your account email.",
  "resetPasswordRequestEmailPlaceHolder": "Email",
  "resetPasswordRequestEmailTitle": "Forgot Password?",
  "resetPasswordRequestErrorInvalidEmail": "Invalid email address",
  "resetPasswordRequestErrorRequiredEmail": "Email is required",
  "reviewYourInfoClientInfoTitle": "Client Information",
  "reviewYourInfoTitle": "Review Your Information",
  "seeAll": "See All",
  "serviceArrItem1": "Track GHG Inventory",
  "serviceArrItem2": "CI Carbon Intensity",
  "serviceArrItem3": "Climate Lens",
  "serviceArrItem4": "Offset Project",
  "serviceArrItem5": "Other",
  "serviceInterestQuestion": "What service are you interesting in?",
  "serviceProfileCommentTitle": "COMMENTS",
  "serviceProfileSubTitle": "What service are you interesting in?",
  "serviceProfileTitle": "Service Profile",
  "sizeOfOrganizationQuestion": "What is the size of your organization?",
  "submitButton": "SUBMIT",
  "successCriteriaLabel": "Success Criteria",
  "taskCompletedMessage": "1 Task deleted",
  "taskFormDescription": "Description",
  "taskFormDropdownClientDefault": "Client",
  "taskFormDropdownPriorityAddButton": "ADD TASK",
  "taskFormDropdownPriorityHigh": "High",
  "taskFormDropdownPriorityLow": "Low",
  "taskFormDropdownPriorityMedium": "Medium",
  "taskFormDropdownPriorityTitle": "Priority",
  "taskFormDropdownPriorityUrgent": "Urgent",
  "taskFormDueDateText": "Due Date",
  "taskFormName": "Task Name",
  "taskTableLoadingMessage": "... No Tasks to display",
  "tellUsMoreOrgSizeArrItem1": "1-50 employees",
  "tellUsMoreOrgSizeArrItem2": "51-500 employees",
  "tellUsMoreOrgSizeArrItem3": "501 - 1000 employees",
  "tellUsMoreOrgSizeArrItem4": "1001+ employees",
  "tellUsMoreOrgTypeArrItem1": "Government (Regional or Local)",
  "tellUsMoreOrgTypeArrItem2": "Company / Business",
  "tellUsMoreOrgTypeArrItem3": "Industry",
  "tellUsMoreOrgTypeArrItem4": "Non-profit / Charity",
  "tellUsMoreOrgTypeArrItem5": "Educational Institution",
  "tellUsMoreOrgTypeArrItem6": "Community Group",
  "tellUsMoreOrgTypeArrItem7": "Others",
  "tellUsMoreSubTitle1": "What is the type of organization?",
  "tellUsMoreSubTitle2": "What is the size of organization?",
  "tellUsMoreTitle": "Tell Us More",
  "textCopiedMessage": "Text Copied!",
  "EmailCopiedMessage": "Email Copied!",
  "thankYouDisplayMessage": "We have received your registration, we will review your information\n and send you a confirmation by email soon.",
  "thankYouDisplayTitle": "Thank you for your registration!",
  "todayTitle": "Today",
  "typeOfOrganizationQuestion": "What is the type of organization?",
  "undoTaskDeleteTitle": "Undo",
  "upcomingTitle": "Upcoming",

  "editClientFormTitle": "Edit Client",
  "editClientFormClientLabel": "Client",
  "editClientFormFirstNameLabel": "Name",
  "editClientFormLastNameLabel": "Last Name",
  "editClientFormPhoneLabel": "Phone",
  "editClientFormEmailLabel": "E-mail",
  "editClientFormCloseDateLabel": "Close Date",
  "editClientFormActiveDateLabel": "Active Date",
  "editClientFormCountryLabel": "Country",
  "editClientFormStateProvinceLabel": "State/Province",
  "editClientFormMunicipalityLabel": "Municipality",
  "editClientFormCityLabel": "City",
  "editClientFormStreetAddressLabel": "Street Address",
  "editClientFormPostalCodeLabel": "Postal Code",
  "editClientFormOrganizationTypeLabel": "Organization Type",
  "editClientFormGovernmentOption": "Government (Regional or Local)",
  "editClientFormCompanyOption": "Company / Business",
  "editClientFormIndustryOption": "Industry",
  "editClientFormNonProfitOption": "Non-profit / Charity",
  "editClientFormEducationalInstitutionOption": "Educational Institution",
  "editClientFormCommunityGroupOption": "Community Group",
  "editClientFormOthersOption": "Others",
  "editClientFormSaveButton": "Save",


  "errorUpdatingTitle": "Error Updating",
  "errorUpdatingMessage": "We were not able to update at this moment"
}
