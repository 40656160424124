import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Typography,
  TextField,
  Select,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import { FormValuesStateAndSetter } from '../../user-profile';
import { mapMenuItems, MapRadioOptions } from './components-mappings-renderers';
import { FormControlFields } from 'pages/user-profile/components/types';
import { UserSettingsDataTypeCreate } from '../../../../react-query-toolkit/state/user-settings-context';
import { transformToLowerCaseAndJoin } from '../utilities/transform-to-lowercase-and-join';
import { BasicJoinedUserSettingsType } from 'pages/user-profile/components/types';
import Grid from '@mui/material/Grid2';

export interface InputParameters {
  formControlInfo: FormControlFields;
  formValuesStateAndSetter: FormValuesStateAndSetter;
  area: string;
  apiDataForThisControlInstance: string | null;
  value: string;
  formattedName: string;
}

export interface TextInputParameters extends InputParameters {
  changeHandler: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

export interface SelectInputParameters extends InputParameters {
  changeHandler: (e: SelectChangeEvent<string>) => void;
}

export interface RadioInputParameters extends InputParameters {
  changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Control = (props: {
  formControlInfo: FormControlFields;
  formValuesStateAndSetter: FormValuesStateAndSetter;
  area: string;
  fetchedUserSettings: BasicJoinedUserSettingsType;
}) => {
  const {
    formControlInfo,
    area,
    fetchedUserSettings,
    formValuesStateAndSetter,
  } = props;

  const { name } = formControlInfo;

  const { formInputValues: formValues, setFormInputValues: setFormValues } =
    formValuesStateAndSetter;

  const apiDataForThisControlInstance =
    fetchedUserSettings[
      formControlInfo.name.toLowerCase() as keyof BasicJoinedUserSettingsType
    ] ?? null;

  const formattedName = transformToLowerCaseAndJoin(name);

  const defaultvalue =
    formValues[formattedName as keyof UserSettingsDataTypeCreate];
  //console.log("df",defaultvalue,apiDataForThisControlInstance);

  const [value, setValue] = useState<string>(
    apiDataForThisControlInstance ?? defaultvalue,
  );

  useEffect(() => {}, [
    fetchedUserSettings[
      formControlInfo.name.toLowerCase() as keyof BasicJoinedUserSettingsType
    ],
  ]);

  useEffect(() => {
    apiDataForThisControlInstance && setValue(apiDataForThisControlInstance);
  }, [apiDataForThisControlInstance]);

  useEffect(() => {
    apiDataForThisControlInstance && setValue(apiDataForThisControlInstance);
  }, [apiDataForThisControlInstance]);

  const handleRadioSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    setFormValues({
      ...formValues,
      [formattedName]: newValue,
    });
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | { value: string }>,
  ) => {
    const newValue = event.target.value;
    setValue(newValue);
    setFormValues({
      ...formValues,
      [formattedName]: newValue,
    });
  };

  const handleSelection = (e: SelectChangeEvent<string>) => {
    const newValue = e.target.value;
    setValue(newValue);
    setFormValues({
      ...formValues,
      [formattedName]: newValue,
    });
  };

  const inputParameters: InputParameters = {
    formControlInfo,
    formValuesStateAndSetter,
    area,
    apiDataForThisControlInstance,
    value,
    formattedName,
  };

  const isInputTypeSelect = formControlInfo.controlType === 'select';
  const isInputTypeRadio = formControlInfo.inputType === 'radio';

  return (
    <Grid
      size={{
        xs: 'grow',
        xl: area === 'profile' ? (isInputTypeSelect ? 12 : 6) : 12,
      }}
    >
      {isInputTypeSelect ? (
        <SelectElement
          parameters={{ ...inputParameters, changeHandler: handleSelection }}
        />
      ) : isInputTypeRadio ? (
        <RadioInputElement
          parameters={{
            ...inputParameters,
            changeHandler: handleRadioSelection,
          }}
        />
      ) : (
        <TextInputElement
          parameters={{ ...inputParameters, changeHandler: handleInputChange }}
        />
      )}
    </Grid>
  );
};

const SelectElement = (props: { parameters: SelectInputParameters }) => {
  const {
    formControlInfo,
    formValuesStateAndSetter,
    area,
    value,
    formattedName,
    changeHandler,
  } = props.parameters;

  const { displayedName } = formControlInfo;

  const labelId = `${formattedName}-label`;
  const isRequired = formControlInfo.isRequired;

  return (
    <FormControl fullWidth required={isRequired}>
      <InputLabel id={labelId}>{displayedName}</InputLabel>
      <Select
        labelId={labelId}
        id={formattedName}
        value={value}
        label={displayedName}
        onChange={(e) => changeHandler(e)}
        fullWidth
      >
        {mapMenuItems({ formControlInfo, formValuesStateAndSetter, area })}
      </Select>
    </FormControl>
  );
};

const RadioInputElement = (props: { parameters: RadioInputParameters }) => {
  const {
    formControlInfo,
    formValuesStateAndSetter,
    area,
    value,
    changeHandler,
  } = props.parameters;

  const { displayedName, name } = formControlInfo;
  const isRequired = formControlInfo.isRequired;

  return (
    <FormControl sx={{ gap: 3 }} required={isRequired}>
      <FormLabel
        id="number-formats-group-label"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle1" color={'primary.dark'}>
          {displayedName}
        </Typography>
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="number-formats-group-label"
        value={value}
        onChange={(e) => changeHandler(e)}
        sx={{
          gap: 4,
          justifyContent: { xs: 'flex-end', lg: 'flex-start' },
          flexWrap: { xs: 'initial', lg: 'nowrap' },
          marginLeft: { md: 'auto', lg: '0' },
          flexDirection: {
            xs: name === 'numberformat' ? 'column' : 'row',
            md: 'row',
          },
        }}
      >
        <MapRadioOptions
          formControlInfo={formControlInfo}
          formValuesStateAndSetter={formValuesStateAndSetter}
          area={area}
        />
      </RadioGroup>
    </FormControl>
  );
};

const TextInputElement = (props: { parameters: TextInputParameters }) => {
  const { formControlInfo, value, formattedName, changeHandler } =
    props.parameters;

  const { displayedName, inputType } = formControlInfo;
  const isRequired = formControlInfo.isRequired;

  return (
    <TextField
      label={displayedName}
      value={value}
      onChange={(e) => changeHandler(e)}
      InputLabelProps={{ htmlFor: formattedName }}
      fullWidth
      autoComplete="off"
      color="primary"
      helperText=""
      required={isRequired}
      InputProps={{
        type: inputType,
      }}
      id={formattedName}
    />
  );
};
