import React, { useEffect, useState } from 'react';
import { PageWrapper } from 'components';
import { Container } from 'pages/dashboard/styled';
import { useTranslation } from 'react-i18next';
import { ProjectTable } from 'pages/projects/components/project-table';
import { DateLabel, LabelText } from 'pages/projects/components/styled';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { ProjectLogoDefault } from 'icons/project-logo-default';
import { LabelCalendarIcon } from 'icons/label-calendar-icon';
import { epochToDate } from 'utils/epoch-to-date';
import { useNavigate } from 'react-router-dom';
import { ProjectItemOverview } from 'pages/projects/components/project-item-overview';
import { Box, Button, Typography } from '@mui/material';
import { PageTitle } from 'components/atoms/page-title/page-title';
import { ProjectComments } from './project-comments';
import { useProjectsDataContext } from '../../../react-query-toolkit/state/projects-context';
import LoadingComponent from 'components/loading-icon-component';
import { useParams } from 'react-router-dom';
import { useClientDataContext } from '../../../react-query-toolkit/state/client-context';
import { findClientByIdInSystemPLResponse } from 'utils/System-pl-data-util';
import { NewClientDataType } from 'pages/clients/api/get-clients';
import Grid from '@mui/material/Grid2';
import { useUserDataContext } from '../../../react-query-toolkit/state/user-context';
import { findUserById } from 'utils/find-user-by-id';

const ProjectItem = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const [commentsOpen, setCommentsOpen] = useState(false);
  const [stepComment, setStepComment] = useState('set-up');
  const [formStep, setFormStep] = useState(true);
  const [stepActive, setStepActive] = useState(true);

  const { setCurrentProjectId, handleGetProject, isGetProjectsLoading } =
    useProjectsDataContext();
  const { handleGetAllUsers, isGetUserLoading } = useUserDataContext();
  const { handleGetClients, clientListIsLoading } = useClientDataContext();

  const { projectId } = useParams();
  useEffect(() => {
    if (projectId) {
      setCurrentProjectId(projectId);
    }
  }, [projectId, setCurrentProjectId]); // Ensure `setCurrentProjectId` is stable or memoized

  const rawProjectData = handleGetProject() || {};
  const rawUserData = handleGetAllUsers() || [];

  //Getting the client Name and Profile picture
  const rawClientData = handleGetClients() || [];

  const clientData: NewClientDataType | null = rawClientData
    ? findClientByIdInSystemPLResponse(
        rawClientData,
        rawProjectData.projectClientId,
      )
    : null;

  const projectDueDateValue = epochToDate(
    rawProjectData.projectDueDate,
    'MMLongDD,YYYY',
  );

  const clientName = clientListIsLoading
    ? translate?.('ProjectLoadingMessage')
    : clientData?.clientName ?? translate?.('ProjectLoadingMessage');
  const consultant =
    rawProjectData.accountManager && Array.isArray(rawUserData)
      ? findUserById(rawProjectData.accountManager, rawUserData)
      : null;

  const consultantName = consultant
    ? `${consultant?.firstname ?? ''} ${consultant?.lastname ?? ''}`.trim()
    : translate?.('unknownManager') || translate?.('unknownManager');

  const projectDueDate = projectDueDateValue ?? translate?.('unknownDueDate');
  const toggleCommentsDrawer = () => {
    setCommentsOpen(!commentsOpen);
  };

  let componentToRender;
  switch (formStep) {
    case true:
      componentToRender = (
        <ProjectTable
          // projectStepData={projectStepsData}
          setCommentsOpen={setCommentsOpen}
          setStepComment={setStepComment}
        />
      );
      break;
    case false:
      componentToRender = <ProjectItemOverview />;
      break;
    default:
      componentToRender = (
        <ProjectTable
          // projectStepData={projectStepsData}
          setCommentsOpen={setCommentsOpen}
          setStepComment={setStepComment}
        />
      );
  }

  if (
    isGetUserLoading ||
    clientListIsLoading ||
    isGetProjectsLoading ||
    !rawProjectData
  ) {
    return (
      <Box
        width={'100%'}
        height={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box width={'100px'} height={'100px'}>
          <LoadingComponent size={'100'} />
        </Box>
      </Box>
    );
  }
  return (
    <>
      {isGetUserLoading ||
      clientListIsLoading ||
      isGetProjectsLoading ||
      !rawProjectData ? (
        <Box
          width={'100%'}
          height={'100%'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Box width={'100px'} height={'100px'}>
            <LoadingComponent size={'100'} />
          </Box>
        </Box>
      ) : rawProjectData.projectSteps ? (
        <>
          <ProjectComments
            open={commentsOpen}
            stepComment={stepComment}
            toggleDrawer={toggleCommentsDrawer}
          />
          <PageWrapper>
            <Box>
              <Container width="100%" margin="0">
                <PageTitle
                  title={`${translate?.('headerSingleProject')} ${rawProjectData.projectPeriod} ${rawProjectData.projectType} ${rawProjectData.projectJurisdiction}`}
                />
              </Container>
              <Box bgcolor="bg.box" marginTop={7} p={6} sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  size={12}
                  display={'flex'}
                  justifyContent="space-between"
                >
                  <Grid
                    container
                    size={{ xs: 12, md: 'auto' }}
                    spacing={6}
                    marginBottom={{ xs: 0, md: 6 }}
                    sx={{
                      flexWrap: { xs: 'wrap', md: 'nowrap' },
                    }}
                  >
                    <Grid size={{ xs: 12, md: 1.5 }}>
                      <Box
                        height={'100%'}
                        justifyContent={'center'}
                        width={{ sm: '33px', md: '77px' }}
                        margin={0}
                      >
                        <ProjectLogoDefault />
                      </Box>
                    </Grid>
                    <Grid
                      size={{ xs: 12, md: 'auto' }}
                      marginTop={{ xs: 0, md: 0 }}
                      marginLeft={{ md: 6, xs: 0 }}
                    >
                      <Grid display={{ xs: 'block', md: 'flex' }}>
                        <Typography
                          noWrap
                          marginRight={1}
                          variant={'subtitle2'}
                          color={'primary.dark'}
                        >
                          {translate?.('projectNameTitle')}
                        </Typography>
                        <Typography
                          noWrap
                          variant={'body2'}
                          color={'text.primary'}
                        >{`${rawProjectData.projectPeriod} ${rawProjectData.projectType} ${rawProjectData.projectJurisdiction}`}</Typography>
                      </Grid>
                      <Grid display={{ xs: 'block', md: 'flex' }}>
                        <Typography
                          noWrap
                          marginRight={1}
                          variant={'subtitle2'}
                          color={'primary.dark'}
                        >
                          {translate?.('projectClientTitle')}
                        </Typography>
                        <Typography
                          noWrap
                          variant={'body2'}
                          color={'text.primary'}
                        >
                          {clientName}
                        </Typography>
                      </Grid>
                      <Grid display={{ xs: 'block', md: 'flex' }}>
                        <Typography
                          noWrap
                          marginRight={1}
                          variant={'subtitle2'}
                          color={'primary.dark'}
                        >
                          {translate?.('projectConsultantTitle')}
                        </Typography>
                        <Typography
                          noWrap
                          variant={'body2'}
                          color={'text.primary'}
                        >
                          {consultantName}
                        </Typography>
                      </Grid>
                      <Grid display={{ xs: 'block', md: 'flex' }}>
                        <Typography
                          noWrap
                          marginRight={1}
                          variant={'subtitle2'}
                          color={'primary.dark'}
                        >
                          {translate?.('projectDueDateTitle')}
                        </Typography>
                        <DateLabel>
                          <LabelCalendarIcon />
                          <LabelText>{projectDueDate}</LabelText>
                        </DateLabel>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    marginTop={{ xs: 5, md: 0 }}
                    size={{ xs: 12, md: 'auto' }}
                    container
                    justifyContent="flex-end"
                    marginBottom={6}
                    spacing={4}
                  >
                    <Grid
                      size={{ xs: 12 }}
                      container
                      justifyContent={{ xs: 'flex-start', md: 'space-between' }}
                      alignContent={'start'}
                      alignItems="center"
                      spacing={4}
                      sx={{
                        flexWrap: { xs: 'wrap', md: 'nowrap' },
                      }}
                    >
                      <Grid size={{ xs: 12, md: 6 }}>
                        <Button
                          variant={'outlined'}
                          sx={{ display: 'flex', alignItems: 'center' }}
                          startIcon={
                            rawProjectData.previousProject ? (
                              <ArrowLeftIcon
                                onClick={() =>
                                  navigate(
                                    `/my-projects/project-id/${rawProjectData.previousProject}`,
                                  )
                                }
                              />
                            ) : null
                          }
                          endIcon={
                            rawProjectData.nextProject ? (
                              <ArrowRightIcon
                                onClick={() =>
                                  navigate(
                                    `/my-projects/project-id/${rawProjectData.nextProject}`,
                                  )
                                }
                              />
                            ) : null
                          }
                        >
                          2024
                        </Button>
                      </Grid>
                      <Grid size={{ xs: 'auto', md: 'auto' }}>
                        <Button
                          variant={'contained'}
                          onClick={() => {
                            setFormStep(!formStep);
                            setStepActive(!stepActive);
                          }}
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          {!stepActive
                            ? translate?.('headerProjectSteps')
                            : translate?.('projectOverviewButtonText')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {componentToRender}
              </Box>
            </Box>
          </PageWrapper>
        </>
      ) : (
        <>ERROR</>
      )}
    </>
  );
};

export default ProjectItem;
