{
  "description":{
    "default": "Notification",
    "receptionDate": "Reçu : {{createdAt}}",
    "changedProjectStepDueDate": "La date d’échéance de l’étape de {{name}} a été modifiée.",
    "changedProjectDueDate": "La date d’échéance du projet a été modifiée pour le {{duedate}}.",
    "createdNewTask": "Une nouvelle tâche \"{{name}}\" a été créée",
    "changedProjectStepStatus": "Le statut de l'étape \"{{name}}\" a été modifié pour {{status}}.",
    "noDetailsAvailable": "Aucun détail supplémentaire disponible."
  },
  "titles": {
    "systemNotices": "Notifications système",
    "openProject": "Projets Ouverts",
    "reportReview": "Revue du rapport"
  }
}
