import ReactCalendar, { ViewCallback } from 'react-calendar';
import React, { SetStateAction, useEffect, useState } from 'react';
import { CardHeader } from 'pages/dashboard/styled';
import { Heading, Hoverable } from 'components';
import { isBefore, isSameDay, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { NewTaskDataType } from 'pages/task-manager/api/task-type';
import {
  RightSideContainer,
  CalendarContainer,
  PriorityDot,
  Dot,
  DateCellWrapper,
  ModalContentContainer,
  Modal,
  ModalHeader,
  CloseButton,
  ModalText,
  SeeAllText,
  ModalContentContainerInner,
} from 'pages/dashboard/components/calendar/styled';
import {
  ArrowLeft,
  ArrowRight,
} from 'pages/dashboard/components/calendar/icons';
import { MobileCalendarContainer } from 'pages/calendar/components/styled';
import { useTaskDataContext } from '../../../react-query-toolkit/state/tasks-context';
import { Project } from '../../../react-query-toolkit/state/types';
import { EventInput } from '@fullcalendar/core';

const monthAndYearToString = (year: number, month: number) => {
  const DEFAULT_DATE = 1;
  const date = new Date(Date.UTC(year, month, DEFAULT_DATE));

  return date.toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });
};

export const MobileCalendar = ({
  rawTaskData,
  userProjects,
}: {
  rawTaskData: NewTaskDataType[];
  userProjects: Project[];
}) => {
  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showAll, setShowAll] = useState(false);
  const [selectedDayInMonth, setSelectedDayInMonth] = useState(date.getDate());

  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { setTaskIdSelected } = useTaskDataContext();

  const monthAndYearToDisplay = monthAndYearToString(
    date.getFullYear(),
    date.getMonth() + 1,
  ).toUpperCase();

  const handleClickDay = (args: Date) => {
    handleClick(args);
  };

  const handleActiveDateChange: ViewCallback = ({ activeStartDate }) => {
    setDate(activeStartDate);
  };

  function transformTaskData(taskData: NewTaskDataType[]) {
    return taskData
      .filter((task) => !task.isCompleted)
      .map((task) => ({
        date: task.dueDate,
        title: task.taskName,
        priority: task.priority,
        eventId: task.taskId,
        eventType: 'task',
      }));
  }

  function transformProjectData(projectData: Project[]): EventInput[] {
    return projectData.map((project) => ({
      eventId: project.id,
      date: project.duedate,
      title: `${project.period} ${project.mandate} ${project.jurisdiction}`,
      eventType: 'project',
      priority: 'project',
      description: 'This is a project description',
    }));
  }

  const dates = [
    ...transformTaskData(rawTaskData),
    ...transformProjectData(userProjects),
  ];

  // const dates = transformTaskData(rawTaskData);

  const [modal, setModal] = useState<{
    visible: boolean;
    content: {
      title: string;
      priority: string;
      eventId: string;
      eventType: string;
    }[];
    remainingCount: number;
  }>({
    visible: false,
    content: [],
    remainingCount: 0,
  });

  const today = new Date();

  const openModal = (
    events: {
      title: string;
      priority: string;
      eventId: string;
      eventType: string;
    }[],
  ) => {
    const displayedEvents = events.slice(0, 3);
    const remainingCount = events.length - 3;
    setModal({
      visible: true,
      content: displayedEvents,
      remainingCount: remainingCount > 0 ? remainingCount : 0,
    });
    setShowAll(false);
  };

  const closeModal = () => {
    setModal({ visible: false, content: [], remainingCount: 0 });
    setShowAll(false);
    setDate(
      new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDayInMonth,
      ),
    );
  };

  const handleClick = (date: Date) => {
    setSelectedDayInMonth(parseInt(dayjs(date).format('DD')));
    const dateEvents = dates.filter((d) => isSameDay(parseISO(d.date), date));
    if (dateEvents.length > 0) {
      const events = dateEvents.map((event) => ({
        title: event.title,
        priority: event.priority,
        eventId: event.eventId!,
        eventType: event.eventType,
      }));
      setSelectedDate(date);
      openModal(events);
    } else {
      setSelectedDate(date);
    }
  };

  const renderTileContent = ({ date }: { date: Date }) => {
    const dateEvents = dates.filter((d) => isSameDay(parseISO(d.date), date));
    const past = isBefore(date, today);
    const isTodayDue = isSameDay(date, today);
    if (dateEvents.length > 0) {
      return (
        <DateCellWrapper hasEvents onClick={() => handleClick(date)}>
          <Dot
            isTodayDue={isTodayDue}
            past={past}
            onClick={() => handleClick(date)}
          />
        </DateCellWrapper>
      );
    } else {
      return (
        <DateCellWrapper onClick={() => handleClick(date)}>
          {/* No dot for this day */}
        </DateCellWrapper>
      );
    }
  };

  const handleSeeAllClick = () => {
    setModal((prevModal) => ({
      ...prevModal,
      content: dates
        .filter((d) => isSameDay(parseISO(d.date), selectedDate))
        .map((event) => ({
          title: event.title,
          priority: event.priority,
          eventId: event.eventId!,
          eventType: event.eventType!,
        })),
    }));
    setShowAll(true);
  };

  const handleArrowLeftClick = () => {
    const newDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    setDate(newDate);
  };

  const handleArrowRightClick = () => {
    const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    setDate(newDate);
  };

  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [selectedDate]);

  function handleEventClick(eventType: string, eventId: string) {
    if (eventType === 'task') {
      setTaskIdSelected(eventId);
      navigate('/task-manager');
    }
    if (eventType === 'project') {
      navigate(`/my-projects/project-id/${eventId}`);
    }
  }

  return (
    <MobileCalendarContainer>
      <RightSideContainer className="calendar-temp">
        <CalendarContainer key={key}>
          <CardHeader onClick={() => navigate('/calendar')}>
            <Heading level={3} color="secondaryBlue">
              {monthAndYearToDisplay}
            </Heading>
            <div style={{ gap: '30px', display: 'flex' }}>
              <Hoverable onClick={() => handleArrowLeftClick()}>
                <ArrowLeft />
              </Hoverable>
              <Hoverable onClick={() => handleArrowRightClick()}>
                <ArrowRight />
              </Hoverable>
            </div>
          </CardHeader>
          <ReactCalendar
            tileContent={renderTileContent}
            formatShortWeekday={(_locale, date) =>
              [
                `${translate?.('calendarShortWeekDaySunday')}`,
                `${translate?.('calendarShortWeekDayMonday')}`,
                `${translate?.('calendarShortWeekDayTuesday')}`,
                `${translate?.('calendarShortWeekDayWednesday')}`,
                `${translate?.('calendarShortWeekDayThursday')}`,
                `${translate?.('calendarShortWeekDayFriday')}`,
                `${translate?.('calendarShortWeekDaySaturday')}`,
              ][date.getDay()]
            }
            value={date}
            onActiveStartDateChange={handleActiveDateChange}
            onChange={(value: SetStateAction<Date>) => {
              setDate(value);
            }}
            onClickDay={handleClickDay}
          />
          {modal.visible && (
            <Modal>
              <ModalHeader>
                <h4>{dayjs(date).format('DD')}</h4>
                <CloseButton onClick={closeModal}>×</CloseButton>
              </ModalHeader>
              <ModalContentContainer>
                {modal.content.map((event, index) => (
                  <ModalContentContainerInner
                    display={'flex'}
                    key={index}
                    onClick={() => {
                      handleEventClick(event.eventType, event.eventId);
                    }}
                  >
                    <PriorityDot priority={event.priority} />
                    <ModalText>{event.title}</ModalText>
                    <br />
                  </ModalContentContainerInner>
                ))}
                {!showAll && modal.remainingCount > 0 && (
                  <>
                    <SeeAllText onClick={handleSeeAllClick}>
                      {modal.remainingCount} more
                    </SeeAllText>
                  </>
                )}
                {showAll ? (
                  <SeeAllText onClick={() => navigate('/task-manager')}>
                    See all
                  </SeeAllText>
                ) : null}
                {modal.remainingCount === 0 && (
                  <>
                    <SeeAllText onClick={() => navigate('/task-manager')}>
                      See all
                    </SeeAllText>
                  </>
                )}
              </ModalContentContainer>
            </Modal>
          )}
        </CalendarContainer>
      </RightSideContainer>
    </MobileCalendarContainer>
  );
};
