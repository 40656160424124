import { Checkbox } from '@mui/material';
import CircleChecked from '@mui/icons-material/CheckCircleOutline';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { DisplayTable } from '../../clients/components/clients-table';
import {
  DisplayTableTaskCell,
  FlagPriorityHandlerContainer,
} from '../../clients/components/clients-table/styled';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ColumnDef, Row, Table } from '@tanstack/react-table';
import { NewTaskDataType } from 'pages/task-manager/api/task-type';
import { DataVisibilityType, NewDataType } from 'pages/clients/api/get-clients';
import TaskTableSubComponent from 'pages/task-manager/components/task-table-sub-component';
import {
  AddTaskContainer,
  CellContainer,
  ClientIconContainer,
  ClientInfoContainer,
  DescriptionIconContainer,
  HeaderContainer,
  HeaderTitle,
  HeaderTitleButton,
  HeaderTitleButtonsContainer,
  ModalActionText,
  ModalText,
  Spacer,
  TableCellTextContainer,
  TableContainer,
  TaskHeaderContainer,
  TitleContainer,
  TodayTaskContainer,
  UndoContainer,
} from 'pages/task-manager/components/styled';
import AddTask from 'pages/task-manager/components/AddTask';
import { Calendar, DeleteIcon } from 'icons';
import { flagPriorityHandler } from 'pages/task-manager/components/utils';
import { colors } from 'utils/colors';
import { useTranslation } from 'react-i18next';
import { useIsWindowWidthMobile } from 'utils/hooks/useIsWindowWidthMobile';
import { useTaskDataContext } from '../../../react-query-toolkit/state/tasks-context';
import { EditIcon } from 'icons/edit-icon';
import { DescriptionTableIcon } from 'icons/description-table-icon';
import { MD } from 'utils/breakpoints';
import { getWindowDimensions } from 'utils/window-utils';
import LoadingComponent from 'components/loading-icon-component';
import { formatTaskData } from 'pages/task-manager/api/format-task-data';
import { ClientIcon } from 'icons/client-icon';
import { findClientNameById } from 'utils/find-client-name-by-id';
import { CopyToClipboardTruncated } from 'utils/copy-to-clipboard-truncated';
import { CopyToClipBoard } from 'utils/copy-to-clipboard';
import { useClientDataContext } from '../../../react-query-toolkit/state/client-context';
import { DateTime } from 'luxon';

export interface todayTaskType {
  setSelectedData?: Dispatch<SetStateAction<NewTaskDataType | null>>;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  setModalType: Dispatch<SetStateAction<string>>;
  completedTabActive?: boolean;
  setCompletedTabActive?: Dispatch<SetStateAction<boolean>>;
  setGlobalFilter: Dispatch<SetStateAction<string>>;
  globalFilter: string;
}

export const CompletedTasks = (props: todayTaskType) => {
  const {
    handleGetTasks,
    tasksFetchingError,
    tasksListIsLoading,
    handleDeleteTask,
    handleCreateTask,
    handleUpdateTask,
  } = useTaskDataContext();
  const { handleGetClients } = useClientDataContext();

  const clientData = handleGetClients() || [];
  const rawTaskData = handleGetTasks() || [];

  let taskData: NewDataType[];
  if (rawTaskData.length > 0 || clientData) {
    taskData = filterCompletedData(formatTaskData(rawTaskData, clientData));
  }
  function filterCompletedData(data: NewTaskDataType[]) {
    return data.filter((item) => item.isCompleted);
  }

  const [deletedTask, setDeletedTask] = useState<NewTaskDataType | null>(null);
  const { isMobileWidth, windowSizeWidth } = useIsWindowWidthMobile();
  const [windowSize, setWindowSize] = useState(getWindowDimensions());
  const [isTableWidth, setIsTableWidth] = useState<boolean>(
    windowSize.width > MD,
  );
  const { t: translate } = useTranslation();

  const [columnsVisibility, setColumnsVisibility] =
    useState<DataVisibilityType>({
      priority: !isMobileWidth,
      notes: isTableWidth,
      dueDate: !isMobileWidth,
      taskId: false,
      clientName: false,
      clientId: isTableWidth,
    });

  const handleResize = () => {
    setWindowSize(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [undoTimer, setUndoTimer] = useState<NodeJS.Timeout | null>(null);

  const columns: ColumnDef<NewTaskDataType>[] = useMemo(
    () => [
      {
        header: () => (
          <TaskHeaderContainer>{translate?.('headerTask')}</TaskHeaderContainer>
        ),
        accessorKey: 'taskName',
        size: 1,
        minSize: 1,
        maxSize: 20,
        cell: ({ row }) => {
          useEffect(() => {
            row.toggleSelected(true);
          }, []);

          const handleCheckboxChange = async () => {
            row.toggleSelected();
            handleUpdateTask(
              row.original.taskId!,
              row.original.taskName,
              row.original.priority,
              !row.original.isCompleted,
              row.original.dueDate,
              row.original.notes,
              row.original.clientId,
            );
          };

          return (
            <DisplayTableTaskCell
              onClick={row.getToggleExpandedHandler()}
              // rowIsSelected={row.getIsSelected()}
              // Todo - Verify this can be deleted as I cannot test because API was changed.
            >
              <Checkbox
                checked={row.getIsSelected()}
                indeterminate={row.getIsSomeSelected()}
                onChange={handleCheckboxChange}
                sx={{
                  color: colors.altGreen,
                  '&.Mui-checked': {
                    color: colors.altGreen,
                  },
                }}
                icon={<CircleUnchecked />}
                checkedIcon={<CircleChecked />}
                size="medium"
              />
              <CopyToClipboardTruncated copyText={row.original.taskName}>
                {row.original.taskName}
              </CopyToClipboardTruncated>
            </DisplayTableTaskCell>
          );
        },
      },
      {
        header: '',
        accessorKey: 'notes',
        size: 1,
        maxSize: 5,
        enableSorting: false,
        cell: ({ row }) => (
          <DescriptionIconContainer>
            <CopyToClipBoard
              copyText={row.original.notes}
              cursorPointer
              onClick={() => row.getToggleExpandedHandler()}
            >
              <DescriptionTableIcon />
            </CopyToClipBoard>
          </DescriptionIconContainer>
        ),
      },
      {
        header: 'Client',
        accessorKey: 'clientId',
        size: 100,
        cell: ({ row }) => {
          return (
            <ClientInfoContainer>
              <ClientIconContainer>
                <ClientIcon />
              </ClientIconContainer>{' '}
              {clientData ? (
                <CopyToClipboardTruncated
                  copyText={findClientNameById(
                    row.original.clientId,
                    clientData,
                  )}
                >
                  {findClientNameById(row.original.clientId, clientData)}
                </CopyToClipboardTruncated>
              ) : null}
            </ClientInfoContainer>
          );
        },
      },
      {
        header: `${translate?.('headerPriority')}`,
        accessorKey: 'priority',
        cell: ({ row }) => (
          <>
            <FlagPriorityHandlerContainer
              onClick={row.getToggleExpandedHandler()}
              onKeyDown={row.getToggleExpandedHandler()}
            >
              <CellContainer>
                {flagPriorityHandler(row.original.priority)}
                <TableCellTextContainer>
                  {row.original.priority}
                </TableCellTextContainer>
              </CellContainer>
            </FlagPriorityHandlerContainer>
          </>
        ),
        size: 1,
      },
      {
        header: `${translate?.('headerDueDate')}`,
        accessorKey: 'dueDate',
        size: 50,
        cell: ({ row }) => (
          <CellContainer
            tabIndex={0}
            role="button"
            onClick={row.getToggleExpandedHandler()}
            onKeyDown={row.getToggleExpandedHandler()}
          >
            <Calendar
              width={14}
              height={14}
              color={colors.lighterSecondaryBlue}
            />
            <TableCellTextContainer>
              {DateTime.fromISO(row.original.dueDate).toLocaleString(
                DateTime.DATE_FULL,
              )}
            </TableCellTextContainer>
          </CellContainer>
        ),
      },
      {
        header: `${translate?.('headerEdit')}`,
        size: 60,
        cell: ({
          table,
          row,
        }: {
          table: Table<NewTaskDataType>;
          row: Row<NewTaskDataType>;
        }) => (
          <DisplayTableTaskCell
            tabIndex={0}
            role="button"
            onClick={row.getToggleExpandedHandler()}
            onKeyDown={row.getToggleExpandedHandler()}
          >
            <button
              onKeyDown={() => {
                props.setSelectedData && props.setSelectedData(row.original);
                props.setModalIsOpen(true);
                props.setModalType('update');
              }}
              onClick={() => {
                props.setSelectedData && props.setSelectedData(row.original);
                props.setModalIsOpen(true);
                props.setModalType('update');
              }}
            >
              <EditIcon />
            </button>
            <button
              onKeyDown={() => handlerDelete(table, row)}
              {...{
                style: { cursor: 'pointer' },
              }}
              onClick={() => handlerDelete(table, row)}
              {...{
                style: { cursor: 'pointer' },
              }}
            >
              <DeleteIcon />
            </button>
          </DisplayTableTaskCell>
        ),
      },
      {
        header: 'clientName', // This Column is hidden by default but it's used to search by clientName
        accessorKey: 'clientName',
        size: 0,
      },
      {
        header: 'taskId', // This Column is hidden by default but it's used to search by taskID
        accessorKey: 'taskId',
        size: 0,
      },
    ],
    [],
  );

  const undoCompletedTask = () => {
    ///Here send request to Server to change task to isCompleted =false; Get response and if good then continue
    if (deletedTask) {
      handleCreateTask(
        deletedTask.taskName,
        deletedTask.priority,
        deletedTask.dueDate,
        deletedTask.notes,
        deletedTask.clientId,
      );
      setDeletedTask(null);
      clearTimeout(undoTimer!);
    }
  };

  const handlerDelete = (
    table: Table<NewTaskDataType>,
    row: Row<NewTaskDataType>,
  ) => {
    // Clear any existing undo timers
    clearTimeout(undoTimer!);
    setDeletedTask(null);

    // Update the task as completed, and delete it from the backend
    row.original.isCompleted = !row.original.isCompleted;
    handleDeleteTask(row.original.taskId!);

    // Set the task to be deleted and initiate the undo timer
    setDeletedTask(row.original);

    // Set up an undo timer
    const timer = setTimeout(() => {
      // If timer expires, clear the deleted task
      setDeletedTask(null);
    }, 5000);
    setUndoTimer(timer);

    // Reset any expanded rows if needed without affecting row selection
    setTimeout(() => {
      table.resetExpanded(true);
    });
  };

  useEffect(() => {
    return () => {
      // Clear the undo timer when component unmounts
      clearTimeout(undoTimer!);
    };
  }, [undoTimer]);

  const addTaskHandler = async () => {
    const date = DateTime.now().toISO();

    // Convert the Date object to 'YYYY-MM-DD' format
    //const formattedTodayDate = DateTime.fromISO(date).toISODate();
    props.setModalType('create');
    props.setModalIsOpen(true);
    props.setSelectedData &&
      props.setSelectedData({
        taskName: '',
        priority: 'Medium',
        notes: '',
        dueDate: `${date}`,
        isCompleted: false,
        clientId: '',
      });
  };

  useEffect(() => {
    setIsTableWidth(windowSize.width >= MD);
    setColumnsVisibility({
      priority: !isMobileWidth,
      notes: isTableWidth,
      dueDate: !isMobileWidth,
      taskId: false,
      clientName: false,
      clientId: isTableWidth,
    });
    return () => {};
  }, [windowSizeWidth, windowSize.width]);

  const displayTableNoItemsMessage = translate?.('displayTableNoItemsMessage');

  return (
    <>
      <TodayTaskContainer>
        {deletedTask ? (
          <UndoContainer>
            <ModalText>{translate?.('taskCompletedMessage')}</ModalText>

            <ModalActionText
              onClick={() => {
                undoCompletedTask();
              }}
            >
              {translate?.('undoTaskDeleteTitle')}
            </ModalActionText>
          </UndoContainer>
        ) : null}
        <HeaderContainer>
          <TitleContainer>
            <HeaderTitle>{`${translate?.('completedTitle')}`}</HeaderTitle>{' '}
          </TitleContainer>
          <Spacer>
            <HeaderTitleButtonsContainer>
              <HeaderTitleButton
                active={!props.completedTabActive!}
                onClick={() => {
                  props.setCompletedTabActive!(false);
                }}
              >
                {translate?.('activeTitle')}
              </HeaderTitleButton>
              <HeaderTitleButton
                active={props.completedTabActive!}
                onClick={() => {
                  props.setCompletedTabActive!(true);
                }}
              >
                {translate?.('completedTitle')}
              </HeaderTitleButton>
            </HeaderTitleButtonsContainer>
          </Spacer>
        </HeaderContainer>
        <TableContainer>
          <DisplayTable
            data={taskData!}
            columns={columns}
            getRowCanExpand={() => true}
            columnsVisibility={columnsVisibility}
            showFooter={false}
            RenderSubComponent={TaskTableSubComponent}
            loadingItem={
              tasksListIsLoading ? (
                <LoadingComponent size={'50'} />
              ) : tasksFetchingError ? (
                displayTableNoItemsMessage
              ) : undefined
            }
            hideSubComponentFromSizeMd
            setGlobalFilter={props.setGlobalFilter}
            globalFilter={props.globalFilter}
          />
        </TableContainer>
      </TodayTaskContainer>
      <AddTaskContainer>
        <AddTask onClickAction={addTaskHandler} />
      </AddTaskContainer>
    </>
  );
};
