import {
  InnerText,
  ProfileImageIcon,
} from 'pages/clients/components/clients-table/styled';
import { CopyToClipBoard } from 'utils/copy-to-clipboard';
import {useTheme} from "@mui/material/styles";

export const ProfileImage = ({
  profileName,
  profileEmail,
  imageIdx,
  moveValue,
  textToDisplayWhenClicked,
}: {
  profileName: string;
  profileEmail: string;
  imageIdx: string;
  moveValue: string;
  textToDisplayWhenClicked?: string;
}) => {
  // where initials is 2 length strings
  const theme = useTheme();

  const split = profileName.toUpperCase();
  const initials = `${split[0]}`; // First character of each word
  const pickColor = (theme: any, imageIdx: number) => {
    const colors = [
      theme.palette.primary.light, // First color
      theme.palette.primary.main,  // Second color
      theme.palette.primary.dark,  // Third color
    ];

    return colors[(imageIdx - 1) % colors.length]; // Ensure correct order
  };

  const color = pickColor(theme, Number(imageIdx));
  const zIndex = `${100 - +imageIdx}`;

  return (
    <CopyToClipBoard
      textToDisplayWhenClicked={textToDisplayWhenClicked}
      titleTextReplacement={
        <>
          {profileName}
          <br />
          {profileEmail}
        </>
      }
      copyText={profileEmail}
    >
      <ProfileImageIcon
        move={moveValue}
        color={color}
        zIndex={zIndex}
      >
        <InnerText>{initials}</InnerText>
      </ProfileImageIcon>
    </CopyToClipBoard>
  );
};
