import { NewClientDataType } from 'pages/clients/api/get-clients';
import {
  getCity,
  getCountry,
  getMunicipality,
  getState,
} from 'pages/clients/api/get-location';
import Grid from '@mui/material/Grid2';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useClientDataContext } from '../../../../react-query-toolkit/state/client-context';
import { DateSelector } from 'components/date-picker/components/date-selector';
import { DateTime } from 'luxon';
import { useTranslateAll } from '../../../../locales/translation-hooks';
//todo-cg:delete desktop date picker once all attributes have been confirmed x2

export type ClientModalFormProps = {
  data: NewClientDataType[];
  selectedData: NewClientDataType;
  isOpen: boolean;
  toggleModal: Dispatch<SetStateAction<boolean>>;
};

export const ClientModalForm = (props: ClientModalFormProps) => {
  const { data, selectedData, toggleModal, isOpen } = props;
  const [formData, setFormData] = useState<NewClientDataType>(selectedData);

  const handleChange = <K extends keyof NewClientDataType>(
    field: K,
    value: NewClientDataType[K],
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const {
    handleUpdateClient,
    isUpdateClientLoading,
    isUpdateClientSuccess,
    resetUpdateClientQueryValues,
  } = useClientDataContext();

  function closeModalHandler() {
    toggleModal?.(false);
    document.body.style.overflow = 'visible';
  }
  const formDisabled = isUpdateClientLoading || isUpdateClientSuccess;

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    handleUpdateClient(
      formData.clientId,
      formData.clientName,
      formData.firstName,
      formData.lastName,
      formData.phone,
      formData.email,
      formData.country,
      formData.city,
      formData.municipality,
      formData.state,
      formData.activeDate,
      formData.closeDate,
      formData.address,
      formData.postalCode,
      formData.organizationType,
    );
    form.reset();
  };
  const { translateClients } = useTranslateAll(['clients']);

  useEffect(() => {
    if (isUpdateClientSuccess && isOpen) {
      closeModalHandler();
      resetUpdateClientQueryValues();
    }
  }, [isUpdateClientSuccess]);

  const handleClose = () => toggleModal(false);

  const countryOptions = getCountry(data);
  const stateOptions = getState(data);
  const cityOptions = getCity(data);
  const municipalitiesOptions = getMunicipality(data);

  const theme = useTheme();

  const [closeDateValue, setCloseDateValue] = React.useState<string>(
    DateTime.fromISO(formData.closeDate).toISO()!,
  );
  const [activeDateValue, setActiveDateValue] = React.useState<string>(
    DateTime.fromISO(formData.activeDate).toISO()!,
  );

  const [openCloseDate, setOpenCloseDate] = useState(false);
  const [openActiveDate, setOpenActive] = useState(false);
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth={false} // Disable the predefined maxWidth to use custom width
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '648px',
            width: '100%',
            height: '740px',
          },
        }} // Custom maxWidth
      >
        <DialogTitle
          component="div"
          sx={{
            paddingTop: theme.spacing(9),
            px: theme.spacing(10),
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
            <IconButton
              onClick={handleClose}
              sx={{
                p: 0,
                color: theme.palette.textColor.main,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography
            variant="h4"
            color="chevronGray.main"
            sx={{
              color: theme.palette.primary.dark,
              textAlign: 'left',
            }}
          >
            {translateClients('editClient.title')}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            paddingBottom: theme.spacing(0),
            px: theme.spacing(10),
            paddingTop: `${theme.spacing(3)} !important`,
          }}
        >
          <Box component="form" onSubmit={onSubmitForm}>
            <Grid container spacing={6} sx={{ flexGrow: 1 }}>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label={translateClients('editClient.labels.client')}
                  value={formData.clientName}
                  color="primary"
                  onChange={(e) => handleChange('clientName', e.target.value)}
                  sx={{}}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label={translateClients('editClient.labels.firstName')}
                  value={formData.firstName}
                  onChange={(e) => handleChange('firstName', e.target.value)}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label={translateClients('editClient.labels.lastName')}
                  value={formData.lastName}
                  onChange={(e) => handleChange('lastName', e.target.value)}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label={translateClients('editClient.labels.phone')}
                  value={formData.phone}
                  onChange={(e) => handleChange('phone', e.target.value)}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label={translateClients('editClient.labels.email')}
                  value={formData.email}
                  onChange={(e) => handleChange('email', e.target.value)}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                {/*<DesktopDatePicker*/}
                {/*  label={translateClients('editClientFormCloseDateLabel')}*/}
                {/*  open={openCloseDate}*/}
                {/*  onClose={() => setOpenCloseDate(false)}*/}
                {/*  disableOpenPicker*/}
                {/*  slotProps={{*/}
                {/*    textField: {*/}
                {/*      onClick: () => setOpenCloseDate(true),*/}
                {/*    },*/}
                {/*    popper: {*/}
                {/*      sx: {*/}
                {/*        '.MuiPickersArrowSwitcher-button': {*/}
                {/*          color: theme.palette.primary.main,*/}
                {/*        },*/}
                {/*      },*/}
                {/*    },*/}
                {/*  }}*/}
                {/*  value={closeDateValue}*/}
                {/*  onChange={(newValue) => {*/}
                {/*    setCloseDateValue(newValue); // Update the local closeDateValue state*/}
                {/*    handleChange(*/}
                {/*      'closeDate',*/}
                {/*      dayjs(newValue).format('MM-DD-YYYY'),*/}
                {/*    ); // Update formData.closeDate*/}
                {/*  }}*/}
                {/*  sx={{ width: '100%' }}*/}
                {/*/>*/}
                <DateSelector
                  name={translateClients('editClient.labels.closeDate')}
                  dateData={formData.closeDate}
                  dateUpdater={(newValue: string | undefined) => {
                    setCloseDateValue(newValue!); // Update the local closeDateValue state
                    handleChange('closeDate', newValue!); // Update formData.closeDate
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <DateSelector
                  name={translateClients('editClient.labels.activeDate')}
                  dateData={activeDateValue}
                  dateUpdater={(newValue: string | undefined) => {
                    setActiveDateValue(newValue!); // Update the local closeDateValue state
                    handleChange('activeDate', newValue!);
                  }}
                />
                {/*<DesktopDatePicker*/}
                {/*  label={translateClients('editClientFormActiveDateLabel')}*/}
                {/*  open={openActiveDate}*/}
                {/*  onClose={() => setOpenActive(false)}*/}
                {/*  disableOpenPicker*/}
                {/*  slotProps={{*/}
                {/*    textField: {*/}
                {/*      onClick: () => setOpenActive(true),*/}
                {/*    },*/}
                {/*    popper: {*/}
                {/*      sx: {*/}
                {/*        '.MuiPickersArrowSwitcher-button': {*/}
                {/*          color: theme.palette.primary.main,*/}
                {/*        },*/}
                {/*      },*/}
                {/*    },*/}
                {/*  }}*/}
                {/*  value={activeDateValue}*/}
                {/*  onChange={(newValue) => {*/}
                {/*    setActiveDateValue(newValue); // Update the local closeDateValue state*/}
                {/*    handleChange(*/}
                {/*      'activeDate',*/}
                {/*      dayjs(newValue).format('DD-MM-YYYY'),*/}
                {/*    );*/}
                {/*  }}*/}
                {/*  sx={{ width: '100%' }}*/}
                {/*/>*/}
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <FormControl fullWidth>
                  <InputLabel id="country-field-label">
                    {translateClients('editClient.labels.country')}
                  </InputLabel>
                  <Select
                    labelId="country-field-label"
                    id="country-field"
                    input={
                      <OutlinedInput
                        label={translateClients('editClient.labels.country')}
                      />
                    }
                    value={formData.country}
                    onChange={(e) => handleChange('country', e.target.value)}
                  >
                    {countryOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <FormControl fullWidth>
                  <InputLabel id="State-field-label">
                    {translateClients('editClient.labels.stateProvince')}
                  </InputLabel>
                  <Select
                    labelId="State-field-label"
                    id="State-field"
                    input={
                      <OutlinedInput
                        label={translateClients(
                          'editClient.labels.stateProvince',
                        )}
                      />
                    }
                    value={formData.state}
                    onChange={(e) => handleChange('state', e.target.value)}
                  >
                    {stateOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <FormControl fullWidth>
                  <InputLabel id="Municipality-field-label">
                    {translateClients('editClient.labels.municipality')}
                  </InputLabel>
                  <Select
                    labelId="Municipality-field-label"
                    id="Municipality-field"
                    input={
                      <OutlinedInput
                        label={translateClients(
                          'editClient.labels.municipality',
                        )}
                      />
                    }
                    value={formData.municipality}
                    onChange={(e) =>
                      handleChange('municipality', e.target.value)
                    }
                  >
                    {municipalitiesOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <FormControl fullWidth>
                  <InputLabel id="city-field-label">
                    {translateClients('editClient.labels.city')}
                  </InputLabel>
                  <Select
                    labelId="city-field-label"
                    id="city-field"
                    input={
                      <OutlinedInput
                        label={translateClients('editClient.labels.city')}
                      />
                    }
                    value={formData.city}
                    onChange={(e) => handleChange('city', e.target.value)}
                  >
                    {cityOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label={translateClients('editClient.labels.streetAddress')}
                  value={formData.address}
                  onChange={(e) => handleChange('street', e.target.value)}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label={translateClients('editClient.labels.postalCode')}
                  value={formData.postalCode}
                  onChange={(e) => handleChange('postalCode', e.target.value)}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <FormControl fullWidth>
                  <InputLabel id="organization-field-label">
                    {translateClients('editClient.labels.organizationType')}
                  </InputLabel>
                  <Select
                    labelId="organization-field-label"
                    id="organization-field"
                    input={
                      <OutlinedInput
                        label={translateClients(
                          'editClient.labels.organizationType',
                        )}
                      />
                    }
                    value={formData.organizationType}
                    onChange={(e) =>
                      handleChange('organizationType', e.target.value)
                    }
                  >
                    <MenuItem value="goverment">
                      {translateClients('editClient.options.government')}{' '}
                    </MenuItem>
                    <MenuItem value="company">
                      {translateClients('editClient.options.company')}
                    </MenuItem>
                    <MenuItem value="industry">
                      {translateClients('editClient.options.industry')}
                    </MenuItem>
                    <MenuItem value="charity">
                      {translateClients('editClient.options.nonProfit')}
                    </MenuItem>
                    <MenuItem value="educational">
                      {translateClients(
                        'editClient.options.educationalInstitution',
                      )}
                    </MenuItem>
                    <MenuItem value="community">
                      {translateClients('editClient.options.communityGroup')}
                    </MenuItem>
                    <MenuItem value="others">
                      {translateClients('editClient.options.Others')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <DialogActions
              sx={{
                paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(9),
                px: theme.spacing(0),
                justifyContent: 'flex-start',
              }}
            >
              <Button type="submit" variant="contained" disabled={formDisabled}>
                {translateClients('editClient.buttons.save')}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
