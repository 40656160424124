import React, { useEffect, useState } from 'react';
import { HelpIcon, UserIcon } from 'icons';

import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomButton, useNavAndSideBarContext } from 'components';
import useAuth from 'configs/AuthContext';
import { GetUserFromEmail } from 'utils/get-user-from-email';
import { getLocationLabel } from 'components/nav-and-sidebar/sidebar/utils';
import { defaultTransitionTimeAndStyle } from 'utils/styles';
import { Theme } from '@mui/material/styles';
// Icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { AssignmentIcon } from 'icons/assignment-icon';
import { useFaqContext } from '../../../react-query-toolkit/state/faq-context';
import { translateAll, useTranslateAll } from 'locales/translation-hooks';

const { translateMisc } = translateAll(['misc']);

const tabs = {
  get Dashboard() {
    return {
      key: translateMisc('NavBar.navBarNavigationItems.dashboard'),
      Icon: DashboardIcon,
    };
  },
  get 'My Clients'() {
    return {
      key: translateMisc('NavBar.navBarNavigationItems.myClients'),
      Icon: PeopleIcon,
    };
  },
  get 'My Projects'() {
    return {
      key: translateMisc('NavBar.navBarNavigationItems.projects'),
      Icon: AssignmentIcon,
    };
  },
  get Calendar() {
    return {
      key: translateMisc('NavBar.navBarNavigationItems.calendar'),
      Icon: EventIcon,
    };
  },
  get Notifications() {
    return {
      key: translateMisc('NavBar.navBarNavigationItems.notifications'),
      Icon: NotificationsIcon,
    };
  },
  get 'Task Manager'() {
    return { key: translateMisc('NavBar.taskManager'), Icon: TaskAltIcon };
  },
};

type Props = {
  showSideBar: boolean;
};

export const Sidebar: React.FC<Props> = ({ showSideBar }) => {
  const { setShowSideBar } = useNavAndSideBarContext();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const theme: Theme = useTheme();

  const currentUserEmail =
    auth.user?.getSignInUserSession()?.getIdToken().payload.email || '';

  const userName = currentUserEmail
    ? GetUserFromEmail(currentUserEmail)
    : 'Guest';
  const userRole = 'Admin'; // Assuming the role is fixed

  const sidebarStyles = {
    drawer: {
      transform: {
        md: 'none',
        sm: `${showSideBar ? 'translate(0, 0)' : 'translate(-100%, 0)'}`,
        lg: 'none',
      },
      position: { sm: 'fixed', md: 'static', lg: 'static' },
      display: 'flex',
      zIndex: '2',
      height: '100%',
      width: { lg: '237px', sm: '84px' },
      flexShrink: 0,
      transition: `transform ${defaultTransitionTimeAndStyle}`,
      '& .MuiDrawer-paper': {
        width: { lg: '237px', sm: '84px' },
        transition: `transform ${defaultTransitionTimeAndStyle}`,
        transform: {
          md: 'none',
          sm: `${showSideBar ? 'translate(0, 0)' : 'translate(-100%, 0)'}`,
          lg: 'none',
        },
        boxSizing: 'border-box',
      },
    },
    list: {
      gap: 4,
      padding: () => {
        const availableHeight = window.innerHeight; // Or a specific container height
        return availableHeight > 850 ? '50px 20px 0 20px' : '20px 20px 0 20px'; //This is a dynamic padding to match Svend screen size
      },
      justifyContent: { md: 'center', lg: 'auto' },
    },
    listItemButton: {
      gap: 3,
      py: 2,
      borderRadius: 2,
      justifyContent: 'center',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      // This is a workaround to match the design
      width: { xs: '110%', lg: '100%' },
      left: { xs: '-3px', lg: '0px' },
      top: { xs: '10px' },
      position: 'relative',
    },
    listItemIcon: { minWidth: theme.spacing(7), justifyContent: 'center' },
    listItem: {
      py: 2,
      px: 0,
      '& .MuiButtonBase-root': {
        py: 3,
        justifyContent: 'center',
      },
    },
    listItemProfile: {
      '& .MuiButtonBase-root': {
        py: 3,
        justifyContent: { sm: 'center', lg: 'start' },
      },
    },
  };

  const { translateCommon } = useTranslateAll('common');

  const [selectedTab, setSelectedTab] = useState('');
  useEffect(() => {
    const currentTab = getLocationLabel(location.pathname);
    setSelectedTab(currentTab);
  }, [location.pathname]);

  const routeChange = (key: string) => {
    const path = `/${key.replace(/\s+/g, '-').toLowerCase()}`;
    navigate(path);
    setShowSideBar(false);
    setSelectedTab(key); // Update the selected tab state when navigating
  };

  const [buildNumber, setBuildNumber] = useState('');

  useEffect(() => {
    // Fetch the JSON file from the public directory
    fetch('/env.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch build-info.json');
        }
        return response.json();
      })
      .then((data) => {
        setBuildNumber(data.build); // Access the 'build' key from the JSON file
      })
      .catch((error) => {
        console.error('Error fetching build-info.json:', error);
      });
  }, []);

  const { openFaq } = useFaqContext();

  //  Handle FAQ Button Click to Open Modal
  const handleFaqClick = () => {
    let currentPage = 'Unknown Page';

    // If the pathname starts with "/my-projects/", set it to "MyProjectsPage"
    if (location.pathname.startsWith('/my-projects')) {
      currentPage = 'MyProjectsPage';
    } else {
      // Define other static pages
      const staticPageNames: Record<string, string> = {
        '/': 'DashboardPage',
        '/dashboard': 'DashboardPage',
        '/task-manager': 'TaskManagerPage',
        '/calendar': 'CalendarPage',
        '/my-clients': 'MyClientsPage',
        '/notifications': 'NotificationsPage',
      };

      currentPage = staticPageNames[location.pathname] || 'Unknown Page';
    }

    openFaq(currentPage); // Open FAQ Modal with correct page name
  };

  return (
    <>
      {auth.user && (
        <Drawer variant="permanent" sx={sidebarStyles.drawer}>
          <Toolbar />
          <Box
            height={'100%'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden', // Prevents scrollbar issues
            }}
          >
            <Divider />

            {/* Sidebar Content - Ensures scrolling when needed */}
            <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
              <List sx={sidebarStyles.list}>
                {/* User Profile Section */}
                <ListItem
                  disableGutters
                  disablePadding
                  sx={sidebarStyles.listItemProfile}
                >
                  <ListItemButton sx={sidebarStyles.listItemButton}>
                    <ListItemIcon sx={{ minWidth: 'auto' }}>
                      <UserIcon color={theme.palette.primary.dark} />
                    </ListItemIcon>
                    <Box
                      sx={{ display: { xs: 'auto', sm: 'none', lg: 'inline' } }}
                    >
                      <Typography
                        noWrap
                        display="inline"
                        variant="subtitle2"
                        color={theme.palette.primary.dark}
                      >
                        {userName}
                      </Typography>
                      <Typography
                        noWrap
                        color={theme.palette.text.primary}
                        variant="body2"
                      >
                        {userRole}
                      </Typography>
                    </Box>
                  </ListItemButton>
                </ListItem>

                {Object.entries(tabs).map(([key, values], index) => {
                  // const isSelected = key === getLocationLabel(location.pathname);
                  const isSelected = key === selectedTab;
                  return (
                    <ListItem
                      disableGutters
                      key={index}
                      onClick={() => routeChange(key)}
                      sx={sidebarStyles.listItem}
                    >
                      <ListItemButton
                        selected={isSelected}
                        sx={{
                          gap: 3,
                          py: 2,
                          borderRadius: 2,
                          justifyContent: 'center',
                          '&:hover': {
                            color: theme.palette.secondary.light,
                          },
                          color: isSelected ? theme.palette.bg.main : 'none',
                        }}
                      >
                        <ListItemIcon sx={sidebarStyles.listItemIcon}>
                          <values.Icon
                            sx={{
                              '&:hover': {
                                color: theme.palette.primary.dark,
                              },
                              color: isSelected
                                ? theme.palette.primary.dark
                                : theme.palette.text.secondary,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography variant="body1">{key}</Typography>
                          }
                          sx={{
                            display: { xs: 'auto', sm: 'none', lg: 'inline' },
                            '&:hover': {
                              color: theme.palette.text.secondary,
                            },
                            color: isSelected
                              ? theme.palette.primary.dark
                              : theme.palette.text.secondary,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Box>

            {/* Help Button - Positioned at the bottom */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 2,
                mt: 'auto',
                mb: { xs: 6 }, // Adjust margin based on available space
              }}
            >
              <CustomButton
                disabled={false}
                clickHandler={handleFaqClick}
                variant="outlined"
              >
                <HelpIcon />
                <Typography
                  p={'0 0 0 8px'}
                  display={{ xs: 'none', lg: 'flex' }}
                >
                  {translateCommon?.('headers.headerHelpButton')}
                </Typography>
              </CustomButton>
            </Box>
          </Box>
          <Typography variant={'body3'}>Build Nº {buildNumber}</Typography>
        </Drawer>
      )}
    </>
  );
};
