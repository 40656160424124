{
  "unknownUser": "",
  "me": "",
  "searchProjectsPlaceholder": "",
  "searchCommentsPlaceholder": "",
  "addCommentPlaceholder": "",
  "filterAll": "",
  "filterStarted": "",
  "filterReview": "",
  "filterCompleted": "",
  "confirmAction": "",
  "actions": {
    "edit": "",
    "delete": "",
    "reply": "",
    "close": "",
    "showReplies": "",
    "hideReplies": "",
    "markAsAddressed": "",
    "seeAllProjects": ""
  },
  "notes": {
    "projectDueDateChange": "",
    "projectStepDueDateChange": "",
    "projectStepStatusChange": ""
  },
  "titles": {
    "projectName": "",
    "client": "",
    "consultant": "",
    "dueDate": "",
    "step": ""
  },
  "statuses": {
    "active": "",
    "completed": "",
    "overDue": "",
    "today": "",
    "projectSteps": {
      "notStarted": "",
      "onHold": "",
      "inProgress": "",
      "done": ""
    }
  },
  "messages": {
    "noProject": "",
    "loading": "",
    "displayProjectsNoItemsMessage": "",
    "confirmMarkAsAddressed": ""
  },
  "buttons": {
    "projectOverview": {
      "save": "",
      "overview": ""
    }
  },
  "steps": {
    "setup": "",
    "dataCollection": "",
    "research": "",
    "calculation": "",
    "calculationReview": "",
    "draftReport": "",
    "reportReview": "",
    "clientReview": "",
    "finalizeReport": "",
    "closeProject": ""
  }
}
