import createTypography from '@mui/material/styles/createTypography';
import { palette } from './palette';
import { TypographyVariantsOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    link: React.CSSProperties;
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    link?: React.CSSProperties;
    body3?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    link: true;
    body3: true;
  }
}

//TODO:add font colors

const typographyObject: TypographyVariantsOptions = {
  fontFamily: 'Open Sans',
  h1: {
    fontSize: '96px',
    fontWeight: '300',
    lineHeight: '116.7%',
    letterSpacing: '-1.5px',
  },
  h2: {
    fontSize: '60px',
    fontWeight: '300',
    lineHeight: '120%',
    letterSpacing: '-0.5px',
  },
  h3: {
    fontSize: '48px',
    fontWeight: '400',
    lineHeight: '116.7%',
    letterSpacing: '0px',
  },
  h4: {
    fontSize: '34px',
    fontWeight: '400',
    lineHeight: '123.5%',
    letterSpacing: '0.25px',
  },
  h5: {
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '133.4%',
    letterSpacing: '0px',
  },
  h6: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '160%',
    letterSpacing: '0.15px',
  },
  subtitle1: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '175%',
    letterSpacing: '0.15px',
  },
  subtitle2: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '157%',
    letterSpacing: '0.1px',
  },
  body1: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '150%',
    letterSpacing: '0.15px',
  },
  body2: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '143%',
    letterSpacing: '0.17px',
  },
  body3: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '143%',
    letterSpacing: '0.17px',
  },
  button: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '171.429%',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
  },
  caption: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '166%',
    letterSpacing: '0.4px',
  },
  overline: {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '0%',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  link: {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: 'normal',
    letterSpacing: '0px',
    textDecorationLine: 'underline',
  },
};

export const typography = createTypography(palette, typographyObject);
