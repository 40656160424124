{
    "shortenedWeekDays":{
        "friday": "VEN",
        "monday": "LUN",
        "saturday": "SAM",
        "sunday": "DIM",
        "thursday": "JEU",
        "tuesday": "MAR",
        "wednesday": "MER"
    }
}
