{
    "taskTableLoadingMessage": "... Aucune tâche à afficher",
    "searchTaskPlaceholder": "Rechercher une tâche",
    "description": "Description",
    "buttons":{
        "add":"AJOUTER UNE TÂCHE"
    },
    "messages": {
        "successfulTaskCreation": "Tâche créée",
        "successfulTaskDeletion": "1 tâche supprimée"
    },
    "headers": {
        "taskName": "Tâche",
        "notes": "Notes",
        "priority": "Priorité",
        "dueDate": "Date de clôture",
        "edit": "Modifier",
        "client": "Client",
        "clientId": "ID du client",
        "taskId": "ID de la tâche"
    },
    "titles": {
        "active": "En cours",
        "completed": "Complétées",
        "overDue": "En retard",
        "today": "Aujourd'hui",
        "undoTaskDelete": "Annuler la suppression",
        "upcoming": "À venir",
        "seeAllTasks": "Voir toutes les tâches",
        "addTask": "AJOUTER UNE TÂCHE",
        "priority":"Priorité"
    },
    "displayTaskTable":{
        "displayTableFetchingErrorMessage": "Une erreur est survenue",
        "displayTableNoItemsMessage": "Aucun élément à afficher"
    },
    "priorities":{
        "levels": {
            "high": "Haute",
            "low": "Basse",
            "medium": "Moyenne",
            "urgent": "Urgente"
        }
    },
    "labels":{
        "dueDate": "Date de clôture",
        "taskName": "Nom de la tâche"
    },
    "form":{
        "dropdownClientNone": "Aucun",
        "dropdownClientDefault": "Client"
    }

}
