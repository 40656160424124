import { useLocation, Navigate } from 'react-router-dom';
import useAuth from '../configs/AuthContext';
import CircularProgress from '@mui/material/CircularProgress';

function ProtectedRoute({ children }: { children: JSX.Element }): JSX.Element {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    // Show a loading spinner or placeholder
    return <CircularProgress size={50} />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
    return (
      <Navigate to="/change-password" state={{ from: location }} replace />
    );
  }

  return children;
}

export default ProtectedRoute;
