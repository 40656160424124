import React, {createContext, ReactNode, useContext, useMemo, useState} from 'react';
import {
  useGetPlQuery,
  useUpdateSlMutation,
  useCreateSlMutation,
  useDeleteSlMutation,
} from '../services/ghgci-sfe';
import { PlEntities, SlEntities } from '../services/types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import refactorProjectPlData from 'pages/projects/components/refactor-project-pl-data';
import { NewProjectDataType } from 'pages/projects/get-projects';
import { useParams } from 'react-router-dom';
import useAuth from 'configs/AuthContext';

// Define UpdateProjectOptions with optional fields at the top level
interface UpdateProjectOptions {
  projectId: string;
  projectClientId?: string;
  projectMandate?: string;
  projectCurrentStep?: string;
  projectJurisdiction?: string;
  projectDueDate?: string;
  projectType?: string;
  projectPeriod?: string;
  nextProject?: string;
  accountManager?: string;
  previousProject?: string;
}

type ProjectsContextType = {
  handleGetProject: () => NewProjectDataType;
  isGetProjectsFetching: boolean;
  isGetProjectsLoading: boolean;
  isGetProjectsSuccessful: boolean;
  isGetProjectsError: FetchBaseQueryError | SerializedError | undefined;

  /**
   * Updates the project with the specified fields.
   *
   * This function allows you to update various fields of a project. Pass only the fields you want to update,
   * and any omitted fields will retain their previous values.
   *
   * @param {UpdateProjectOptions} options - An object containing the fields to update.
   * @param {string} options.projectId - The ID of the project to update (required).
   * @param {string} [options.projectClientId] - The client ID associated with the project.
   * @param {string} [options.projectStatus] - The current status of the project.
   * @param {string} [options.projectMandate] - The mandate of the project.
   * @param {string} [options.projectCurrentStep] - The current step in the project.
   * @param {string} [options.projectJurisdiction] - The jurisdiction of the project.
   * @param {string} [options.projectDueDate] - The due date for the project.
   * @param {string} [options.projectType] - The type of the project.
   * @param {string} [options.projectPeriod] - The period the project spans.
   * @param {string} [options.nextProject] - The ID of the next project in the sequence.
   * @param {string} [options.accountManager] - The account manager for the project.
   * @param {string} [options.previousProject] - The ID of the previous project.
   * @example
   * handleUpdateProject({
   *   projectId: projectId!,
   *   projectClientId: rawProjectData.projectClientId,
   *   projectStatus: rawProjectData.projectStatus,
   *   projectMandate: rawProjectData.projectMandate,
   *   projectCurrentStep: rawProjectData.projectCurrentStep,
   *   projectJurisdiction: rawProjectData.projectJurisdiction,
   *   projectDueDate: rawProjectData.projectDueDate,
   *   projectType: rawProjectData.projectType,
   *   projectPeriod: rawProjectData.projectPeriod,
   *   nextProject: rawProjectData.nextProject,
   *   accountManager: rawProjectData.accountManager,
   *   previousProject: rawProjectData.previousProject,
   * });
   */
  handleUpdateProject: (options: UpdateProjectOptions) => void;
  handleUpdateProjectStep: (
    sk_id: string,
    duedate: string,
    status: string,
    name: string,
  ) => Promise<void>;
  handleCreateProjectComment: (
    id: string,
    parent: string,
    projectstep: string,
    read: string[],
    tags: string,
    text: string,
    checked: boolean,
    author: string,
  ) => Promise<void>;
  handleUpdateProjectComment: (
    projectCommentId: string,
    parent: string,
    projectstep: string,
    read: string[],
    tags: string,
    text: string,
    checked: boolean,
  ) => Promise<void>;
  ghgciId: string;
  handleDeleteComment: (customId: string) => void;
  deleteCommentLoading: boolean;
  deleteCommentError: FetchBaseQueryError | SerializedError | undefined;

  handleUpdateProjectText: (
    projectId: string,
    successCriteria: string,
    intendedUse: string,
    applicableStandard: string,
  ) => void;
  isUpdateProjectLoading: boolean;
  updateProjectError: FetchBaseQueryError | SerializedError | undefined;
  isUpdateProjectSuccess: boolean;
  resetUpdateProjectQueryValues: () => void;

  setCurrentProjectId: React.Dispatch<React.SetStateAction<string>>;
};

const defaultNewProjectData: NewProjectDataType = {
  projectId: '',
  projectClientId: '',
  projectJurisdiction: '',
  projectMandate: '',
  projectAssignees: [],
  projectDueDate: '',
  successCriteria: [],
  applicableStandard: [],
  intendedUse: [],
  projectSteps: [],
  projectComments: [],
  projectCurrentStep: '',
  projectType: '',
  projectPeriod: '',
  nextProject: '',
  accountManager: '',
  previousProject: '',
};

const defaultProjectsContext: ProjectsContextType = {
  handleGetProject: () => defaultNewProjectData,
  isGetProjectsFetching: false,
  isGetProjectsLoading: false,
  isGetProjectsSuccessful: false,
  isGetProjectsError: {},

  handleUpdateProject: () => {},

  handleUpdateProjectStep: async () => Promise.resolve(),
  handleCreateProjectComment: async () => {},
  handleUpdateProjectComment: async () => {},
  ghgciId: '',

  handleDeleteComment: () => {}, // Provide a default implementation for handleDeleteComment
  deleteCommentLoading: false,
  deleteCommentError: undefined,

  handleUpdateProjectText: () => {},
  isUpdateProjectLoading: false,
  updateProjectError: {},
  isUpdateProjectSuccess: false,
  resetUpdateProjectQueryValues: () => {},
  setCurrentProjectId: () => {},
};

const DataContext = createContext(defaultProjectsContext);

export const ProjectsDataProvider = ({ children }: { children: ReactNode }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const [currentProjectId, setCurrentProjectId] = useState(projectId || '');
  const auth = useAuth();
  const ghgciId = useMemo(() => {
    return auth.user?.getSignInUserSession()?.getIdToken()?.payload['custom:ghgci_id'] || null;
  }, [auth.user]);
  const [
    updateProject,
    {
      isLoading: isUpdateProjectLoading,
      error: updateProjectError,
      isSuccess: isUpdateProjectSuccess,
      reset: resetUpdateProjectQueryValues,
    },
  ] = useUpdateSlMutation();
  const [
    createProjectComment,
    {
      isLoading: iscreateProjectCommentLoading,
      error: createProjectCommentError,
      isSuccess: iscreateProjectCommentSuccess,
      reset: resetcreateProjectCommentQueryValues,
    },
  ] = useCreateSlMutation();

  const {
    data,
    isFetching: isGetProjectsFetching,
    error: isGetProjectsError,
    isLoading: isGetProjectsLoading,
    isSuccess: isGetProjectsSuccessful,
  } = useGetPlQuery({
    typename: PlEntities.Project,
    id: currentProjectId,
  });

  const handleGetProject = (): NewProjectDataType => {
    return data
      ? refactorProjectPlData(data.Items, data.id)
      : defaultNewProjectData;
  };

  /**
   * Updates the project with the specified fields.
   *
   * This function allows you to update various fields of a project. Pass only the fields you want to update,
   * and any omitted fields will retain their previous values.
   *
   * @param {UpdateProjectOptions} options - An object containing the fields to update.
   * @example
   * handleUpdateProject({
   *   projectId: projectId!,
   *   projectClientId: rawProjectData.projectClientId,
   *   projectStatus: rawProjectData.projectStatus,
   *   projectMandate: rawProjectData.projectMandate,
   *   projectCurrentStep: rawProjectData.projectCurrentStep,
   *   projectJurisdiction: rawProjectData.projectJurisdiction,
   *   projectDueDate: rawProjectData.projectDueDate,
   *   projectType: rawProjectData.projectType,
   *   projectPeriod: rawProjectData.projectPeriod,
   *   nextProject: rawProjectData.nextProject,
   *   accountManager: rawProjectData.accountManager,
   *   previousProject: rawProjectData.previousProject,
   *   operational: rawProjectData.operational, // if needed
   * });
   */
  const handleUpdateProject = (options: UpdateProjectOptions) => {
    const {
      projectId,
      projectClientId,
      projectMandate,
      projectCurrentStep,
      projectJurisdiction,
      projectDueDate,
      projectType,
      projectPeriod,
      nextProject,
      accountManager,
      previousProject,
    } = options;

    const requestBody = {
      id: projectId,
      typename: SlEntities.Project,
      ...(projectType && { projectType: projectType }),
      ...(projectCurrentStep && { step: projectCurrentStep }),
      ...(projectJurisdiction && { jurisdiction: projectJurisdiction }),
      ...(projectPeriod && { period: projectPeriod }),
      ...(projectMandate && { mandate: projectMandate }),
      ...(nextProject && { next_project: nextProject }),
      ...(projectDueDate && { duedate: projectDueDate }),
      ...(projectClientId && { client: projectClientId }),
      ...(accountManager && { accountmanager: accountManager }),
      ...(previousProject && { previous_project: previousProject }),
    };
    console.log('requestBody', requestBody);

    updateProject({
      typename: PlEntities.Project,
      id: projectId,
      requestBody,
    }).unwrap();
  };

  const handleUpdateProjectText = (
    projectTextLanguageSymbol: string,
    successCriteria: string,
    intendedUse: string,
    applicableStandard: string,
  ) => {
    updateProject({
      typename: PlEntities.Project,
      id: currentProjectId,
      requestBody: {
        id: projectTextLanguageSymbol,
        typename: SlEntities.ProjectText,
        language: projectTextLanguageSymbol,
        success_criteria: successCriteria,
        intended_use: intendedUse,
        applicable_standard: applicableStandard,
      },
    }).unwrap();
  };

  const handleUpdateProjectStep = async (
    sk_id: string,
    duedate: string,
    status: string,
    name: string,
  ): Promise<void> => {
    try {
      await updateProject({
        typename: PlEntities.Project,
        id: currentProjectId,
        requestBody: {
          id: sk_id,
          typename: SlEntities.ProjectStep,
          status: status,
          name,
          duedate,
        },
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };
  const handleUpdateProjectComment = async (
    projectCommentId: string,
    parent: string,
    projectstep: string,
    read: string[],
    tags: string,
    text: string,
    checked: boolean,
  ): Promise<void> => {
    try {
      await updateProject({
        typename: PlEntities.Project,
        id: currentProjectId,
        requestBody: {
          id: projectCommentId,
          typename: SlEntities.Comment,
          parent,
          projectstep,
          read,
          tags,
          text,
          checked,
        },
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };
  const handleCreateProjectComment = async (
    id: string,
    parent: string,
    projectstep: string,
    read: string[],
    tags: string,
    text: string,
    checked: boolean,
    author: string,
  ): Promise<void> => {
    try {
      await createProjectComment({
        typename: PlEntities.Project,
        id: currentProjectId,
        requestBody: {
          id,
          typename: SlEntities.Comment,
          parent,
          projectstep,
          read,
          tags,
          text,
          checked,
          author,
        },
      }).unwrap();
    } catch (error) {
      throw error;
    }
  };

  const [
    deleteComment,
    {
      isLoading: deleteCommentLoading,
      isError: deleteCommentIsError,
      error: deleteCommentError,
    },
  ] = useDeleteSlMutation();
  const handleDeleteComment = async (commentID: string) => {
    try {
      await deleteComment({
        typename: PlEntities.Project,
        id: ghgciId,
        requestBody: {
          id: commentID,
          typename: SlEntities.Comment,
        },
      }).unwrap();
    } catch (e) {
      console.error('Failed to delete notification:', e);
    }
  };

  return (
    <DataContext.Provider
      value={{
        handleGetProject,
        isGetProjectsFetching,
        isGetProjectsError,
        isGetProjectsLoading,
        isGetProjectsSuccessful,
        handleUpdateProject,
        handleUpdateProjectStep,
        handleUpdateProjectText,
        isUpdateProjectLoading,
        isUpdateProjectSuccess,
        resetUpdateProjectQueryValues,
        updateProjectError,
        setCurrentProjectId,

        handleCreateProjectComment,
        handleUpdateProjectComment,
        ghgciId,

        handleDeleteComment,
        deleteCommentLoading,
        deleteCommentError: deleteCommentIsError
          ? deleteCommentError
          : undefined,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useProjectsDataContext = () => useContext(DataContext);
