import { colors } from 'utils/colors';
import { Checkbox, Tooltip } from '@mui/material';
import { Calendar, DeleteIcon } from 'icons';
import { useEffect, useState } from 'react';
import { useTaskDataContext } from '../../../../react-query-toolkit/state/tasks-context';
import { ColumnDef, Row, Table } from '@tanstack/react-table';
import { NewTaskDataType } from 'pages/task-manager/api/task-type';
import { useIsWindowWidthMobile } from 'utils/hooks/useIsWindowWidthMobile';
import { useTranslation } from 'react-i18next';
import { DataVisibilityType } from 'pages/clients/api/get-clients';
import {
  AddButtonContainer,
  AddTaskContainer,
  AddTaskSpacer,
  CalendarText,
  CardHeaderContainer,
  CardHeaderTitle,
  CellDateContainer,
  IconsContainer,
  ModalActionText,
  ModalText,
  SeeAllContainer,
  TableCellTextContainer,
  TableContainer,
  TodayTaskContainer,
  UndoContainer,
} from 'pages/task-manager/components/styled';
import {
  ExpandingButtonContainer,
  TaskCell,
  TaskCellTextContainer,
} from 'pages/clients/components/clients-table/styled';
import { checkBoxColorPriorityHandler } from 'pages/task-manager/components/utils';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import CircleChecked from '@mui/icons-material/CheckCircleOutline';
import { EditIcon } from 'icons/edit-icon';
import { DisplayTable } from 'pages/clients/components/clients-table';
import TaskTableSubComponent from 'pages/task-manager/components/task-table-sub-component';
import { todayTaskType } from 'pages/task-manager/components';
import { PlusIcon } from 'icons/plus-icon';
import LoadingComponent from 'components/loading-icon-component';
import { useNavigate } from 'react-router-dom';
import { formatTaskData } from 'pages/task-manager/api/format-task-data';
import { useUserDataContext } from '../../../../react-query-toolkit/state/user-context';
import { useIsWindowWidthDesktop } from 'utils/hooks/useIsWindowWidthDesktop';
import { DateTime } from 'luxon';
import { Task } from '../../../../react-query-toolkit/state/types';

export const TaskManager = (props: todayTaskType) => {
  const {
    handleGetTasks,
    tasksFetchingError,
    tasksListIsLoading,
    handleDeleteTask,
    handleCreateTask,
    handleUpdateTask,
  } = useTaskDataContext();
  const [showCompletedTask] = useState(false);
  const { handleGetClients } = useUserDataContext();
  const clientData = handleGetClients() || [];
  const rawTaskData = handleGetTasks() || [];
  let taskData: NewTaskDataType[];

  function filter5MostRecentlyActiveTasks(tasks: Task[]) {
    return tasks
      .filter((task) => task.completed === false)
      .map((task) => ({
        ...task,
        parsedDueDate: DateTime.fromISO(task.duedate).toMillis(), // Parse once
      }))
      .sort((a, b) => b.parsedDueDate - a.parsedDueDate) //
      .slice(0, 5) //
      .map(({ parsedDueDate, ...task }) => ({
        ...task,
        duedate: DateTime.fromMillis(parsedDueDate).toISO()!, // Return ISO format
      }));
  }

  if (rawTaskData) {
    taskData = showCompletedTask
      ? filterCompletedData(
          formatTaskData(
            filter5MostRecentlyActiveTasks(rawTaskData),
            clientData,
          ),
        )
      : filterUncompletedData(
          formatTaskData(
            filter5MostRecentlyActiveTasks(rawTaskData),
            clientData,
          ),
        );
  }

  function filterCompletedData(data: NewTaskDataType[]) {
    return data.filter((item) => item.isCompleted);
  }

  function filterUncompletedData(data: NewTaskDataType[]) {
    return data.filter((item) => !item.isCompleted);
  }

  const [completedTask, setCompletedTask] = useState<NewTaskDataType | null>(
    null,
  );
  const { isMobileWidth, windowSizeWidth } = useIsWindowWidthMobile();
  const { isDesktopWidth } = useIsWindowWidthDesktop();

  const { t: translate } = useTranslation();

  const [columnsVisibility, setColumnsVisibility] =
    useState<DataVisibilityType>({
      priority: !isMobileWidth,
      icons: !isDesktopWidth,
    });

  const [undoTimer, setUndoTimer] = useState<NodeJS.Timeout | null>(null);

  const columns: ColumnDef<NewTaskDataType>[] = [
    {
      header: ``,
      accessorKey: 'expand',
      enableSorting: false,
      size: 1,
      minSize: 70,
      cell: ({ row }) => (
        <>
          <ExpandingButtonContainer
            onChange={() =>
              setTimeout(() => {
                handleUpdateTask(
                  row.original.taskId!,
                  row.original.taskName,
                  row.original.priority,
                  !row.original.isCompleted,
                  row.original.dueDate,
                  row.original.notes,
                  row.original.clientId,
                );
              }, 1000)
            }
          >
            <Checkbox
              sx={{
                color: checkBoxColorPriorityHandler(row.original.priority),
                '&.Mui-checked': {
                  color: checkBoxColorPriorityHandler(row.original.priority),
                },
              }}
              icon={<CircleUnchecked />}
              checkedIcon={<CircleChecked />}
              disabled={!row.getCanSelect()}
              {...{
                checked: showCompletedTask ? true : row.getIsSelected(),
                onChange: async () => {
                  // Toggle selected status of current row
                  row.toggleSelected(true);
                  handleUpdateTask(
                    row.original.taskId!,
                    row.original.taskName,
                    row.original.priority,
                    !row.original.isCompleted,
                    row.original.dueDate,
                    row.original.notes,
                    row.original.clientId,
                  );
                },
              }}
            />
            <TaskCell
              onClick={row.getToggleExpandedHandler()}
              rowIsSelected={row.getIsSelected()}
            >
              <Tooltip title={row.original.taskName}>
                <TaskCellTextContainer>
                  {row.original.taskName}
                </TaskCellTextContainer>
              </Tooltip>
            </TaskCell>
          </ExpandingButtonContainer>
        </>
      ),
    },
    {
      header: ``,
      accessorKey: 'dueDate',
      enableSorting: false,
      size: 50,
      minSize: 10,
      cell: ({ row }) => {
        return (
          <CellDateContainer
            tabIndex={0}
            role="button"
            onClick={row.getToggleExpandedHandler()}
            onKeyDown={row.getToggleExpandedHandler()}
          >
            <CalendarText color={colors.secondaryBlue}>
              <Calendar
                width={14}
                height={14}
                color={colors.lighterSecondaryBlue}
              />
              <TableCellTextContainer>
                {DateTime.fromISO(row.original.dueDate).toLocaleString(
                  DateTime.DATE_FULL,
                )}
              </TableCellTextContainer>
            </CalendarText>
          </CellDateContainer>
        );
      },
    },
    {
      header: ``,
      accessorKey: 'icons',
      enableSorting: false,
      size: 30,
      cell: ({
        table,
        row,
      }: {
        table: Table<NewTaskDataType>;
        row: Row<NewTaskDataType>;
      }) => (
        <IconsContainer tabIndex={0} role="button">
          <button
            onKeyDown={() => {
              props.setSelectedData && props.setSelectedData(row.original);
              props.setModalIsOpen(true);
              props.setModalType('update');
            }}
            onClick={() => {
              props.setSelectedData && props.setSelectedData(row.original);
              props.setModalIsOpen(true);
              props.setModalType('update');
            }}
          >
            <EditIcon />
          </button>
          <button
            onKeyDown={() => handlerDelete(table, row)}
            {...{
              style: { cursor: 'pointer' },
            }}
            onClick={() => handlerDelete(table, row)}
            {...{
              style: { cursor: 'pointer' },
            }}
          >
            <DeleteIcon />
          </button>
        </IconsContainer>
      ),
    },
  ];

  const undoCompletedTask = () => {
    ///Here send request to Server to change task to isCompleted =false; Get response and if good then continue
    if (completedTask) {
      handleCreateTask(
        completedTask.taskName,
        completedTask.priority,
        completedTask.dueDate,
        completedTask.notes,
        completedTask.clientId,
      );
      setCompletedTask(null);
      clearTimeout(undoTimer!);
    }
  };

  const handlerDelete = (
    table: Table<NewTaskDataType>,
    row: Row<NewTaskDataType>,
  ) => {
    //In case of a previous delete in progress.
    clearTimeout(undoTimer!);
    setCompletedTask(null);
    row.original.isCompleted = !row.original.isCompleted;
    handleDeleteTask(row.original.taskId!);

    row.original.isCompleted = !row.original.isCompleted;
    setCompletedTask(row.original);
    table.resetRowSelection();
    table.toggleAllPageRowsSelected(false);

    const timer = setTimeout(() => {
      // If timer expires, remove the deleted item
      setCompletedTask(null);
    }, 7000);
    setUndoTimer(timer);

    setTimeout(() => {
      //Handles Expansion of the cell by interaction of delete button.
      table.resetExpanded(true);
    });
  };

  useEffect(() => {
    return () => {
      // Clear the undo timer when component unmounts
      clearTimeout(undoTimer!);
    };
  }, [undoTimer]);

  const addTaskHandler = () => {
    props.setModalType('create');
    props.setModalIsOpen(true);
    props.setSelectedData &&
      props.setSelectedData({
        taskName: '',
        priority: '',
        notes: '',
        dueDate: '',
        isCompleted: false,
        clientId: '',
      });
  };

  useEffect(() => {
    setColumnsVisibility({
      priority: !isMobileWidth,
      icons: !isDesktopWidth,
    });
    return () => {};
  }, [windowSizeWidth]);
  const navigate = useNavigate();

  const displayTableNoItemsMessage = translate?.('displayTableNoItemsMessage');
  return (
    <>
      <TodayTaskContainer hidePadding background={colors.white}>
        {completedTask ? (
          <UndoContainer>
            <ModalText>{translate?.('taskCompletedMessage')}</ModalText>
            <ModalActionText
              onClick={() => {
                undoCompletedTask();
              }}
            >
              {translate?.('undoTaskDeleteTitle')}
            </ModalActionText>
          </UndoContainer>
        ) : null}
        <CardHeaderContainer>
          <CardHeaderTitle onClick={() => navigate('/task-manager')}>
            {translate?.('headerTaskManager')}
          </CardHeaderTitle>
          <AddTaskSpacer>
            <AddTaskContainer>
              <AddButtonContainer padding={'8px 7px'} onClick={addTaskHandler}>
                <PlusIcon />
              </AddButtonContainer>
            </AddTaskContainer>
          </AddTaskSpacer>
        </CardHeaderContainer>
        <TableContainer maxHeight={'100%'}>
          <DisplayTable
            data={taskData!}
            columns={columns}
            getRowCanExpand={() => true}
            columnsVisibility={columnsVisibility}
            showFooter={false}
            cellMaxWidth={'200'}
            RenderSubComponent={TaskTableSubComponent}
            loadingItem={
              tasksListIsLoading ? (
                <LoadingComponent size={'50'} />
              ) : tasksFetchingError ? (
                displayTableNoItemsMessage
              ) : undefined
            }
            hideHeader
          />
          <SeeAllContainer onClick={() => navigate('/task-manager')}>
            {translate?.('seeAll')}
          </SeeAllContainer>
        </TableContainer>
      </TodayTaskContainer>
    </>
  );
};
