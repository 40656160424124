{
  "errors":{
    "inputEmailCodeConfirmationCodeErrorNotEmpty": "Code can't be empty",
    "inputEmailCodeConfirmationCodeErrorRequired": "Code is required",
    "inputEmailCodePasswordErrorMatches": "Password must contain at least 1 letter, 1 number, and 1 special character",
    "inputEmailCodePasswordErrorMin8": "Password must be at least 8 characters",
    "inputEmailCodePasswordErrorRequired": "Password is required"

  },
  "labels":{
    "inputEmailCodeEmailCodeLabel": "Email Code",
    "inputEmailCodePasswordLabel": "New Password"

  },
  "placeholders":{
      "inputEmailCodeEmailCodePlaceholder": "Email Code",
      "inputEmailCodePasswordPlaceholder": "New Password"
  },
  "titles":{
    "inputEmailCodeTitle": "Forgot Password?"
  },
  "messages":{
    "inputEmailCodeBottomMessage": "< Back to Log In",
    "inputEmailCodeEmailAddressConfirmation": "Please confirm your email address: {{ userEmail }}",
    "inputEmailCodeReceiverConfirmation": "Didn't receive a code?",
    "inputEmailCodeWrongEmail": "Wrong email?"
  },
  "buttons":{
    "inputEmailCodeSubmitButton": "RESET PASSWORD"}
}
