import { ReactNode, useEffect, useState } from 'react';
import { MobileMenu, NavBar } from './nav-bar';
import { Sidebar } from './sidebar';
import { useNavAndSideBarContext } from 'components/nav-and-sidebar/state/context';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslateAll } from '../../locales/translation-hooks';

export const NavAndSideBar = ({ children }: { children: ReactNode }) => {
  const { showSideBar, showMenuBar } = useNavAndSideBarContext();
  const [selectedComponent, setSelectedComponent] = useState('');
  const { translateCommon } = useTranslateAll(['common']);

  const location = useLocation();
  const { pathname: currentPath } = location;

  useEffect(() => {
    function getPageName(currentPath: string) {
      const pageNames: Record<string, string> = {
        '/my-clients': translateCommon('headers.headerClient'),
        '/': translateCommon('headers.headerDashboard'),
        '/dashboard': translateCommon('headers.headerDashboard'),
        '/task-manager': translateCommon('headers.headerTaskManager'),
        '/my-projects': translateCommon('headers.headerMyProjects'),
        '/calendar': translateCommon('headers.headerCalendar'),
        '/notifications': translateCommon('headers.headerNotifications'),
        '/user-profile': translateCommon('headers.headerUserProfile'),
      };
      if (pageNames[currentPath]) {
        return pageNames[currentPath];
      } else {
        return '';
      }
    }
    setSelectedComponent(getPageName(currentPath));
    return;
  }, [location]);

  return (
    <Box sx={{ display: 'flex', height: '100vh', width: '100%' }}>
      <NavBar pageName={selectedComponent} />
      <MobileMenu showMenuBar={showMenuBar} />
      <Sidebar showSideBar={showSideBar} />
      <Box
        component="main"
        mt={'60px'}
        width={'100%'}
        height={'calc(100vh - 60px)}'}
        overflow={'auto'}
      >
        {children}
      </Box>
    </Box>
  );
};
