{
  "shortenedWeekDays": {
    "friday": "",
    "monday": "",
    "saturday": "",
    "sunday": "",
    "thursday": "",
    "tuesday": "",
    "wednesday": ""
  }
}
