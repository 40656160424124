import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';
import { defaultFontFamily, spacingWithPx } from 'utils/styles';
import { colors } from 'utils/styles';
import { checkBoxColorPriorityHandler } from 'pages/task-manager/components/utils';

export const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: ${spacingWithPx.sm};
  position: relative; /* Ensure modal is positioned relative to this container */
  width: 100%;
  height: 100%; /* Optional: adjust as per your layout needs */
  justify-content: center; /* Center content horizontally if needed */
  align-items: center; /* Center content vertically if needed */
  @media screen and (min-width: ${breakpoints.sm}) {
    justify-content: center;
    width: 100%;
    height: auto;
  }
  @media screen and (min-width: ${breakpoints.xxxl}) {
    flex-direction: column;
    max-width: none;
  }
`;

export const PriorityDot = styled.div<{ dotSize?: string; priority?: string }>`
  flex-shrink: 0;
  height: ${(props) => (props.dotSize ? props.dotSize : '5px')};
  width: ${(props) => (props.dotSize ? props.dotSize : '5px')};
  background-color: ${(props) =>
    props.priority
      ? checkBoxColorPriorityHandler(`${props.priority}`)
      : `${colors.grey}`};
  border-radius: 50%;
  cursor: pointer;
  top: -2px;
  display: flex;
  position: relative;
`;

export const Dot = styled.span<{ past?: boolean; isTodayDue?: boolean }>`
  height: 4px;
  width: 4px;
  background-color: ${(props) =>
    props.past ? `${colors.red}` : `${colors.greenOnline}`};
  ${(props) =>
    props.isTodayDue ? `background-color: ${colors.greenOnline}` : ''};
  border-radius: 50%;
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  top: 10px;

  @media screen and (max-width: ${breakpoints.md}) {
    justify-content: center;
    top: 5px;
  }
`;

export const DateCellWrapper = styled.div<{ hasEvents?: boolean }>`
  position: relative;
  cursor: ${(props) => (props.hasEvents ? 'pointer' : 'default')};
`;

export const ModalContentContainer = styled.div<{ display?: string }>`
  display: ${(props) => (props.display ? props.display : 'auto')};
  align-items: center;
`;

export const ModalContentContainerInner = styled.div<{ display?: string }>`
  display: ${(props) => (props.display ? props.display : 'auto')};
  align-items: center;
  height: 26px;
`;

export const Modal = styled.div`
  position: relative;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid ${colors.modalBorder};
  box-shadow: 0 4px 8px ${colors.cardShadowDarker};
  z-index: 1000;
  max-width: 90%;

  @media screen and (max-width: ${breakpoints.md}) {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 390px;
    min-width: 250px;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    position: absolute;
    top: 50%;
    left: 50%;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.modalBorder};
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding-left: 12px;
`;

export const ModalText = styled.p<{ fontSize?: string }>`
  color: ${colors.darkGrey};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-style: normal;
  font-weight: 300;
  line-height: 15px; /* Adjusted to match the height of the container */
  margin: 0;
  height: 20px;
  padding-left: 5px;
  letter-spacing: 0.15px;
  white-space: nowrap; /* Ensure text does not wrap */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis when text overflows */
  flex: 1; /* Ensure ModalText takes the remaining space */
`;

export const SeeAllText = styled.p`
  color: ${colors.darkGrey};
  margin: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 0;
  letter-spacing: 0.15px;
  padding-top: 15px;
  cursor: pointer;
`;

export const CalendarContainer = styled.div`
  width: 100%;

  .react-calendar {
    font-family: ${defaultFontFamily};
    background: ${colors.white};

    color: ${colors.darkGrey};

    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
    .react-calendar__navigation {
      display: none;
    }
    .react-calendar__viewContainer {
      padding: 14px 8px; // This is temp
    }
  }

  .react-calendar__tile {
    .react-calendar__month-view__days__day {
      color: ${colors.darkGrey} !important;

      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 130%;
    }
  }
  .react-calendar__tile {
    .react-calendar__month-view__days__day {
      .react-calendar__month-view__days__day--neighboringMonth {
        color: ${colors.darkGrey};

        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 130%;
      }
    }
  }

  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }

  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 0.75em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }

  .react-calendar__navigation__label {
    color: ${colors.bluePrimary};
    font-size: 0.8em;
  }

  .react-calendar__navigation__arrow {
    color: ${colors.darkGrey};
    font-size: 1.1em;
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    color: ${colors.secondaryBlue};
    font-size: 0.75em;
    margin-bottom: 0.9em;
    pointer-events: none;

    abbr[title],
    abbr[data-bs-original-title] {
      text-decoration: none;
    }
  }

  .react-calendar__tile {
    max-width: 100%;
    background: none;
    text-align: center;
    aspect-ratio: 1 / 1;
    font-size: 0.85em;
    color: ${colors.darkGrey};
  }

  .react-calendar__month-view__weekdays button:hover {
    cursor: default;
  }

  .react-calendar__tile--active {
    background: ${colors.bluePrimary};
    color: white;
    border-radius: 50%;
  }
`;
