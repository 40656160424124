import React, { useEffect } from 'react';
//import { Container } from 'pages/dashboard/styled';
import NotificationCard from 'pages/notifications/components/notifications-card';
import { PageWrapper } from 'components/molecules/page-wrapper/page-wrapper';
import { useNotificationDataContext } from '../../react-query-toolkit/state/notifications-context';
import { Box, Stack } from '@mui/material';
import { PageTitle } from 'components/atoms/page-title/page-title';
import { useTranslateAll } from '../../locales/translation-hooks';
import CircularProgress from '@mui/material/CircularProgress';
import { useWebSocketContext } from '../../react-query-toolkit/state/websocket-context';

export const Notifications: React.FC = () => {
  const { translateCommon } = useTranslateAll('common');
  const {
    handleGetNotifications,
    notificationsFetchingError,
    notificationsListIsLoading,
    deleteNotificationLoading,
    deleteNotificationError,
  } = useNotificationDataContext();

  const notifications = handleGetNotifications() || [];

  const { lastMessageData } = useWebSocketContext();
  useEffect(() => {
    console.log(lastMessageData);
  }, [lastMessageData]);

  return (
    <PageWrapper pxValue={7} pyValue={9}>
      <Stack spacing={7}>
        <PageTitle
          title={translateCommon('headers.headerNotifications')}
          py={0}
        />
        <Stack spacing={{ xs: 7, xxl: 5 }}>
          {notificationsListIsLoading ? (
            <Box
              display={'flex'}
              width={'100%'}
              height={'30%'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <CircularProgress size={50} />
            </Box>
          ) : notificationsFetchingError ? (
            <Box>Error loading notifications</Box>
          ) : (
            <>
              {/* Render notifications */}
              {notifications.map((notification) => (
                <NotificationCard
                  key={notification.id}
                  notification={notification}
                />
              ))}
            </>
          )}
          {deleteNotificationLoading && <Box>Deleting notification...</Box>}
          {deleteNotificationError && <Box>Error deleting notification</Box>}
        </Stack>
      </Stack>
    </PageWrapper>
  );
};
