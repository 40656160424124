import React, { useEffect, useRef, useState } from 'react';
import { Profile } from './components/profile/profile';
import { Password } from './components/password/password';
import { Formats } from './components/formats/formats';
import { PageTitle } from 'components/atoms/page-title/page-title';
import Grid from '@mui/material/Grid2';
import { PageWrapper } from 'components';
import {
  BasicUserSettingsData,
  ExtendedUserSettingsData,
  useUserSettingsDataContext,
} from '../../react-query-toolkit/state/user-settings-context';
import {
  BasicSlUserData,
  ExtendedSlUserData,
  useUserDataContext,
} from '../../react-query-toolkit/state/user-context';
import { Item, Settings, User } from '../../react-query-toolkit/state/types';
import {
  BasicJoinedUserSettingsType,
  ExtendedJoinedUserSettingsType,
} from 'pages/user-profile/components/types';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Container, Typography } from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { DateTime } from 'luxon';
import { TIMEZONES } from './components/utilities/timezones';
import { normalizeTimezones } from './components/utilities/normalize-timezones';
import { useTranslateAll } from '../../locales/translation-hooks';

export interface FormValuesStateAndSetter {
  formInputValues: BasicJoinedUserSettingsType;
  setFormInputValues: React.Dispatch<
    React.SetStateAction<BasicJoinedUserSettingsType>
  >;
}

export const defaultUser: User = {
  created_at: 0,
  systemnotices: 0,
  reports_for_review: 0,
  openprojects: 0,
  typename: 'user',
  email: '',
  lastname: '',
  role: '',
  firstname: '',
  operational: '',
  phone: '',
  profilepicture: '',
  id: '',
};

export const defaultTrimmedUserObject: BasicSlUserData = {
  firstname: '',
  lastname: '',
  email: '',
  profilepicture: '',
  role: '',
};

export const defaultUserSettingsObject: BasicUserSettingsData = {
  phone: '',
  jobtitle: '',
  timezone: normalizeTimezones(DateTime.local().offsetNameShort, TIMEZONES),
  location: '',
  numberformat: 'point',
  timeformat: 'hh:mm a',
  dateformat: 'MMM dd, y',
  language: '',
  secondaryemail: '',
};

export const UserProfile = () => {
  const { translateCommon } = useTranslateAll(['common']);

  const {
    handleCreateUserSettings,
    isCreateUserSettingsLoading,
    userSettingsCreationError,
    handleUpdateUserSettings,
    isUpdateUserSettingsLoading,
    userSettingsUpdateError,
  } = useUserSettingsDataContext();

  const {
    handleGetUser,
    isGetUserLoading,
    isGetUserFetching,
    handleUpdateUser,
  } = useUserDataContext();

  const defaultJoinedUserSettings: BasicJoinedUserSettingsType = {
    ...defaultTrimmedUserObject,
    ...defaultUserSettingsObject,
  };

  const [formInputValues, setFormInputValues] = useState<
    BasicJoinedUserSettingsType | ExtendedJoinedUserSettingsType
  >(defaultJoinedUserSettings);

  const formInputValuesStateAndSetter: FormValuesStateAndSetter = {
    formInputValues: formInputValues,
    setFormInputValues: setFormInputValues,
  };

  const [fetchedUserSettings, setFetchedUserSettings] = useState<
    BasicJoinedUserSettingsType | ExtendedJoinedUserSettingsType
  >(defaultJoinedUserSettings);

  const rawUserData = handleGetUser() || [];

  const createNewUserSettings = useRef(false);

  function handleGetUserSettings(userData: Item[]) {
    const settingsItem = userData.find(
      (item: Item) => item.typename.toLowerCase() === 'settings',
    );

    if (!settingsItem) {
      createNewUserSettings.current = true;
      return defaultUserSettingsObject;
    }

    createNewUserSettings.current = false;
    return settingsItem as Settings;
  }

  function handleGetSlUserInfo(userData: Item[]) {
    const { firstname, lastname, email, profilepicture, role, id } =
      userData.find(
        (item: Item) => item.typename.toLowerCase() === 'user',
      ) as User;
    return { firstname, lastname, email, profilepicture, role, id };
  }

  const getUserItem = (userData: Item[]) => {
    if (userData.length > 0) {
      const userItem = userData.find(
        (item: Item) => item.typename.toLowerCase() === 'user',
      );
      return userItem as User;
    }

    return defaultUser;
  };

  const combineDataForUserSettings = (
    data: Item[],
  ): BasicJoinedUserSettingsType | ExtendedJoinedUserSettingsType | null => {
    if (data.length > 0) {
      const userSettings = handleGetUserSettings(data);
      const slUserInfo = handleGetSlUserInfo(data);

      if (!(userSettings.timezone.length > 0)) {
        userSettings.timezone = defaultJoinedUserSettings.timezone;

        return {
          ...userSettings,
          ...slUserInfo,
        };
      } else {
        return { ...userSettings, ...slUserInfo };
      }
    } else {
      return null;
    }
  };

  //todo-cg: turn this into a utility function,+ add ability to remove multiple properties
  const removeObjectProperty = <
    T extends Record<string, unknown>,
    K extends keyof T,
  >(
    obj: T,
    propertyToRemove: K,
  ): Omit<T, K> => {
    const result = { ...obj };

    delete result[propertyToRemove];

    return result;
  };

  const handleUpdateOrCreateNewUserSettings = async (
    userSettingsData: ExtendedUserSettingsData,
    slUserData?: ExtendedSlUserData,
  ) => {
    const { sk_id } = userSettingsData;
    const basicUserSettingsData = removeObjectProperty(
      userSettingsData as unknown as Record<string, unknown>,
      sk_id,
    );
    try {
      if (rawUserData && rawUserData.length > 0) {
        if (createNewUserSettings.current) {
          handleCreateUserSettings(
            basicUserSettingsData as BasicUserSettingsData,
          );
        } else {
          handleUpdateUserSettings(userSettingsData);
          slUserData && handleUpdateUser(slUserData);
        }
      } else {
        console.warn('No user data available to process.');
      }
    } catch (error) {
      console.error('An error occurred while creating user settings:', error);
    }
  };

  useEffect(() => {
    const combinedData = combineDataForUserSettings(rawUserData);
    if (combinedData) {
      setFetchedUserSettings(combinedData);
      setFormInputValues(combinedData);
    }
  }, [rawUserData]);

  const isLoading =
    isGetUserFetching ||
    isCreateUserSettingsLoading ||
    isUpdateUserSettingsLoading;

  const hasError = userSettingsCreationError || userSettingsUpdateError;

  const handlePasswordSubmit = () => {
    console.error(
      "You are trying to save the changes to the password pane, but this feature has not been enabled yet. It will be included in future PR's.",
    );
  };
  const [imageUploaderOpenState, setImageUploaderOpenState] = useState(false);

  return (
    <>
      {isGetUserLoading ? (
        <LoadingOverlay isOpen={isGetUserLoading} />
      ) : hasError ? (
        <NoUserSettings error={userSettingsCreationError} />
      ) : (
        <PageWrapper isGrid>
          <Grid
            container
            gap={7}
            sx={{
              flexWrap: { lg: 'nowrap' },
            }}
          >
            <Grid
              container
              wrap="nowrap"
              direction={{ xs: 'column', xxl: 'row' }}
              flexWrap={{ xxl: 'wrap' }}
              width={'100%'}
              sx={{ overflowY: 'scroll', gap: { xs: 7 } }}
              size={{ lg: 6 }}
            >
              <Grid size={{ xxl: 12 }}>
                <PageTitle
                  title={translateCommon('headers.headerSettings')}
                  py={0}
                />
              </Grid>
              <Grid>
                <Profile
                  formInputValuesStateAndSetter={formInputValuesStateAndSetter}
                  fetchedUserSettings={fetchedUserSettings}
                  handleSubmit={handleUpdateOrCreateNewUserSettings}
                  userData={getUserItem(rawUserData)}
                  openStateSetter={setImageUploaderOpenState}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction={{ xs: 'column', xxl: 'row' }}
              flexWrap={{ xxl: 'wrap' }}
              width={'100%'}
              bgcolor={'white'}
              px={{ xs: 7, md: 10 }}
              py={{ xs: 9, md: 9 }}
              sx={{ overflowY: 'scroll', gap: { xs: 7, md: 6 } }}
              size={{ lg: 6 }}
            >
              <Grid height="auto">
                <Password
                  formInputValuesStateAndSetter={formInputValuesStateAndSetter}
                  fetchedUserSettings={fetchedUserSettings}
                  handleSubmit={handlePasswordSubmit}
                />
              </Grid>
              <Grid>
                <Formats
                  formInputValuesStateAndSetter={formInputValuesStateAndSetter}
                  fetchedUserSettings={fetchedUserSettings}
                  handleSubmit={handleUpdateOrCreateNewUserSettings}
                />
              </Grid>
            </Grid>
          </Grid>
          <LoadingOverlay isOpen={isLoading || imageUploaderOpenState} />
        </PageWrapper>
      )}
    </>
  );
};

const NoUserSettings = ({
  error,
}: {
  error: FetchBaseQueryError | SerializedError | undefined;
}) => {
  return (
    <Container fixed>
      <Typography variant="h6" color="initial">
        There was an error fetching user settings data: {error?.toString()}
      </Typography>
    </Container>
  );
};

const LoadingOverlay = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <Backdrop open={isOpen}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};
