{
    "title": "Profil utilisateur",
    "headings": {
        "profile": "Profil",
        "password": "Mot de passe",
        "formats": "Formats"
    },
    "subHeadings": {
        "profile": {
            "main": "Mettez à jour votre photo et vos détails personnels ici."
        },
        "password": {
            "main": "Changer le mot de passe"
        },
        "formats": {
            "main": "Formats"
        }
    },
    "labels":{
        "profile":{
            "photo":"Photo",
            "firstName":"Prénom",
            "lastName":"Nom de famille",
            "phone":"Téléphone",
            "jobTitle":"Poste",
            "loginEmail":"E-mail de connexion",
            "noticeEmail":"E-mail de notification",
            "timeZone":"Fuseau horaire",
            "location":"Localisation"
        },
        "password":{
            "current":"Mot de passe actuel",
            "new":"Nouveau mot de passe",
            "confirm":"Confirmer le mot de passe"
        },
        "formats":{
            "dateFormat":"Format de dates",
            "timeFormat":"Format des heures",
            "numberFormat":"Format des chiffres"
        }
    },
    "buttons":{
        "save":"Enregistrer",
        "cancel":"Annuler",
        "delete":"Supprimer",
        "upload":"Téléverser"
    },
    "options":{
        "formats":{
            "dateFormat":{
            },
            "timeFormat":{
                "12":"12 heures",
                "24":"24 heures"
            },
            "numberFormat":{
                "comma":"Virgule",
                "period":"Point"
            }
        }
    }
}
