{
  "dataTable": "Data Table",
  "seeAll": "See All",
  "expandAll": "Expand All",
  "collapseAll": "Collapse All",
  "more": "More",
  "uploading": "Uploading",
  "buttons":{
    "addButton": "ADD",
    "backButton": "BACK",
    "cancelButton": "CANCEL",
    "closeButton": "CLOSE",
    "confirmButton": "CONFIRM",
    "confirmationButton": "CONTINUE",
    "submitButton": "SUBMIT",
    "deleteButton": "DELETE",
    "editButton": "EDIT",
    "saveButton": "SAVE",
    "nextButton": "NEXT",
    "okButton": "OK",
    "uploadButton": "UPLOAD"
  },
  "dueDate":{
    "dueDateTomorrow": "Tomorrow",
    "dueDateYesterday": "Yesterday",
    "dueDateToday": "Today",
    "dueDateOverdue": "Overdue",
    "dueDateIn": "In {{numberOfWeeks}} weeks"
  },
  "errors":{
    "errorButtons":{
      "errorCloseButton": "Close"
    },
    "errorTitles":{
      "errorTitle": "Error",
      "errorUpdatingTitle": "Error Updating"
    },
    "errorMessages":{
      "errorDefaultMessage": "There was an error.",
      "errorUpdatingMessage": "We were not able to update at this moment"
    }
  },
  "greetings":{
    "welcomeGreeting": "Hello, World!",
    "navBarGreeting": "Hi"
  },
  "headers":{
    "headerCalendar": "Calendar",
    "headerCity":"City",
    "headerClient": "Clients",
    "headerUserProfile":"User Profile",
    "headerConsultant": "Consultant",
    "headerDashboard": "Dashboard",
    "headerDueDate": "Due Date",
    "headerEdit": "Edit",
    "headerEmail": "Email",
    "headerHelpButton": "HELP",
    "headerLocation": "Location",
    "headerLogin": "Log In",
    "headerName": "Name",
    "headerNotifications": "Notifications",
    "headerPhone": "Phone",
    "headerPriority": "Priority",
    "headerProjectAssignees": "Assignees",
    "headerProjectComments": "Comments",
    "headerProjectDueDate": "Due Date",
    "headerProjectStatus": "Status",
    "headerProjectSteps": "Steps",
    "headerProjects": "Projects",
    "headerMyProjects": "My Projects",
    "headerSingleProject": "Project",
    "headerSettings": "User Profile",
    "headerTask": "Task",
    "headerTaskManager": "Task Manager"
  },
  "labels":{
    "applicableStandard": "Applicable Standard",
    "intendedUse": "Intended Use",
    "successCriteria": "Success Criteria"
  },
  "messages":{
    "message": "Welcome to GHG CI.",
    "noDataMessage": "No data",
    "noResultsMessage": "No result",
    "confirmationMessage": "Your...has been confirmed/succesffully...",
    "textCopiedMessage": "Text Copied!",
    "EmailCopiedMessage": "Email Copied!",
    "thankYouDisplayMessage": "We have received your registration, we will review your information\n and send you a confirmation by email soon."
  },
  "questions":{
    "typeOfOrganizationQuestion": "What is the type of your organization?",
    "sizeOfOrganizationQuestion": "What is the size of your organization?"
  },
  "titles":{
    "congratulations": "Congratulations!",
    "thankYouDisplayTitle": "Thank you for your registration!"
  },
  "unknowns":{
    "unknownClient": "Unknown Client",
    "unknownManager": "Unknown Manager",
    "unknownDueDate": "Unknown Due Date",
    "unknownUser": "Unknown User"
  }
}
