import React from 'react';
import { PlusIcon } from 'icons/plus-icon';
import {
  AddButtonContainer,
  ButtonText,
} from 'pages/task-manager/components/styled';
import { useTranslateAll } from '../../../locales/translation-hooks';

function AddTask({
  onClickAction,
  hideButtonText,
}: {
  onClickAction?: React.MouseEventHandler;
  hideButtonText?: boolean;
}) {
  const { translateTasks } = useTranslateAll('tasks');
  return (
    <AddButtonContainer onClick={onClickAction}>
      <PlusIcon />
      {hideButtonText ? null : (
        <ButtonText>{translateTasks?.('titles.addTask')}</ButtonText>
      )}
    </AddButtonContainer>
  );
}

export default AddTask;
