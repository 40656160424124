import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { enRessources } from './en/ressources';
import { frRessources } from './fr/ressources';
import { deRessources } from './de/ressources';

export interface NamespaceInfo {
  namespace: string;
  nameSpaceDeclaration: { ns: string };
}

export type Namespaces =
  | 'common'
  | 'misc'
  | 'account'
  | 'accountRequest'
  | 'calendar'
  | 'clients'
  | 'forgottenPassword'
  | 'login'
  | 'notifications'
  | 'password'
  | 'projects'
  | 'tasks'
  | 'userProfile';

export const resources = {
  en: enRessources,
  de: deRessources,
  fr: frRessources,
};

const namespaces: Namespaces[] = [
  'common',
  'misc',
  'account',
  'accountRequest',
  'calendar',
  'clients',
  'forgottenPassword',
  'login',
  'notifications',
  'password',
  'projects',
  'tasks',
  'userProfile',
];

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Set the default language here
  ns: namespaces,
  fallbackLng: 'en', // Fallback language in case translation is missing for the current language
  interpolation: {
    escapeValue: false, // Allows usage of HTML tags in translations
  },
});

export const languages = i18n;
