import React, { useEffect, useState } from 'react';
import { Cell } from '@tanstack/react-table';
import { NewClientDataType } from 'pages/clients/api/get-clients';
import {
  CardHeaderContainer,
  SeeAllContainer,
} from 'pages/task-manager/components/styled';
import { DisplayTable } from 'pages/clients/components/clients-table';
import { MD } from 'utils/breakpoints';
import { colors, spacingWithPx } from 'utils/styles';
import { Container, TableRowContainer } from 'pages/dashboard/styled';
import { getWindowDimensions } from 'utils/window-utils';
import { ActiveIcon } from 'icons/active-icon';
import { isFirstDatePastSecondDate } from 'utils/date-validations';
import { Heading } from 'components';
import { useNavigate } from 'react-router-dom';
import { CopyToClipboardTruncated } from 'utils/copy-to-clipboard-truncated';
import { useClientDataContext } from '../../../../react-query-toolkit/state/client-context';
import { getClientIdsFromProjects } from 'utils/get-client-ids-from-projects';
import { findItemsByTypename } from 'utils/filterItemsbyTypeName';
import { useUserDataContext } from '../../../../react-query-toolkit/state/user-context';
import { Item } from '../../../../react-query-toolkit/state/types';
import {Box, ButtonBase, Typography} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslateAll } from '../../../../locales/translation-hooks';
import { clientHeadersTranslationMappings } from '../../../../locales/mappings';

export const Clients = () => {
  const [filtering, setFiltering] = useState('');
  const [windowSize, setWindowSize] = useState(getWindowDimensions());
  const { translateCommon, translateMisc } = useTranslateAll([
    'common',
    'misc',
  ]);

  function createColumnInfo() {
    return [
      {
        accessorKey: 'clientName',
        size: 150,
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <TableRowContainer>
            <ActiveIcon
              active={isFirstDatePastSecondDate(
                row.original.closeDate,
                row.original.activeDate,
              )}
            />
            <CopyToClipboardTruncated copyText={row.original.clientName}>
              {row.original.clientName}
            </CopyToClipboardTruncated>
          </TableRowContainer>
        ),
      },
      {
        header: 'Location',
        accessorKey: 'country',
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <div
            style={{
              textAlign: 'right',
              paddingRight: '20px',
            }}
          >
            <CopyToClipboardTruncated
              copyText={`${row.original.city}${row.original.state}`}
            >
              {`${row.original.city}, ${row.original.state}`}
            </CopyToClipboardTruncated>
          </div>
        ),
      },
    ];
  }

  const [columnInfo, setColumnInfo] = useState(createColumnInfo());

  // Fetch client data
  const { handleGetClients, clientListIsLoading, clientsFetchingError } =
    useClientDataContext();
  const rawClientData = handleGetClients() || [];

  // Fetch user data
  const { handleGetUser, isGetUserLoading } = useUserDataContext();
  const rawUserData: Item[] | null = handleGetUser();

  // Extract client IDs from user projects if user data exists
  const userClientsIds =
    rawUserData && Array.isArray(rawUserData)
      ? getClientIdsFromProjects(
          findItemsByTypename(rawUserData, 'ref:project'),
        )
      : [];

  // Filter clients to display based on user client IDs
  const clientsToBeDisplayed =
    rawUserData && rawClientData && userClientsIds !== null
      ? getItemsByIds(rawClientData, userClientsIds)
      : [];

  // Helper function to filter items by IDs
  function getItemsByIds(data: NewClientDataType[], ids: (string | null)[]) {
    return data.filter((item) => ids.includes(item.clientId));
  }

  // Determine final client data to be displayed
  let clientData: NewClientDataType[];
  if (rawClientData) {
    clientData = get5RecentActiveClients(clientsToBeDisplayed);
  }
  function get5RecentActiveClients(
    clients: NewClientDataType[],
  ): NewClientDataType[] {
    return clients
      .filter((client) =>
        isFirstDatePastSecondDate(client.closeDate, client.activeDate),
      )
      .sort(
        (a, b) =>
          new Date(a.activeDate).getTime() - new Date(b.activeDate).getTime(),
      )
      .slice(0, 5);
  }

  const handleResize = () => {
    setWindowSize(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <Container
        width={'100%'}
        margin={windowSize.width >= MD ? `0 ${spacingWithPx.xs} 0 0` : '0'}
      >
        <CardHeaderContainer onClick={() => navigate('/clients')}>
          <Heading level={3} color={colors.chevronGray}>
            {translateMisc('NavBar.navBarNavigationItems.myClients')}
          </Heading>
        </CardHeaderContainer>
        <DisplayTable
          filtering={filtering}
          setFiltering={setFiltering}
          data={clientData!}
          columns={columnInfo}
          getRowCanExpand={() => true}
          showFooter={false}
          hideHeader
          translationKeysMapping={clientHeadersTranslationMappings}
          //headersTranslationFunction={translateCommon}
          loadingItem={
            clientListIsLoading || isGetUserLoading ? (
              <Box
                display={'flex'}
                width={'100%'}
                height={'30%'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <CircularProgress size={50} sx={{ paddingY: 4 }} />
              </Box>
            ) : clientsFetchingError ? (
              'There was an error'
            ) : undefined
          }
        />
        <ButtonBase
          onClick={() => navigate('/clients')}
          sx={{
            padding: '0',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '21px',
            letterSpacing: '0.1px',
            alignItems: 'left',
            cursor: 'pointer',
            alignSelf: 'stretch',
            justifyContent:'start',
            '&:hover': { bgcolor: 'action.hover' }, // Optional hover effect
          }}
        >
          <Typography my={4} mx={7} variant={'link'} color={'primary.dark'}>
          {translateCommon('seeAll')}
          </Typography>
        </ButtonBase>
      </Container>
    </>
  );
};
