{
  "NavBar":{
    "navBarNavigationItems":{
      "myClients": "My Clients",
      "consultants": "Consultants",
      "dashboard": "Dashboard",
      "projects": "Projects",
      "tasks": "Tasks",
      "userProfile": "My Profile",
      "calendar": "Calendar",
      "notifications": "Notifications",
      "reports": "Reports",
      "signout": "Sign Out"
    },
    "taskManager":"Task Manager"
  }
}
