import { Container } from '../../styled';
import { colors, spacingWithPx } from 'utils/styles';
import styled from 'styled-components';
import { CardHeader } from '../../styled';

import {
  List as MuiList,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
} from '@mui/material';
import { Heading, Hoverable, Span } from 'components';
import { EllipsisVertical } from 'icons';
import { useTranslateAll } from '../../../../locales/translation-hooks';

const StyledList = styled(MuiList)`
  &.dashboard-list {
    padding: 0;
  }
`;

const ListItem = styled(MuiListItem)<{ isFirst: boolean }>`
  display: flex;
  ${({ isFirst }) =>
    isFirst ? `border-top: 1px solid ${colors.lightGrey}25;` : ''}
  border-bottom: 1px solid ${colors.lightGrey}25;
`;

const OpenableText = styled.div`
  cursor: pointer;
  margin: ${spacingWithPx.xs} auto;
`;

const ListItemButton = styled(MuiListItemButton)`
  padding: ${spacingWithPx.xs} ${spacingWithPx.gap} !important;
`;

type Props = {
  title: string;
  listData: JSX.Element[];
};

export const List = ({ title, listData }: Props) => {
  const { translateCommon } = useTranslateAll('common');
  return (
    <Container width="100%" margin="0">
      <CardHeader hasBorder={false}>
        <Heading level={3} color={colors.chevronGray}>
          {title}
        </Heading>
        <Hoverable
          onClick={() => alert('Click UI action to be implemented...')}
        >
          <EllipsisVertical height={'20'} />
        </Hoverable>
      </CardHeader>
      <StyledList className="dashboard-list">
        {listData.map((Data, index) => {
          return (
            <ListItem key={index} disablePadding isFirst={index === 0}>
              {}
              <ListItemButton dense>{Data}</ListItemButton>
            </ListItem>
          );
        })}
      </StyledList>
      <OpenableText>
        <Span color="secondaryBlue">{translateCommon('seeAll')}</Span>
      </OpenableText>
    </Container>
  );
};
