{

    "form":{
        "forgottenPassword": "Mot de passe oublié ?",
        "accountRequest": "Vous n’avez pas de compte ?",
        "buttons":{
            "login": "SE CONNECTER",
            "requestAccount": "CRÉER UN COMPTE"
        },
        "labels":{
            "email": "E-mail",
            "password": "Mot de passe"
        },
        "placeHolders":{
            "password": "Mot de passe",
            "email": "E-mail"
        }
    },
    "schema":{
        "passwordRequirements":{
            "lowercase": "Le mot de passe doit contenir au moins 1 lettre minuscule",
            "minLength": "Le mot de passe doit contenir au moins 8 caractères",
            "number": "Le mot de passe doit contenir au moins 1 chiffre",
            "required": "Un mot de passe est requis",
            "specialCharacter": "Le mot de passe doit contenir au moins 1 caractère spécial",
            "uppercase": "Le mot de passe doit contenir au moins 1 lettre majuscule"
        },
        "emailRequirements":{
            "emailRequired": "Une adresse e-mail est requise",
            "invalidEmail": "Adresse e-mail invalide"
        }
    }
}
